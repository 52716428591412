<template>
  <div class="chart">
    <div class="row">
      <div class="col-lg-12" id="mainBGs">
        <div class="row">
          <div class="chartImgs middle">
            <div
              id="colors"
              class="row"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 10px;
              "
            ></div>
            <div
              id="colors"
              class="row"
              v-show="'whiteboard' !== selectedSheet"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 10px;
                margin-left: -10px;
              "
            >
              <div class="over">
                <div class="dot draggable color yellow"></div>
                <div class="dot draggable color yellow"></div>
                <div class="dot draggable color yellow"></div>
              </div>
              <div class="over">
                <div class="dot draggable color blue"></div>
                <div class="dot draggable color blue"></div>
                <div class="dot draggable color blue"></div>
              </div>
              <div class="over">
                <div class="dot draggable color green"></div>
                <div class="dot draggable color green"></div>
                <div class="dot draggable color green"></div>
              </div>
              <div class="over">
                <div class="dot draggable color orange"></div>
                <div class="dot draggable color orange"></div>
                <div class="dot draggable color orange"></div>
              </div>
              <div class="over">
                <div class="dot draggable color red"></div>
                <div class="dot draggable color red"></div>
                <div class="dot draggable color red"></div>
              </div>
            </div>
            <div
              class="img-container-flex"
              :style="'whiteboard' === selectedSheet ? 'margin-top: 0px;' : ''"
            >
              <img
                class="col s4 middleImgs"
                v-show="'color3' === selectedSheet"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=dc126e92-22e7-4826-bc90-12dcc49b0865"
              />
              <img
                class="col s4 middleImgs"
                v-show="'color2' === selectedSheet"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=5058ab1f-e3dd-4eed-9787-bb1c125f10c7"
              />
              <img
                class="col s4 middleImgs"
                v-show="'sound3' === selectedSheet"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=6e1cf91a-3967-441e-95e8-6544798a1f73"
              />
              <img
                class="col s4 middleImgs"
                v-show="'sound2' === selectedSheet"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=e495b564-6734-4288-a202-9ddc41fe3db1"
              />
              <img
                class="col s4 middleImgs"
                v-show="'ssl' === selectedSheet"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSounds%20Stop%20Light.jpg?alt=media&token=e7db96a3-3d3b-40a4-8cb3-53f32782a99f"
              />
              <WriteBoard
                :showLetterStrokes="false"
                class="col s4 middleImgs"
                v-show="'whiteboard' === selectedSheet"
              />
            </div>
            <div
              class=""
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 10px;
              "
            >
              <div>
                <button
                  type="button"
                  class="btn btn-info mr-2 customSmallButton"
                  @click="resetTiles"
                >
                  Reset Sound Pictures
                </button>
                <button
                  type="button"
                  class="btn btn-info mr-2 customSmallButton"
                  @click="restColors"
                >
                  Reset Color Tiles
                </button>
                <button
                  type="button"
                  class="btn btn-info mr-2 customSmallButton"
                  @click="openModal"
                >
                  Open options
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <form style="flex-wrap: wrap; gap: 20px">
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="exampleRadios4"
                name="exampleRadios"
                class="custom-control-input"
                value="sound2"
                v-model="selectedSheet"
              />
              <label class="custom-control-label bold" for="exampleRadios4"
                >2 Sound Pictures</label
              >
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="exampleRadios3"
                name="exampleRadios"
                class="custom-control-input"
                value="sound3"
                v-model="selectedSheet"
              />
              <label class="custom-control-label bold" for="exampleRadios3"
                >3 Sound Pictures</label
              >
            </div>

            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="exampleRadios2"
                name="exampleRadios"
                class="custom-control-input"
                value="color2"
                v-model="selectedSheet"
              />
              <label class="custom-control-label bold" for="exampleRadios2"
                >2 Color Tiles</label
              >
            </div>
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="exampleRadios1"
                name="exampleRadios"
                class="custom-control-input"
                value="color3"
                v-model="selectedSheet"
              />
              <label class="custom-control-label bold" for="exampleRadios1"
                >3 Color Tiles</label
              >
            </div>

            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="exampleRadios6"
                name="exampleRadios"
                class="custom-control-input"
                value="whiteboard"
                v-model="selectedSheet"
              />
              <label class="custom-control-label bold" for="exampleRadios6"
                >Whiteboard</label
              >
            </div>

            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="exampleRadios5"
                name="exampleRadios"
                class="custom-control-input"
                value="ssl"
                v-model="selectedSheet"
              />
              <label class="custom-control-label bold" for="exampleRadios5"
                >Sounds Stop Light</label
              >
            </div>
          </form>
          <button @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import soundsStopLightInChart from "./soundsStopLightInChart";
import WriteBoard from "./WhiteBoard";

let sequence = 0;

let move = 0;
let lastTileMoved = "";
let objectWithPlaceholders = {
  moveOne: "",
  moveTwo: "",
  moveThree: "",
};
let correctAndIncorrectObj = {};

export default {
  name: "chart",
  data() {
    return {
      selectedSheet: "color3",
      showModal: false,
      image: "",
      apple: true,
      balloon: true,
      chop: true,
      duck: true,
      eddy: true,
      fish: true,
      goat: true,
      itchy: true,
      jam: true,
      kite: true,
      lamp: true,
      map: true,
      nut: true,
      olive: true,
      pig: true,
      rat: true,
      ship: true,
      sun: true,
      ren: true,
      ten: true,
      thumb: true,
      upper: true,
      vase: true,
    };
  },
  props: [""],
  components: { WriteBoard },
  mounted() {
    const that = this;

    $(".draggable").draggable({
      stack: ".draggable",
    });

    that.$root.$on("restColors", () => {
      that.restColors();
    });

    // Set .draggable height to 150px
    $(".draggable").css("height", "128px");

    // Set .imgContainer height to 150px
    $(".imgContainer").css("height", "130px");
    $(".imgContainer").css("width", "91px");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.positionSmallImages());
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    resetTiles() {
      $(".draggable.tile").each(function () {
        $(this).css({
          top: "",
          left: "",
        });
      });
    },
    restColors() {
      $(".draggable.color").removeAttr("style");
    },
  },
};
</script>

<style scoped>
.color:active {
  height: 58px !important;
  width: 58px !important;
  box-shadow: 0px 0px 5px black;
}

#colors > div {
  float: left;
  margin-left: 50px;
}

#colors > div:first-child {
  margin-left: 0;
}

.over {
  padding-left: 10px;
}

.dot {
  height: 55px !important;
  width: 55px;
  position: absolute !important;
}

.yellow {
  background-color: yellow !important;
}

.blue {
  background-color: blue !important;
}

.green {
  background-color: green !important;
}

.orange {
  background-color: orange !important;
}

.red {
  background-color: red !important;
}

/* .together {
      position: absolute;
      height: 100px;
      width: 72px;
  } */

/* .together img {
      position: absolute;
  } */

.draggable {
  height: 100px;
  /* z-index: 999; */
  /* position: relative; */
}

/* .draggable img {
      z-index: 999;
      position: relative !important;
  } */

#mainBGs .s4 img {
  height: 100%;
}

#mainBGs .row {
  display: flex;
  flex-wrap: wrap;
}

.chartImgs {
  flex-grow: 1;
  margin-bottom: 3px;
}

.side {
  width: 28%;
}

.middle {
  width: 40%;
}

/* .chartImgs img {
      z-index: 0;
      position: relative !important;
  } */

.imgContainer {
  /* float: left; */
  position: relative !important;
  /* margin-right: 82px;
    margin-bottom: 110px; */
  /* transform: translate(-50%, -50%); */
}
.imgContainer img {
  position: absolute !important;
}

#SSLImageGroup {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 0;
  width: 72%;
}

#SSLSmallImage {
  height: 100px;
  top: 115px;
  left: 111px; /* 115 */
  position: absolute;
}

.sideImgs {
  padding: 0px;
}

.img-container-flex {
  display: flex;
  justify-content: center;
  align-items: center; /* To vertically center as well if needed */
  margin-top: 60px;
}

.middleImgs {
  padding: 0px 3px;
  width: auto;
  height: 70vh;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
  flex: inherit;
}

.form-check {
  margin-right: 10px;
}

form {
  color: black;
}

.customSmallButton {
  padding: 5px !important;
}

.bold {
  font-weight: bold;
}

.custom-control {
  margin-bottom: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
}
</style>
