<template>
  <div id="newTutor">
    <div
      class="modal fade"
      id="newTutorModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="newTutorModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newTutorModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-user-add"></i> New tutor
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- The first tutor is included in the price of the program.
              Additional tutors are $25 for 6 months. -->
            <form class="kt-form">
              <label>Tutor's Full Name</label>
              <input
                v-model="tutorFullName"
                class="form-control"
                v-bind:class="{ 'is-invalid': !tutorFullName }"
                type="text"
                placeholder="Sara Smith"
              />
              <br />

              <label>Tutor's Email</label>
              <input
                v-model="tutorEmail"
                class="form-control"
                type="email"
                placeholder="sara@gmail.com"
              />
              <br />

              <br />
            </form>
            <br />
            <h4 v-if="message" class="text-center">{{ message }}</h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Close
            </button>
            <a
              href="javascript:;"
              class="btn btn-brand"
              @click="addTutor()"
              v-bind:class="{ disabled: isSubmitting }"
            >
             <span v-if="!isSubmitting"> Add Tutor</span><span v-else>Loading...</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import firebase from "./firebaseInit"

export default {
  name: "newTutor",
  data() {
    return {
      tutorFullName: "",
      tutorEmail: "",
      message: false,
      isSubmitting: false,
    };
  },
  mounted() {},
  methods: {
    async addTutor() {
      const that = this;

      that.isSubmitting = true;

      const idToken = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .catch(function (error) {
          that.isSubmitting = false;
          that.$store.dispatch(
            "setInfoDanger",
            "Oops something went wrong, please try again later or contact support if the problem persists."
          );
          that.message =
            "Oops something went wrong, please try again later or contact support if the problem persists.";
          $("html, body").animate({ scrollTop: 0 }, "fast");
          //$('#newTutorModal').modal('hide');
        });

      const res = await that.$http
        .post("/api/newTutor", {
          token: idToken,
          tutorEmail: that.tutorEmail,
          tutorFullName: that.tutorFullName,
        })
        .catch(console.error);

      if (res.body.success) {
        $("html, body").animate({ scrollTop: 0 }, "fast");
        that.message = "Tutor Added Successfully";
        alert("Tutor Added Successfully");
          window.location.reload();
      } else {
        that.isSubmitting = false;
        that.$store.dispatch("setInfoDanger", "Error: something went wrong.");
        that.message = "Error: ";
        $("html, body").animate({ scrollTop: 0 }, "fast");
      }
    },
  },
};
</script>

<style scoped>
p.error {
  color: #fd397a;
}
</style>
