<template>
  <div
    id="classroomTeacher"
    class="kt-content kt-grid__item kt-grid__item--fluid"
  >
    <div class="row" id="classFullSize">
      <div class="col-lg-4" style="padding-right: 10px">
        <div id="leftBottom" v-show="!sandbox">
          <div class="kt-portlet kt-portlet--height-fluid kt-widget">
            <div class="kt-portlet__body">
              <div
                id="kt-widget-slider-13-1"
                class="kt-slider carousel slide"
                data-ride="carousel"
                data-interval="800000"
              >
                <div class="kt-slider__head">
                  <div class="kt-slider__label">Instructions</div>
                  <div
                    v-show="subStep"
                    style="padding-left: 10px"
                    v-html="subStep"
                  ></div>
                  <div v-show="!subStep" style="margin-right: 120px">
                    <b>Slide:</b> {{ subStepCount + 1 }}
                  </div>
                  <div class="kt-slider__nav">
                    <a
                      class="kt-slider__nav-prev carousel-control-prev"
                      :class="{ disabled: subStepCount == 0 || isLoadingSlide }"
                      href="javascript:;"
                      role="button"
                      data-slide=""
                      id="slidePrev"
                      @click="prevStep()"
                    >
                      <i class="fa fa-angle-left"></i>
                    </a>
                    <a
                      class="kt-slider__nav-next carousel-control-next"
                      :class="{
                        disabled:
                          subStepCount == teacherInstructions.length - 1 ||
                          isLoadingSlide,
                      }"
                      href="#kt-widget-slider-13-1"
                      role="button"
                      data-slide="next"
                      id="slideNext"
                      @click="nextStep()"
                    >
                      <i class="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>

                <div class="carousel-inner" v-show="isInstructionsVisible">
                  <div>
                    <!-- <b>{{substepName}}</b> -->
                    <div class="kt-widget-13">
                      <div class="kt-widget-13__body">
                        <!-- <div v-show="isLoadingSlide" style="height: 30px;">
                          <div class="spinner-border" role="status" style="
                              margin-left: auto;
                              margin-right: auto;
                              left: 0px;
                              right: 0px;
                              position: absolute;
                          ">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div> -->

                        <div v-if="hideInstructionsArea">
                          <div
                            class="kt-widget-13__desc"
                            id="instructionsAreaBefore"
                          >
                            {{ truncatedInstructions }}
                          </div>
                          <div class="centered-link">
                            <a
                              href="javascript:;"
                              class="smolArrowToggle"
                              @click="hideInstructionsArea = false"
                              style="height: 25px; width: 25px"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <div
                          v-else
                          class="kt-widget-13__desc"
                          id="instructionsArea"
                          v-show="!isLoadingSlide"
                        >
                          <div
                            v-html="teacherInstructions[currentNoteCount]"
                          ></div>
                          <br />

                          <div v-show="studentInstructions[currentNoteCount]">
                            <b>Student:</b>
                            <span
                              v-html="studentInstructions[currentNoteCount]"
                            ></span>
                          </div>
                          <div class="centered-link">
                            <a
                              href="javascript:;"
                              class="smolArrowToggle"
                              @click="hideInstructionsArea = true"
                              style="height: 25px; width: 25px"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m4.5 18.75 7.5-7.5 7.5 7.5"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m4.5 12.75 7.5-7.5 7.5 7.5"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="height: 441px"
          id="leftTop"
          v-show="showPicArea && !sandbox"
        >
          <div id="picArea">
            <div class="stackContainer" v-show="map">
              <div id="map" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="nut">
              <div id="nut" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="fish">
              <div id="fish" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="vase">
              <div id="vase" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="thumb">
              <div id="thumb" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                  class="draggable tile"
                /> -->
              </div>
            </div>

            <div class="stackContainer" v-show="kite">
              <div id="kite" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="goat">
              <div id="goat" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                  class="draggable tile"
                /> -->
              </div>
            </div>

            <div class="stackContainer" v-show="sun">
              <div id="sun" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="ship">
              <div id="ship" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="itchy">
              <div id="itchy" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="eddy">
              <div id="eddy" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="upper">
              <div id="upper" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="apple">
              <div id="apple" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="olive">
              <div id="olive" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="lamp">
              <div id="lamp" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="rat">
              <div id="rat" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="duck">
              <div id="duck" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="ten">
              <div id="ten" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="chop">
              <div id="chop" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="jam">
              <div id="jam" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="balloon">
              <div id="balloon" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                  class="draggable tile"
                /> -->
              </div>
            </div>
            <div class="stackContainer" v-show="pig">
              <div id="pig" class="imgContainer">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                  class="draggable tile"
                />
                <!-- <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                  class="draggable tile"
                /> -->
              </div>
            </div>
          </div>
          <!-- picArea -->
        </div>
        <div style="height: 441px" id="leftTop" v-show="showPicArea && sandbox">
          <div id="picArea">
            <div class="grid">
              <div class="column">
                <div class="stackContainer" v-show="nut">
                  <div id="nut" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="fish">
                  <div id="fish" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="vase">
                  <div id="vase" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="thumb">
                  <div id="thumb" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                      class="draggable tile"
                    /><img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                      class="draggable tile"
                    />
                  </div>
                </div>

                <div class="stackContainer" v-show="kite">
                  <div id="kite" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                      class="draggable tile"
                    />
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="stackContainer" v-show="sun">
                  <div id="sun" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="ship">
                  <div id="ship" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="itchy">
                  <div id="itchy" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="eddy">
                  <div id="eddy" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="upper">
                  <div id="upper" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                      class="draggable tile"
                    />
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="stackContainer" v-show="olive">
                  <div id="olive" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="lamp">
                  <div id="lamp" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="rat">
                  <div id="rat" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="duck">
                  <div id="duck" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="ten">
                  <div id="ten" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                      class="draggable tile"
                    /><img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                      class="draggable tile"
                    />
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="stackContainer" v-show="chop">
                  <div id="chop" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="jam">
                  <div id="jam" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="balloon">
                  <div id="balloon" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                      class="draggable tile"
                    />
                  </div>
                </div>
                <div class="stackContainer" v-show="pig">
                  <div id="pig" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                      class="draggable tile"
                    /><img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                      class="draggable tile"
                    />
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="stackContainer" v-show="map">
                  <div id="map" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                      class="draggable tile"
                    />
                  </div>
                </div>

                <div class="stackContainer" v-show="goat">
                  <div id="goat" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                      class="draggable tile"
                    /><img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                      class="draggable tile"
                    />
                  </div>
                </div>

                <div class="stackContainer" v-show="apple">
                  <div id="apple" class="imgContainer">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                      class="draggable tile"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                      class="draggable tile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <introduction
          v-if="introduction"
          :show-objs-btn="showObjsBtn"
          :show-sounds-btn="showSoundsBtn"
        ></introduction>
        <procedureOne v-if="game"></procedureOne>
        <Chart
          v-if="activitySheet"
          :selectedSheet="selectedSheet"
          :toShowOrToNowShowArray="toShowOrToNowShowArray"
          v-observe-visibility="showPicArea"
          :show-image="SSLObj.showImage"
          :show-stop-light="SSLObj.showStopLight"
          :image-src="SSLObj.imageSrc"
        ></Chart>
        <soundsStopLight
          v-if="SSL"
          :show-image="SSLObj.showImage"
          :show-stop-light="SSLObj.showStopLight"
          :image-src="SSLObj.imageSrc"
          :image-two-src="SSLObj.imageTwoSrc"
        ></soundsStopLight>
        <Sandbox v-if="sandbox" :selectedSheet="selectedSheet"></Sandbox>
      </div>
    </div>

    <!-- Start Classroom Settings Modal -->
    <classroomSettings></classroomSettings>
    <!-- End Classroom Settings Modal -->

    <div
      class="modal fade"
      role="dialog"
      id="confirmModal"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Finish Class Session?</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              id="saveAndFinishModal"
              v-show="!sandbox"
            >
              Save & Mark as Complete
            </button>
            <button
              type="button"
              class="btn btn-primary"
              id="saveAtCurrentStep"
              v-show="!sandbox"
            >
              Save at Current Step and Finish
            </button>
            <button type="button" class="btn btn-primary" id="finishModal">
              Finish Without Saving
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- endModal -->
    <div class="modal fade" role="dialog" id="endModal" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">
              After completing this slide, click “Next Procedure” to move
              forward.
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-primary" id="saveAndFinishModal">Save & Mark as Complete</button>
            <button type="button" class="btn btn-primary" id="finishModal">Finish Without Saving</button> -->
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end endModal -->

    <!-- endLessonModal-->
    <div
      class="modal fade"
      role="dialog"
      id="endLessonModal"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">
              Congratulations! You've finished this lesson!
              <br />
              <br />
              Click “Next Procedure” to move forward.
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-primary" id="saveAndFinishModal">Save & Mark as Complete</button>
            <button type="button" class="btn btn-primary" id="finishModal">Finish Without Saving</button> -->
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end endLessonModal -->

    <!-- finalModal -->
    <div
      class="modal fade"
      role="dialog"
      id="finalModal"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <p>
              Congratulations on finishing Foundation in Sounds™! At this time,
              be sure to readminister the
              <a href="https://bartonreading.com/students/#ss" target="_new"
                >Barton Student Screening Part C</a
              >
              if you are intending to begin the Barton Reading and Spelling
              System. Afterward, go to your
              <a :href="'/studentProfile?studentID=' + studentID"
                >student's profile page (linked here.)</a
              >
            </p>
            <p>
              In the green “Student Information” box at the top of the page,
              click on the “Post Screening Scores” box in the lower right hand
              corner and input your students results from the
              <a href="https://bartonreading.com/students/#ss" target="_new"
                >Barton Student Screening Part C.</a
              >
            </p>
            <p>
              Thank you again for using the Foundation in Sounds web
              application! If you have opportunity, we would love your feedback.
              Your feedback helps us in our never ending quest to improve this
              Program.
            </p>
            <p>
              A very big thank you!
              <br />
              From the Foundation In Sounds™ Team,
            </p>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-primary" id="saveAndFinishModal">Save & Mark as Complete</button>
            <button type="button" class="btn btn-primary" id="finishModal">Finish Without Saving</button> -->
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end finalModal -->
  </div>
</template>

<script>
// import io from 'socket.io-client'
// import ss from 'socket.io-stream'
// import Shepherd from 'shepherd.js'

// const socket = io();
import Vue from "vue/dist/vue.esm.js";

import introduction from "./Introduction";
import procedureOne from "./procedureOne";
import Chart from "./Chart";
import soundsStopLight from "./soundsStopLight";

import classroomSettings from "./classroomSettings";
// import procedures from "./Procedures";
import moment from "moment";

import saveClassSession from "./saveClassSession.js";

import confetti from "canvas-confetti";
import Sandbox from "./Sandbox.vue";

const arrayOfClasses = [
  [4, 5, 6, 7, 8], //lesson 1
  [1, 2, 3, 4, 5, 6, 7, 8], //lesson 2
  [1, 2, 3, 4, 5, 6, 7, 8], //lesson 3
  [1, 2, 3, 4, 5, 6, 7, 8], //lesson 4
  [1, 2, 3, 4, 5, 6, 7, 8], //lesson 5
  [1, 2, 3, 4, 5, 6, 7, 8], //lesson 6
  [1, 2, 3, 4, 5, 6, 7, 8], //lesson 7
  [1, 2, 3, 4, 5, 6, 7, 8], //lesson 8
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 9
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 10
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 11
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 12
  [1, 2, 3], //lesson 13
];

const arrayOfImages = [
  "apple",
  "balloon",
  "chop",
  "duck",
  "eddy",
  "fish",
  "goat",
  "itchy",
  "jam",
  "kite",
  "lamp",
  "map",
  "nut",
  "olive",
  "pig",
  "rat",
  "ship",
  "sun",
  "ren",
  "ten",
  "thumb",
  "upper",
  "vase",
];

let regExpXX = "/([/,!\\^${}[]().*+?|<>-&])/g";
let leftTop;
let leftTopPrime;

let currentNoteCountToSave = {};

export default {
  name: "classroomTeacher",
  data() {
    return this.getInitialState();
  },
  components: {
    classroomSettings,
    // procedures,
    soundsStopLight,
    Chart,
    introduction,
    procedureOne,
    Sandbox,
  },
  beforeMount() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  async mounted() {
    const that = this;

    // -- Things that can only be run once
    that.$root.$on("endClassSession", () => {
      that.endClassSession();
    });

    that.$root.$on("chartActive", (isActive) => {
      that.showPicArea = isActive;
    });

    that.$root.$on("showPicAreaRoot", (isActive) => {
      that.showPicArea = isActive;
    });

    that.$root.$on("prevProd", () => {
      that.prevProd();
    });

    that.$root.$on("nextProd", () => {
      that.nextProd();
    });

    that.$root.$on("loadEverything", () => {
      that.loadEverything();
    });

    that.$root.$on("launchConfetti", () => {
      that.launchConfetti();
    });

    that.$root.$on("toggleInstructionsVisibility", () => {
      that.isInstructionsVisible = !that.isInstructionsVisible;
    });

    $(".draggable").draggable({
      stack: ".draggable",
    });

    console.log(
      "\npage mounted lesson:",
      localStorage.getItem("classroomInfoCurrentLesson")
    );
    console.log(
      "page mounted procedure:",
      localStorage.getItem("classroomInfoProgress")
    );

    window.addEventListener("resize", that.resizePicArea);

    // -- End of things that can only be run once

    that.loadEverything();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizePicArea());
  },
  methods: {
    getInitialState() {
      return {
        studentID: "",
        DBProgress: "",
        hideInstructionsArea: true,
        currentlyRunning: false,
        isLoadingSlide: false,
        showSoundsBtn: false,
        isCurrentLesson: false,
        showObjsBtn: false,
        componentKey: 0,
        startTime: 0,
        minutesForToday: 0,
        teacherInstructions: [],
        studentInstructions: [],
        toShowOrToNotShow: [],
        toShowOrToNowShowArray: [],
        instructionData: [],
        visableImagesArray: [],
        procedureName: "",
        newStep: "",
        stepName: "",
        subTitles: "",
        subStep: "",
        subStepCount: 0,
        currentNoteCount: 0,
        // whatToDisplay: {
        game: false,
        introduction: false,
        sandbox: false,
        activitySheet: false,
        selectedSheet: false,
        SSL: false,
        // },
        SSLObj: {
          showImage: "",
          showStopLight: "",
          imageSrc: "",
          imageTwoSrc: "",
        },
        showPicArea: false,
        chartActive: false,
        apple: true,
        balloon: true,
        chop: true,
        duck: true,
        eddy: true,
        fish: true,
        goat: true,
        itchy: true,
        jam: true,
        kite: true,
        lamp: true,
        map: true,
        nut: true,
        olive: true,
        pig: true,
        rat: true,
        ship: true,
        sun: true,
        ren: true,
        ten: true,
        thumb: true,
        upper: true,
        vase: true,
        isInstructionsVisible: true,
      };
    },
    resetData() {
      Object.assign(this.$data, this.getInitialState());
    },
    launchConfetti(confettiType = "simple") {
      const that = this;

      if (typeof confettiType !== "string") {
        confettiType = "simple";
      }

      if (confettiType === "simple") {
        confetti.create(that.$refs.confettiCanvas, {
          resize: true,
          useWorker: true,
        })({ particleCount: 200, spread: 200 });
      } else if (confettiType === "stars") {
        console.log("here");
        var defaults = {
          spread: 360,
          ticks: 50,
          gravity: 0,
          decay: 0.94,
          startVelocity: 30,
          shapes: ["star"],
          colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
        };

        function shoot() {
          confetti.create(that.$refs.confettiCanvas, {
            resize: true,
            useWorker: true,
          })({
            ...defaults,
            particleCount: 40,
            scalar: 1.2,
            shapes: ["star"],
          });

          confetti.create(that.$refs.confettiCanvas, {
            resize: true,
            useWorker: true,
          })({
            ...defaults,
            particleCount: 10,
            scalar: 0.75,
            shapes: ["circle"],
          });
        }

        setTimeout(shoot, 0);
        setTimeout(shoot, 100);
        setTimeout(shoot, 200);
        setTimeout(shoot, 300);
        setTimeout(shoot, 400);
        setTimeout(shoot, 500);
      } else if (confettiType === "fireworks") {
        //Fireworks
        var duration = 5 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min, max) {
          return Math.random() * (max - min) + min;
        }

        var interval = setInterval(function () {
          var timeLeft = animationEnd - Date.now();

          if (timeLeft <= 0) {
            return clearInterval(interval);
          }

          var particleCount = 50 * (timeLeft / duration);
          // since particles fall down, start a bit higher than random
          confetti.create(that.$refs.confettiCanvas, {
            resize: true,
            useWorker: true,
          })(
            Object.assign({}, defaults, {
              particleCount,
              origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
            })
          );
          confetti.create(that.$refs.confettiCanvas, {
            resize: true,
            useWorker: true,
          })(
            Object.assign({}, defaults, {
              particleCount,
              origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
            })
          );
        }, 250);

        // School Pride
        var end = Date.now() + 15 * 1000;

        // Colors:
        var colors = ["#370CD3", "#ffffff"];

        (function frame() {
          confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors,
          });
          confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors,
          });

          if (Date.now() < end) {
            requestAnimationFrame(frame);
          }
        })();
      }
    },
    stripHtml(html) {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    },
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + "...";
    },
    async loadEverything() {
      const that = this;

      console.log("In loadEverything");

      // Resets data
      that.resetData();

      //Set start time
      that.startTime = moment().format("X");

      // const info = localStorage.getItem('classroomInfoProgress').split(', ')
      const info = localStorage.getItem("classroomInfoProgress").split(", ");

      if (info[0] == "sandbox") {
        that.sandbox = true;
        that.teacherInstructions.push(
          "Play around with the tiles and sounds to see how they work."
        );
      } else {
        const lesson = info[0].toLowerCase();
        const procedure = info[1] ? info[1].toLowerCase() : "introduction";
        let procedureString = "";

        switch (procedure) {
          case "introduction":
            procedureString = "introduction";
            that.introduction = true;
            break;
          case "procedure 1":
            procedureString = "procedureOne";
            break;
          case "procedure 2":
            procedureString = "procedureTwo";
            break;
          case "procedure 3":
            procedureString = "procedureThree";
            break;
          case "procedure 4":
            procedureString = "procedureFour";
            break;
          case "procedure 5":
            procedureString = "procedureFive";
            break;
          case "procedure 6":
            procedureString = "procedureSix";
            break;
          case "procedure 7":
            procedureString = "procedureSeven";
            break;
          case "procedure 8":
            procedureString = "procedureEight";
            break;
          case "procedure 9":
            procedureString = "procedureNine";
            break;
          case "procedure 10":
            procedureString = "procedureTen";
            break;
        }

        const ref = db
          .collection("courseDataTwo")
          .doc(lesson)
          .collection(procedureString)
          .doc("procedureObject");

        const snapshot = await ref.get().catch(function (error) {
          console.error("Error getting document:", error);
        });

        let procedures = snapshot.data();
        let tutorInstructionsArray = procedures.tutorInstructions;
        let studentInstructionsArray = procedures.studentInstructions;
        if (procedures.toShowOrToNotShow) {
          that.toShowOrToNotShow = procedures.toShowOrToNotShow;
        }
        if (procedures.sebTitles) {
          that.subTitles = procedures.sebTitles;
        }
        if (procedures.visableImages) {
          that.visableImagesArray.push(...procedures.visableImages);
        }
        let longestLength = Math.max(
          tutorInstructionsArray.length,
          studentInstructionsArray.length
        );
        let regExp = /\(([^)]+)\)/;

        that.$root.$emit("procedureName", procedures.procedureName);

        // --- For the 3rd save btn
        const suid = localStorage.getItem("classroomInfoStudentID");
        that.studentID = suid;
        const uid = localStorage.getItem("userID");

        const snapshotTwo = await db
          .collection("users")
          .doc(uid)
          .collection("students")
          .doc(suid)
          .get()
          .catch(console.error);
        that.DBProgress = snapshotTwo.data().studentReadableProgress;

        console.log("that.DBProgress ", that.DBProgress);
        console.log(
          "classroomInfoProgress ",
          localStorage.getItem("classroomInfoProgress")
        );

        currentNoteCountToSave =
          snapshotTwo.data().currentNoteCountToSave || {};

        // Do this now reguardless of current lesson or not
        that.currentNoteCount =
          currentNoteCountToSave[
            localStorage.getItem("classroomInfoProgress")
          ] || 0;
        that.subStepCount = that.currentNoteCount;

        if (
          that.DBProgress.toLowerCase() ===
          localStorage.getItem("classroomInfoProgress").toLowerCase()
        ) {
          that.isCurrentLesson = true;
        }
        // ---

        for (let i = 0; i < longestLength; i++) {
          if (
            "" === tutorInstructionsArray[i] &&
            "" === studentInstructionsArray[i]
          ) {
            continue;
          }

          tutorInstructionsArray[i] =
            "<b>" + tutorInstructionsArray[i] + "</b>";
          tutorInstructionsArray[i] = tutorInstructionsArray[i].replaceAll(
            "(",
            "</b>(<i>"
          );
          tutorInstructionsArray[i] = tutorInstructionsArray[i].replaceAll(
            ")",
            "</i>)<b>"
          );
          tutorInstructionsArray[i] = tutorInstructionsArray[i].replaceAll(
            "^",
            "<br/>"
          );

          let teacherInstructionString = tutorInstructionsArray[i]; //!teacherMatches ? tutorInstructionsArray[i].bold() : splitStringWithBold;

          let studentMatches = regExp.exec(studentInstructionsArray[i]);
          let studentInstructionString = !studentMatches
            ? studentInstructionsArray[i]
            : studentInstructionsArray[i].replace(
                studentMatches[0],
                studentMatches[0].italics()
              );

          that.teacherInstructions.push(teacherInstructionString);
          that.studentInstructions.push(studentInstructionString);
        }
      }

      // Initalize
      await that.findWhatToDisplay();

      await that.resizePicArea();

      that.currentlyRunning = false;
    },
    prevProd() {
      const that = this;

      if (that.currentlyRunning) {
        console.log("currently running***");
        return;
      }

      that.currentlyRunning = true;

      //   if (!confirm('Are you sure you want to leave this procedure?')) {
      //     return;
      //   }

      const currentLesson = localStorage.getItem("classroomInfoCurrentLesson");
      let currentLessionInt = parseInt(currentLesson.replace(/^\D+/g, ""));

      const info = localStorage.getItem("classroomInfoProgress").split(", ");
      if ("introduction" == info[0]) {
        return;
      }
      const currentProcedureInt = parseInt(
        info[1].toLowerCase().replace(/^\D+/g, "")
      );
      let prevProcedureInt = currentProcedureInt - 1;

      while (true) {
        let hasPrevious =
          arrayOfClasses[currentLessionInt - 1].includes(prevProcedureInt);
        if (hasPrevious) {
          break;
        }

        if (currentLessionInt > 1) {
          //If we're at least at lesson 2
          currentLessionInt--;
          prevProcedureInt =
            arrayOfClasses[currentLessionInt - 1][
              arrayOfClasses[currentLessionInt - 1].length - 1
            ]; //Get last procedure in lesson array
        } else {
          //If lesson 1
          prevProcedureInt = currentProcedureInt; //Don't go back any if we're already at lesson 1 prod 4
          break;
        }
      }

      console.log(
        "previous lesson and procedure is:",
        currentLessionInt,
        prevProcedureInt
      );

      that.updateWhatIsDisplayed(currentLessionInt, prevProcedureInt);
    },
    nextProd() {
      const that = this;

      if (that.currentlyRunning) {
        console.log("currently running****");
        return;
      }

      that.currentlyRunning = true;

      //   if (!confirm('Are you sure you want to leave this procedure?')) {
      //     return;
      //   }

      const currentLesson = localStorage.getItem("classroomInfoCurrentLesson");
      let currentLessionInt = parseInt(currentLesson.replace(/^\D+/g, ""));

      const info = localStorage.getItem("classroomInfoProgress").split(", ");
      // console.log(info);
      if ("introduction" == info[0].toLowerCase()) {
        console.log("next lesson and procedure is:", 1, 4);

        that.updateWhatIsDisplayed(1, 4);
        return;
      }
      const currentProcedureInt = parseInt(
        info[1].toLowerCase().replace(/^\D+/g, "")
      );
      let nextProcedureInt = currentProcedureInt + 1;

      while (true) {
        let hasNext =
          arrayOfClasses[currentLessionInt - 1].includes(nextProcedureInt);
        if (hasNext) {
          break;
        }

        if (currentLessionInt < 13) {
          //If we're below lesson 13
          currentLessionInt++;
          nextProcedureInt = arrayOfClasses[currentLessionInt - 1][0]; //Get first procedure in the next lesson array
        } else {
          //If lesson 1
          nextProcedureInt = currentProcedureInt; //Don't go back any if we're already at lesson 1 prod 4
          break;
        }
      }

      console.log(
        "\n1. ----next lesson and procedure is:",
        currentLessionInt,
        nextProcedureInt
      );

      that.updateWhatIsDisplayed(currentLessionInt, nextProcedureInt);
    },
    updateWhatIsDisplayed(currentLessionInt, procedureInt) {
      const that = this;

      //Set new data
      localStorage.setItem(
        "classroomInfoCurrentLesson",
        `lesson${currentLessionInt}`
      );
      console.log(
        "2. --setting-- currentLesson, procedureInt",
        currentLessionInt,
        procedureInt
      );

      localStorage.setItem(
        "classroomInfoProgress",
        `Lesson ${currentLessionInt}, Procedure ${procedureInt}`
      );
      window.dispatchEvent(
        new CustomEvent("classroomInfoProgress-changed", {
          detail: {
            storage: localStorage.getItem("classroomInfoProgress"),
          },
        })
      );

      //Set which tiles aren't in the current lesson
      switch (localStorage.getItem("classroomInfoCurrentLesson")) {
        case "lesson1":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson1
          );
          break;
        case "lesson2":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson2
          );
          break;
        case "lesson3":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson3
          );
          break;
        case "lesson4":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson4
          );
          break;
        case "lesson5":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson5
          );
          break;
        case "lesson6":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson6
          );
          break;
        case "lesson7":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson7
          );
          break;
        case "lesson8":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson8
          );
          break;
        case "lesson9":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson9
          );
          break;
        case "lesson10":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson10
          );
          break;
        case "lesson11":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson11
          );
          break;
        case "lesson12":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson12
          );
          break;
        case "lesson13":
          that.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            that.$store.getters.getClassroomInfoLesson13
          );
          break;
      }

      //
      console.log(
        "3. should be final",
        localStorage.getItem("classroomInfoProgress")
      );

      that.loadEverything();

      // //Reload page
      // window.location.reload();
    },
    async endClassSession() {
      const that = this;
      const suid = localStorage.getItem("classroomInfoStudentID");
      const uid = localStorage.getItem("userID");
      const today = moment();
      const todayFull = moment().format("DD-MM-YYYY");

      const finishConfirmSave = await that.confirm();
      $("#confirmModal").modal("hide");

      const startTime = moment(that.startTime, "X");
      const endTime = moment();
      const difference = Math.abs(startTime.diff(endTime, "minutes"));

      // Get minutesForToday from DB
      const querySnapshot = await db
        .collection("users")
        .doc(suid)
        .collection("classes")
        .doc(todayFull)
        .get()
        .catch(function (error) {
          console.error("Error getting document:", error);
        });

      const minutesForTodayBefore = querySnapshot.data()?.minutesForToday
        ? querySnapshot.data().minutesForToday
        : 0;

      //Add onto the current hours for today (if they exist)
      that.minutesForToday =
        that.minutesForToday + difference + 1 + minutesForTodayBefore; //Always add 1 minute to account for the time they started

      await db
        .collection("users")
        .doc(suid)
        .collection("classes")
        .doc(todayFull)
        .set({
          minutesForToday: that.minutesForToday,
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });

      if (finishConfirmSave.saveStep || finishConfirmSave.complete) {
        // If marking as complete
        if (finishConfirmSave.complete) {
          that.$root.$emit("saveClassSession", true);
        }

        // console.log("suid", suid);
        // console.log("uid", uid);

        const currentLesson = localStorage
          .getItem("classroomInfoProgress")
          .split(",")[0];
        let currentLessionInt = parseInt(currentLesson.replace(/^\D+/g, ""));
        const info = localStorage.getItem("classroomInfoProgress").split(", ");

        if (info[0].toLowerCase() == "introduction") {
          await db
            .collection("users")
            .doc(uid)
            .collection("students")
            .doc(suid)
            .update({
              currentLesson: "lesson1",
              studentReadableProgress: "Lesson 1, Procedure 4",
            })
            .catch(console.error);

          that.$destroy();
          that.$el.remove(); // Remove the component's DOM elements
          that.$router.push({ path: "/dashboard" });
          window.location.reload();
        } else {
          const currentProcedureInt = parseInt(
            info[1].toLowerCase().replace(/^\D+/g, "")
          );
          const totalPoints = this.$store.getters.getClassroomInfoPoints;
          const totalTries = this.$store.getters.getClassroomInfoTotalTries;
          const averageProficiency = parseFloat(
            100 * (totalPoints / totalTries)
          );

          let lessons = {};
          let lastLesson = 0;
          let lastProcedure = 0;

          if (finishConfirmSave.complete) {
            const loopThroughLessonsForComplete = () => {
              // Mark every procedure up to this point as complete
              // First loop through all the lessons and procedures
              for (
                let lessonInt = 0;
                lessonInt < arrayOfClasses.length;
                lessonInt++
              ) {
                const realLesson = lessonInt + 1;
                if (realLesson > currentLessionInt) return; // Break if we've passed the current lesson

                for (
                  let procedureInt = 0;
                  procedureInt < arrayOfClasses[lessonInt].length;
                  procedureInt++
                ) {
                  const procedure = arrayOfClasses[lessonInt][procedureInt];

                  if (
                    realLesson === currentLessionInt &&
                    procedure > currentProcedureInt
                  )
                    return; // Break if we're in the current lesson and have passed the current procedure

                  const key = "lesson" + realLesson + "Procedure" + procedure;

                  lessons[key] = true;
                }
              }
            };
            loopThroughLessonsForComplete();

            await saveClassSession.save(
              averageProficiency,
              totalPoints,
              totalTries,
              uid,
              suid
            );

            await db
              .collection("users")
              .doc(uid)
              .collection("students")
              .doc(suid)
              .update({
                currentNoteCountToSave: {}, // Reset the currentNoteCountToSave
              })
              .catch(console.error);
          } else if (finishConfirmSave.saveStep) {
            const previousLessonAndProcedure =
              that.getPreviousLessonAndProcedure(
                currentLessionInt,
                currentProcedureInt
              );

            const previousLesson = `lesson${previousLessonAndProcedure[0]}`;
            const previousProcedure = `procedure ${previousLessonAndProcedure[1]}`;

            console.log("previousLesson", previousLesson);
            console.log("previousProcedure", previousProcedure);

            const loopThroughLessonsForSaveStep = () => {
              // // console.log("currentLessionInt", currentLessionInt);
              // // console.log("currentProcedureInt", currentProcedureInt);
              // // debugger

              // // If on lesson 1 procedure 4, don't save anything
              // if (currentLessionInt === 1 && currentProcedureInt === 4) return;

              // Mark every procedure up to this point as complete
              // First loop through all the lessons and procedures
              for (
                let lessonInt = 0;
                lessonInt < arrayOfClasses.length;
                lessonInt++
              ) {
                const realLesson = lessonInt + 1;
                if (realLesson > currentLessionInt) return; // Break if we've passed the current lesson

                for (
                  let procedureInt = 0;
                  procedureInt < arrayOfClasses[lessonInt].length;
                  procedureInt++
                ) {
                  const procedure = arrayOfClasses[lessonInt][procedureInt];

                  if (
                    realLesson === currentLessionInt &&
                    procedure >= currentProcedureInt
                  )
                    return; // Break if we're in the current lesson and have passed the current procedure

                  const key = "lesson" + realLesson + "Procedure" + procedure;

                  lessons[key] = true;
                }
              }
            };
            loopThroughLessonsForSaveStep();

            // Makes the previous lesson and procedure as the new current lesson and procedure
            // This way when the go back to the classroom, it will be at the correct place
            await saveClassSession.save(
              averageProficiency,
              totalPoints,
              totalTries,
              uid,
              suid,
              previousLesson,
              previousProcedure
            );

            await db
              .collection("users")
              .doc(uid)
              .collection("students")
              .doc(suid)
              .update({
                currentNoteCountToSave: {
                  // ...currentNoteCountToSave,
                  [localStorage.getItem("classroomInfoProgress")]:
                    that.currentNoteCount,
                },
              })
              .catch(console.error);
          }

          // Mark every procedure up to this point as complete
          const refLessons = db.collection("users").doc(suid);
          await refLessons
            .update({
              lessons: lessons,
            })
            .catch(function (error) {
              console.error("Error getting document:", error);
            });

          localStorage.setItem("classroomInfoCurrentLesson", "");

          this.$store.dispatch("setClassroomInfoWhiteListedTiles", []);

          this.$store.dispatch("setClassroomInfoMatchingGameFinished", false);
          this.$store.dispatch("setClassroomInfoThumbsGameFinished", false);
          this.$store.dispatch("setClassroomInfoBeatTheClockFinished", false);
          this.$store.dispatch("setClassroomInfoSeqPicsFinished", false);
          this.$store.dispatch("setClassroomInfoSeqTilesFinished", false);
          this.$store.dispatch("setClassroomInfoStopLightFinished", false);
          this.$store.dispatch("setClassroomInfoPhotoCardFinished", false);
          this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
          this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
          this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
          this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);

          that.$destroy();
          that.$el.remove(); // Remove the component's DOM elements
          that.$router.push({ path: "/dashboard" });
          window.location.reload();
        }
      } else if (true === finishConfirmSave.finish) {
        //Flush VueX state
        // this.$store.dispatch("setClassroomInfoPoints", 0);
        // this.$store.dispatch("setClassroomInfoTotalTries", 0);

        localStorage.setItem("classroomInfoCurrentLesson", "");

        this.$store.dispatch("setClassroomInfoWhiteListedTiles", []);

        this.$store.dispatch("setClassroomInfoMatchingGameFinished", false);
        this.$store.dispatch("setClassroomInfoThumbsGameFinished", false);
        this.$store.dispatch("setClassroomInfoBeatTheClockFinished", false);
        this.$store.dispatch("setClassroomInfoSeqPicsFinished", false);
        this.$store.dispatch("setClassroomInfoSeqTilesFinished", false);
        this.$store.dispatch("setClassroomInfoStopLightFinished", false);
        this.$store.dispatch("setClassroomInfoPhotoCardFinished", false);
        this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
        this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
        this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
        this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);

        that.$destroy();
        that.$el.remove(); // Remove the component's DOM elements
        that.$router.push({ path: "/dashboard" });
        window.location.reload();
      } else if ("exit" === finishConfirmSave) {
        return;
      }
    },
    async prevStep() {
      const that = this;

      if (that.isLoadingSlide) {
        return;
      }

      that.isLoadingSlide = true;

      if (0 === that.currentNoteCount) {
        that.isLoadingSlide = false;
        return;
      }

      // Wait for 500 miliseconds
      await new Promise((resolve) => setTimeout(resolve, 500));

      that.subStepCount--;
      that.currentNoteCount--;

      that.$root.$emit("prevStep");
      await that.findWhatToDisplay();

      setTimeout(() => {
        that.isLoadingSlide = false;
      }, 0);
    },
    async nextStep() {
      const that = this;

      if (that.isLoadingSlide) {
        return;
      }

      that.isLoadingSlide = true;

      if (that.teacherInstructions.length === that.currentNoteCount + 1) {
        that.isLoadingSlide = false;
        return;
      }

      // Wait for 500 miliseconds
      await new Promise((resolve) => setTimeout(resolve, 500));

      that.subStepCount++;
      that.currentNoteCount++;

      that.$root.$emit("nextStep", that.teacherInstructions.length);
      await that.findWhatToDisplay();
      setTimeout(() => {
        that.isLoadingSlide = false;
      }, 0);

      const currentLesson = localStorage.getItem("classroomInfoCurrentLesson");
      let currentLessionInt = parseInt(currentLesson.replace(/^\D+/g, ""));
      const info = localStorage.getItem("classroomInfoProgress").split(", ");
      const currentProcedureInt = info[1]
        ? parseInt(info[1].toLowerCase().replace(/^\D+/g, ""))
        : null;

      if (
        that.teacherInstructions.length === that.currentNoteCount + 1 &&
        13 === currentLessionInt &&
        3 === currentProcedureInt
      ) {
        setTimeout(function () {
          that.launchConfetti("fireworks");
          that.isLoadingSlide = false;

          setTimeout(function () {
            $("#finalModal").modal("show");
          }, 1000);
        }, 1000);
      } else if (
        that.teacherInstructions.length ===
        that.currentNoteCount + 1
      ) {
        // If last procedure in lesson
        if (
          (currentLessionInt >= 1 &&
            currentLessionInt <= 8 &&
            currentProcedureInt === 8) ||
          (currentLessionInt >= 9 &&
            currentLessionInt <= 12 &&
            currentProcedureInt === 10)
        ) {
          setTimeout(function () {
            that.launchConfetti("stars");
            that.isLoadingSlide = false;

            setTimeout(function () {
              $("#endLessonModal").modal("show");
            }, 1000);
          }, 1000);
        } else {
          setTimeout(function () {
            $("#endModal").modal("show");
            that.isLoadingSlide = false;
          }, 1000);
        }
      }
    },
    handleKeydown(e) {
      const that = this;
      console.log(e.keyCode);
      switch (e.keyCode) {
        case 37:
          if (!that.isLoadingSlide) {
            document.getElementById("slidePrev").click();
          }
          break;
        case 39:
          if (!that.isLoadingSlide) {
            document.getElementById("slideNext").click();
          }
          break;
      }
    },
    confirm: function () {
      return new Promise((resolve) => {
        $("#confirmModal").modal("show");

        $("#finishModal").click(function () {
          resolve({ finish: true });
        });

        $("#saveAndFinishModal").click(function () {
          resolve({ complete: true });
        });

        $("#saveAtCurrentStep").click(function () {
          resolve({ saveStep: true });
        });

        $("#confirmModal").on("hide.bs.modal", function (e) {
          resolve("exit");
        });
      });
    },
    async findWhatToDisplay() {
      const that = this;
      // First reset all current items
      that.activitySheet = false;
      that.game = false;
      that.selectedSheet = "";
      that.SSL = false;
      that.SSLObj.showImage = false;
      that.SSLObj.showStopLight = false;
      that.SSLObj.imageSrc = "";
      that.SSLObj.imageTwoSrc = "";
      // Reset pic area
      that.apple = false;
      that.balloon = false;
      that.chop = false;
      that.duck = false;
      that.eddy = false;
      that.fish = false;
      that.goat = false;
      that.itchy = false;
      that.jam = false;
      that.kite = false;
      that.lamp = false;
      that.map = false;
      that.nut = false;
      that.olive = false;
      that.pig = false;
      that.rat = false;
      that.ship = false;
      that.sun = false;
      that.ten = false;
      that.thumb = false;
      that.upper = false;
      that.vase = false;

      // console.log(that.currentNoteCount);
      // console.log(that.toShowOrToNotShow[that.currentNoteCount]);
      // console.log(that.visableImagesArray[that.currentNoteCount]);

      if (that.introduction) {
        if (that.currentNoteCount >= 2 && that.currentNoteCount < 4) {
          that.showSoundsBtn = true;
        } else {
          that.showSoundsBtn = false;
        }

        if (that.currentNoteCount >= 7 && that.currentNoteCount < 9) {
          that.showObjsBtn = true;
        } else {
          that.showObjsBtn = false;
        }

        return;
      }

      if (that.sandbox) {
        that.showPicArea = true;

        const studentUid = localStorage.getItem("classroomInfoStudentID");
        let studentDocData = await db.collection("users").doc(studentUid).get();
        let studentData = studentDocData.data();

        // Loop through the student.tiles and set the respective properties
        for (let tileName in studentData.tiles) {
          if (studentData.tiles.hasOwnProperty(tileName)) {
            that[tileName] = studentData.tiles[tileName];
          }
        }

        return;
      }

      if (that.subTitles) {
        if (that.subTitles[that.currentNoteCount].split(":")[1]) {
          that.subStep =
            "<b>" +
            that.subTitles[that.currentNoteCount].split(":")[0] +
            "</b>:" +
            that.subTitles[that.currentNoteCount].split(":")[1];
        } else {
          that.subStep = that.subTitles[that.currentNoteCount];
        }
      }

      if (that.visableImagesArray[that.currentNoteCount].includes("Game")) {
        that.game = true;
      } else if (
        that.visableImagesArray[that.currentNoteCount].includes("AS")
      ) {
        /////////////////VC?
        that.activitySheet = true;

        if (that.visableImagesArray[that.currentNoteCount].includes("SSP3")) {
          that.selectedSheet = "sound3";
        } else if (
          that.visableImagesArray[that.currentNoteCount].includes("SSP2")
        ) {
          that.selectedSheet = "sound2";
        } else if (
          that.visableImagesArray[that.currentNoteCount].includes("SCT2")
        ) {
          that.selectedSheet = "color2";
        } else if (
          that.visableImagesArray[that.currentNoteCount].includes("SCT3")
        ) {
          that.selectedSheet = "color3";
        } else if (
          that.visableImagesArray[that.currentNoteCount].includes("SSL")
        ) {
          that.selectedSheet = "stopLight";

          var image = "";
          that.SSLObj.showImage = false; //reset

          var allImagesInCell =
            that.visableImagesArray[that.currentNoteCount].split(",");
          for (let i = 0; i < allImagesInCell.length; i++) {
            if (
              arrayOfImages.includes(
                allImagesInCell[i].toLowerCase().replace(/\s/g, "")
              )
            ) {
              that.SSLObj.showImage = true;
              // image = allImagesInCell[i].toLowerCase().charAt(0).toUpperCase() + allImagesInCell[i].toLowerCase().slice(1)
              image = allImagesInCell[i].replace(/\s/g, ""); //Remove spaces
            }
          }

          if (that.visableImagesArray[that.currentNoteCount].includes("SSL")) {
            that.SSLObj.showStopLight = true;
          } else {
            that.SSLObj.showStopLight = false;
            that.SSLObj.showImage = true;
          }

          var storageRef = storage.ref();
          var starsRef = storageRef.child("Images/Tiles/" + image + ".jpg");

          // Not working rn so leave out
          if (!image) {
            that.SSL = true;
          }

          // Get the download URL
          const url = await starsRef.getDownloadURL().catch(function (error) {
            console.error(error);
          });

          that.SSLObj.imageSrc = url;
          that.SSL = false; //true
        }
      } else if (
        that.visableImagesArray[that.currentNoteCount].includes("SSL") ||
        that.visableImagesArray[that.currentNoteCount].split(",").length === 1
      ) {
        var image;
        var imageTwo;
        that.SSLObj.showImage = false; //reset

        if (
          that.visableImagesArray[that.currentNoteCount].split(",").length === 1
        ) {
          // If only on image then get it's name
          image = that.visableImagesArray[that.currentNoteCount];
        } else {
          //This assumes that the first item *IS* SSL
          image = that.visableImagesArray[that.currentNoteCount]
            .split(",")[1]
            .replace(/\s/g, "");
          imageTwo = that.visableImagesArray[that.currentNoteCount].split(
            ","
          )[2]
            ? that.visableImagesArray[that.currentNoteCount]
                .split(",")[2]
                .replace(/\s/g, "")
            : "";
        }

        if (that.visableImagesArray[that.currentNoteCount].includes("SSL")) {
          that.SSLObj.showStopLight = true;
        } else {
          that.SSLObj.showStopLight = false;
          that.SSLObj.showImage = true;
        }

        var storageRef = storage.ref();
        // Get the download URL
        that.SSLObj.imageSrc = await storageRef
          .child("Images/Tiles/" + image + ".jpg")
          .getDownloadURL()
          .catch(console.error);

        if (imageTwo) {
          // Get the download URL
          that.SSLObj.imageTwoSrc = await storageRef
            .child("Images/Tiles/" + imageTwo + ".jpg")
            .getDownloadURL()
            .catch(console.error);
        }

        that.SSL = true;
      } else if (!that.visableImagesArray[that.currentNoteCount]) {
        console.log("Empty");
      }

      let splitByPipe;
      if (
        that.toShowOrToNotShow[that.currentNoteCount] &&
        that.toShowOrToNotShow[that.currentNoteCount].includes("|")
      ) {
        splitByPipe = that.toShowOrToNotShow[that.currentNoteCount].split("|");
      }

      if (!that.toShowOrToNotShow[that.currentNoteCount]) {
        that.showPicArea = false;
        that.toShowOrToNowShowArray = [];
      } else if (splitByPipe[0]) {
        // left
        that.showPicArea = true;

        const arrayOfPics = splitByPipe[0].split(",");

        for (let i = 0; i < arrayOfPics.length; i++) {
          const pic = arrayOfPics[i].toLowerCase().replace(/\s/g, "");

          that[pic] = true;
        }
      } else if (splitByPipe[1]) {
        // right
        that.showPicArea = false;

        const arrayOfPics = splitByPipe[1].split(",");

        that.toShowOrToNowShowArray = splitByPipe[1];
        console.log(splitByPipe[1]);
      }
    },
    resizePicArea() {
      const picAreaHeight = document.getElementById("picArea").offsetHeight;
      document.getElementById("leftTop").style.height = picAreaHeight + "px";
    },
    getPreviousLessonAndProcedure(currentLesson, currentProcedure) {
      // Find the index of the current lesson and procedure
      const lessonIndex = currentLesson - 1; // since array indexes start at 0
      const procedureIndex =
        arrayOfClasses[lessonIndex].indexOf(currentProcedure);

      if (procedureIndex > 0) {
        // Previous procedure is in the same lesson
        return [currentLesson, arrayOfClasses[lessonIndex][procedureIndex - 1]];
      } else if (lessonIndex > 0) {
        // Previous procedure is in the previous lesson
        const previousLessonIndex = lessonIndex - 1;
        const previousLessonLastProcedure =
          arrayOfClasses[previousLessonIndex].length - 1;
        return [
          lessonIndex,
          arrayOfClasses[previousLessonIndex][previousLessonLastProcedure],
        ];
      } else {
        // It's the first procedure of the first lesson
        return [1, arrayOfClasses[0][0]];
      }
    },
  },
  computed: {
    truncatedInstructions() {
      const text = this.teacherInstructions[this.currentNoteCount];
      const strippedText = this.stripHtml(text);
      const truncatedText = this.truncateText(strippedText, 130);
      return truncatedText;
    },
  },
  watch: {
    showPicArea(value) {
      this.$root.$emit("showPicAreaRoot", value);
    },
  },
  filters: {
    lowerCase: function (value) {
      if (!value) return "";
      value = value.toString();
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style scoped>
.tile:active {
  /* height: 110px !important; */
  box-shadow: 0px 0px 5px black;
}

.color:active {
  height: 43px !important;
  width: 43px !important;
  box-shadow: 0px 0px 5px black;
}

#classFullSize {
  margin-right: 0px !important;
}

/* #leftTop {
	display: flex;
} */

.row {
  margin-right: 98px !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.form-group {
  margin-bottom: 18px;
}
.kt-portlet__body {
  height: auto !important;
}

#leftMiddle {
  height: auto;
}

#leftBottom div {
  color: rgb(116, 120, 141) !important;
}

.kt-section__content--border {
  padding: 1.5rem;
  border: 3px solid #ebedf2;
}

video {
  width: 100%;
}

#classroomTeacher {
  background-color: #b1987a;
  height: 100%;
  min-height: 100vh;
}

.draggable {
  height: 100px;
  /* z-index: 999; */
  /* position: relative; */
}

.stackContainer {
  float: left;
  margin-right: 3px;
}

#picArea {
  /* padding: 10px; */
  min-height: 120px;
  /* background: white; */
  border-radius: 5px;
}

.imgContainer {
  /* float: left; */
  position: relative !important;
  /* margin-right: 82px;
    margin-bottom: 110px; */
  width: 72px;
  height: 100px;
}

.imgContainer img {
  position: absolute !important;
}

#showTiles {
  display: contents;
  margin-left: 10px;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#instructionsArea {
  font-size: 16px;
}

#instructionsAreaBefore {
  background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centered-link {
  display: flex;
  justify-content: center;
}

.smolArrowToggle {
  background-color: #f7f9ff;
  border-radius: 5px;
  color: #93a2dd;
}

.grid {
  display: flex;
}

.column {
  flex: 1;
}

img {
  width: 100%;
}
</style>
