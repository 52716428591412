<template>
  <div v-if="!ifChart">
    <div class="soundsStopLight">
      <div id="gamePlaceholder">
        <img v-show="showImage" :src="imageSrc" />
        <div id="showStopLight" v-show="showStopLight">
          <div id="stopLight">
            <div class="stopLightImgs">
              <img
                id="stopLightImg"
                style="height: 200px; top: 150px"
                :src="imageSrc"
              />
              <img
                id="stopLightImgTwo"
                style="height: 200px; top: 150px"
                :src="imageTwoSrc"
                v-show="imageTwoSrc"
              />
            </div>
            <img
              id="stopLightBGImg"
              class="ifNotChart"
              src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSounds%20Stop%20Light.jpg?alt=media&token=e7db96a3-3d3b-40a4-8cb3-53f32782a99f"
            />
          </div>
        </div>
        <!-- <chart v-show="showChart" selectedSheet="stopLight" v-observe-visibility="visibilityChanged"></chart> -->
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./Chart";

export default {
  name: "soundsStopLight",
  data() {
    return {
      style: "height: 600px; cursor: pointer;",
      ifChart: false,
      // image: '',
      // imageSrc: '',
      // showImage: true,
      // showStopLight: false,
      // showChart: false,
      // currentNoteCount: 1,
    };
  },
  props: ["showImage", "showStopLight", "imageSrc", "imageTwoSrc"],
  components: { chart },
  mounted() {
    const that = this;
    // alert('mounted')
    //     if(props.ifChart) {
    //       style= ''
    //     }

    // const info = localStorage.getItem('classroomInfoProgress').split(', ');
    // const lesson = info[0].toLowerCase();
    // const procedure = info[1].toLowerCase();

    // if ('lesson 1' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Map.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Nut.jpg';
    //   }
    // } else if ('lesson 2' === lesson) {

    //   if ('procedure 4' === procedure) {
    //     that.image = 'Fish.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Vase.jpg';
    //   }
    // } else if ('lesson 3' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Thumb.jpg';
    //   }
    // } else if ('lesson 4' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Kite.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Goat.jpg';
    //   }
    // } else if ('lesson 5' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Sun.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Ship.jpg';
    //   }
    // } else if ('lesson 6' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Itchy.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Eddy.jpg';
    //   }
    // } else if ('lesson 7' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Upper.jpg';
    //   }
    // } else if ('lesson 8' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Apple.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Olive.jpg';
    //   }
    // } else if ('lesson 9' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Lamp.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Rat.jpg';
    //   }
    // } else if ('lesson 10' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Duck.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Ten.jpg';
    //   }
    // } else if ('lesson 11' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Chop.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Jam.jpg';
    //   }
    // } else if ('lesson 12' === lesson) {
    //   if ('procedure 4' === procedure) {
    //     that.image = 'Balloon.jpg';
    //   } else if ('procedure 5' === procedure) {
    //     that.image = 'Pig.jpg';
    //   }
    // }

    // var storageRef = storage.ref();

    // // Create a reference to the file we want to download
    // var starsRef = storageRef.child('Images/Tiles/' + that.image);

    // // Get the download URL
    // starsRef.getDownloadURL().then(function (url) {
    //   that.imageSrc = url;
    // }).catch(function (error) {
    //   console.error(error);
    // });

    // that.$root.$on('prevStep', () => {
    //   that.currentNoteCount--;

    //   if (that.currentNoteCount >= 4 && that.currentNoteCount < 12) {
    //     that.showImage = false;
    //     that.showStopLight = true;
    //     that.showChart = false;
    //   } else if (that.currentNoteCount >= 12) {
    //     that.showStopLight = false;
    //     that.showImage = false;
    //     that.showChart = true;
    //   } else {
    //     that.showStopLight = false;
    //     that.showImage = true;
    //     that.showChart = false;
    //   }

    // });

    // that.$root.$on('nextStep', (length) => {
    //   that.currentNoteCount++;

    //   if (that.currentNoteCount >= 4 && that.currentNoteCount < 12) {
    //     that.showImage = false;
    //     that.showStopLight = true;
    //   } else if (that.currentNoteCount >= 12) {
    //     that.showStopLight = false;
    //     that.showImage = false;
    //     that.showChart = true;
    //   } else {
    //     that.showStopLight = false;
    //     that.showImage = true;
    //     that.showChart = false;
    //   }

    // });
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.$root.$emit("chartActive", isVisible);
    },
  },
};
</script>

<style scoped>
#gamePlaceholder {
  height: 85vh;
  display: flex;
  justify-content: center;
}

#gamePlaceholder img {
  height: 85vh;
}

#showStopLight {
  width: 700px;
  /* background: rgb(175,180,255);
    background: linear-gradient(180deg, rgba(175,180,255,1) 0%, rgba(251,255,138,1) 100%); */
}

/* #showStopLight img {
    height: 40%;
} */

.stopLightImgs {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 200px;
  width: fit-content;
  z-index: 99;
  padding-top: 150px;
}

#stopLightBGImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  margin-top: -156px;
  position: relative;
  z-index: 1;
  top: -44px;
}

/* #stopLightBGImg img {
    height: 550px;
    margin-top: -250px;
    z-index: -99999999;
} */

.ifNotChart {
  height: 600px;
  cursor: pointer;
}

.ifChart {
  height: 310px;
  cursor: pointer;
}
</style>
