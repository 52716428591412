<template>
  <div id="newStudent">
    <div
      class="modal fade"
      id="newStudentModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="newStudentModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newStudentModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-user-add"></i> New Student
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- The first student is included in the price of the program.
            Additional students are $25 for 6 months. -->
            <form class="kt-form">
              <div v-show="showAdminArea" style="margin-bottom: 10px">
                <label>Assign student to tutor</label>
                <select
                  v-model="selectedTutorId"
                  class="form-control"
                  v-bind:class="{ 'is-invalid': !selectedTutorId }"
                >
                  <option
                    v-for="(tutor, key) in tutors"
                    :key="key"
                    :value="tutor.myUid"
                  >
                    {{ tutor.tutorEmail }}
                  </option>
                </select>
              </div>
              <label>Student's Full Name</label>
              <input
                v-model="studentFullName"
                class="form-control"
                v-bind:class="{ 'is-invalid': !studentFullName }"
                type="text"
                placeholder="John Smith"
                @change="onFormUpdated()"
              />
              <br />

              <label>Student's Birthday</label>
              <input
                v-model="studentDOB"
                v-bind:class="{ 'is-invalid': !studentDOB }"
                class="form-control"
                type="date"
                value="2010-01-25"
                @change="onFormUpdated()"
              />
              <br />

              <!-- <label>Gender</label>
              <select
                v-model="studentGender"
                v-bind:class="{ 'is-invalid': !studentGender }"
                class="form-control"
                @change="onFormUpdated()"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select> -->
              <br />

              <div>
                <hr />
                <h3>Input Barton Student Screening (Part C) scores</h3>
                <label>Opt out of Barton Student Screening</label><br />
                <span class="kt-switch kt-switch--icon">
                  <label>
                    <input
                      type="checkbox"
                      v-model="ignoreBarton"
                      checked="checked"
                      name
                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div v-show="!ignoreBarton">
                <!-- <h6>
                  If your student has not yet taken the Barton student screening
                  and you desire to do so, click on the link below.
                </h6>
                <label
                  ><a
                    class="btn btn-outline-brand"
                    href="https://bartonreading.com/students/#ss"
                    target="_blank"
                    >Link to Barton sudent screening ➜</a
                  ></label
                > -->

                <br />
                <label>1-Prescreening Part C, Column 1: Number Correct</label>
                <input
                  type="number"
                  class="form-control"
                  @input="checkScore()"
                  v-bind:class="{ 'is-invalid': scoreInvalid }"
                  v-model.number="initialScore"
                  data-kwimpalastatus="alive"
                />
                <br />

                <label
                  >2-Prescreening Part C, Column 2: Number Correct with
                  Repeat</label
                >
                <input
                  type="number"
                  class="form-control"
                  @input="checkScore()"
                  v-bind:class="{ 'is-invalid': scoreInvalid }"
                  v-model.number="repeatScore"
                  data-kwimpalastatus="alive"
                />
                <br />

                <label>3-Prescreening Part C, Column 3: Number Incorrect</label>
                <input
                  type="number"
                  class="form-control"
                  @input="checkScore()"
                  v-bind:class="{ 'is-invalid': scoreInvalid }"
                  v-model.number="inCorrectScore"
                  data-kwimpalastatus="alive"
                />
                <br />
              </div>
              <label>Parent's Full Name</label>
              <input
                v-model="parentFullName"
                class="form-control"
                type="text"
                placeholder="Sara Smith"
                @change="onFormUpdated()"
              />
              <br />

              <label>Parent's Phone:</label>
              <input
                v-model="parentPhone"
                class="form-control"
                type="tel"
                placeholder="1 (555) 555-5555"
                @change="onFormUpdated()"
              />
              <br />

              <label>Parent's Email</label>
              <input
                v-model="parentEmail"
                class="form-control"
                type="email"
                placeholder="student-email@email.com"
                @change="onFormUpdated()"
              />
              <!-- <span class="form-text text-muted">*This cannot be the same email as another account.</span> -->
              <br />

              <label>Notes (optional)</label>
              <textarea
                v-model="notes"
                class="form-control"
                rows="3"
                placeholder="Notes about the student (optional)"
              ></textarea>

              <br />
              <br />
            </form>
            <br />
          </div>
          <div class="modal-footer">
            <div style="margin-left: 32px; left: 0px; position: absolute">
              <h4 v-if="message" class="text-center" style="float: left">
                {{ message }}
              </h4>
            </div>
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Close
            </button>
            <a
              href="javascript:;"
              class="btn btn-brand"
              @click="addStudent()"
              v-bind:class="{ disabled: isSubmitting || isEmpty }"
            >
              <span
                v-if="isSubmitting"
                class="spinner-border"
                style="height: 19px; width: 19px"
              ></span>
              Add Student
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import firebase from "./firebaseInit"

export default {
  name: "newStudent",
  data() {
    return {
      studentFullName: "",
      studentDOB: "",
      initialScore: 0,
      repeatScore: 0,
      inCorrectScore: 0,
      scoreInvalid: true,
      parentFullName: "",
      parentPhone: "",
      parentEmail: "",
      message: "",
      isSubmitting: false,
      notes: "",
      isEmpty: true,
      ignoreBarton: false,
      showAdminArea: false,
      selectedTutorId: "",
      tutors: [],
    };
  },
  mounted() {
    const that = this;
    $("#newStudentModal").on("show.bs.modal", function (e) {
      // If is admin, show admin area
      if (window.location.href.includes("admin")) {
        that.showAdminArea = true;
        that.getTutors();
      } else {
        that.showAdminArea = false;
      }
    });
  },
  methods: {
    async addStudent() {
      const that = this;

      if (that.showAdminArea && !that.selectedTutorId) {
        that.message = "Please select a tutor.";
        return;
      }

      // TODO also check if empty and ignroe
      if (!that.validateEmail(that.parentEmail)) {
        that.message = "Please enter a valid email address.";
        return;
      }

      that.isSubmitting = true;

      const idToken = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .catch(function (error) {
          that.isSubmitting = false;
          that.$store.dispatch(
            "setInfoDanger",
            "Oops something went wrong, please try again later or contact support if the problem persists."
          );
          that.message =
            "Oops something went wrong, please try again later or contact support if the problem persists.";
          $("html, body").animate({ scrollTop: 0 }, "fast");
          //$('#newStudentModal').modal('hide');
        });

      let data = {
        studentFullName: that.studentFullName,
        studentDOB: that.studentDOB,
        parentFullName: that.parentFullName,
        parentPhone: that.parentPhone,
        parentEmail: that.parentEmail,
        username: that.makeid(),
        token: idToken,
        notes: that.notes,
        ignoreBarton: that.ignoreBarton,
        assignedTutorId: that.selectedTutorId,
      };

      if (!that.scoreInvalid) {
        data.initialScore = that.initialScore;
        data.repeatScore = that.repeatScore;
        data.inCorrectScore = that.inCorrectScore;
      } else {
        data.initialScore = 0;
        data.repeatScore = 0;
        data.inCorrectScore = 15;
      }

      // await that.$http.post("/api/tourToggle", { token: idToken, showTour: false }).catch(console.error);

      that.$http.post("/api/newStudent", data).then(
        function (res) {
          if (res.body.success) {
            $("html, body").animate({ scrollTop: 0 }, "fast");
            that.message = "Student Added Successfully";

            try {
              const url = window.location.href;
              if (url.includes("?")) {
                // URL already has query parameters, append using '&'
                location.href = url + "&addedStudent=true";
              } else {
                // URL has no query parameters, append using '?'
                location.href = url + "?addedStudent=true";
              }
            } catch (err) {
              console.error(err);
              // Reload the page
              location.reload();
            }
          } else {
            that.isSubmitting = false;
            //TODO handle errors better
            //$('#newStudentModal').modal('hide');
            that.$store.dispatch("setInfoDanger", "Error: " + res.body.message);
            that.message = "Error: " + res.body.message;
            $("html, body").animate({ scrollTop: 0 }, "fast");
          }
        },
        function (err) {
          console.error(err);
          that.isSubmitting = false;
          that.$store.dispatch(
            "setInfoDanger",
            "Oops something went wrong, please try again later or contact support if the problem persists: " +
              err.body
          );
          that.message =
            "Oops something went wrong, please try again later or contact support if the problem persists: " +
            err.body;
          $("html, body").animate({ scrollTop: 0 }, "fast");
        }
      );
    },
    checkScore() {
      const that = this;
      const sum = that.initialScore + that.repeatScore + that.inCorrectScore;

      if (sum === 15) {
        that.scoreInvalid = false;
      } else {
        that.scoreInvalid = true;
      }
    },
    makeid() {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    onFormUpdated() {
      const that = this;

      that.isEmpty = that.studentFullName === "" || that.studentDOB === "";
      // that.parentFullName === "" ||
      // that.parentPhone === "" ||
      // that.parentEmail === "";

      // Check if that.parentEmail is a valid email
      if (that.parentEmail !== "") {
        if (!that.validateEmail(that.parentEmail)) {
          that.message = "Please enter a valid email address.";
          return;
        } else {
          that.message = false;
        }
      }
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async getTutors() {
      const that = this;

      // Get the user's uId
      that.myUid = localStorage.getItem("userID");

      // Get tutors from firebase
      const querySnapshot = await db
        .collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("tutors")
        .get()
        .catch(function (error) {
          console.error("Error getting document:", error);
        });

      // Loop through tutors and add to tutors array
      querySnapshot.docs.forEach((doc) => {
        const tutorData = doc.data();

        var tutor = {
          tutorFullName: tutorData.tutorFullName,
          tutorEmail: tutorData.tutorEmail,
          daysAsATutor: tutorData.dateCreated,
          myUid: tutorData.myUid,
        };

        that.tutors.push(tutor);
      });
    },
  },
};
</script>

<style scoped>
p.error {
  color: #fd397a;
}
</style>
