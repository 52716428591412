<template>
  <div id="videoTraining">
    <div class="container">
      <h1 style="margin-top: 15px; color: black; font-weight: bold;">Contents</h1>
      <ul>
        <li>
          <h2><a href="#fis">Foundation in Sounds™ VideoTraining</a></h2>
        </li>
        <li>
          <h2>
            <a href="#fisClassroom"
              >How to Use the Virtual Classroom Video Training</a
            >
          </h2>
        </li>
      </ul>
    </div>

    <div id="fis" class="thick-blue-bar">
      <span>Foundation in Sounds™ VideoTraining</span>
    </div>

    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="row" v-if="images.length > 0">
        <div
          :key="key"
          v-for="(image, key) in images.slice(0, 18)"
          class="col-lg-6 col-xl-4 order-lg-1 order-xl-1"
        >
          <!--begin::Portlet-->
          <div class="kt-portlet kt-portlet--height-fluid imageHolder">
            <div class="kt-portlet__head kt-portlet__head--noborder">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title" v-if="images.length > 0">
                  {{ image.title }}
                </h3>
              </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
              <a
                href="javascript:;"
                @click="openVideoModel(image.position)"
                data-toggle="modal"
                data-target="#videoPlayerModel"
              >
                <img
                  v-if="images.length > 0"
                  :src="image.src"
                  class="videoImage"
                />
              </a>
            </div>
          </div>
          <!--end::Portlet-->
        </div>
      </div>
    </div>

    <div id="fisClassroom" class="thick-blue-bar">
      <span>How to Use the Virtual Classroom Video Training </span>
    </div>

    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="row" v-if="images.length > 0">
        <div
          :key="key"
          v-for="(image, key) in images.slice(18)"
          class="col-lg-6 col-xl-4 order-lg-1 order-xl-1"
        >
          <!--begin::Portlet-->
          <div class="kt-portlet kt-portlet--height-fluid imageHolder">
            <div class="kt-portlet__head kt-portlet__head--noborder">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title" v-if="images.length > 0">
                  {{ image.title }}
                </h3>
              </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
              <a
                href="javascript:;"
                @click="openVideoModel(image.position)"
                data-toggle="modal"
                data-target="#videoPlayerModel"
              >
                <img
                  v-if="images.length > 0"
                  :src="image.src"
                  class="videoImage"
                />
              </a>
            </div>
          </div>
          <!--end::Portlet-->
        </div>
      </div>
    </div>

    <!-- model v-if="true === this.modalDataReady"  -->
    <div
      class="modal fade"
      id="videoPlayerModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="videoPlayerModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="videoPlayerModalCenterTitle">
              {{ video.title }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <video
              id="my-video"
              controls
              controlslist="nodownload"
              preload="auto"
              poster="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F1%20-%20%20Introduction.jpg?alt=media&token=0882e12e-7c91-47b4-9b00-287982523ab0"
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F1%20-%20Introduction.mp4?alt=media&token=4c678826-df66-48c9-9171-3709b93cb0b4"
                type="video/mp4"
              />
            </video>
          </div>
          <div class="modal-footer">
            <a
              href="javascript:;"
              v-bind:class="{ disabled: video.currentIndex === 0 }"
              class="btn btn-outline-brand"
              @click="prevVideo(video.currentIndex)"
              >Prevous Video</a
            >
            <a
              href="javascript:;"
              v-bind:class="{ disabled: video.currentIndex === 17 }"
              class="btn btn-outline-brand"
              @click="nextVideo(video.currentIndex)"
              >Next Video</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- end model -->
  </div>
</template>

<script>
//import videojs from 'video.js'

let videoData = [
  //TODO Add *dev* images and metadata to here rather than pulling from firebase storage metadata
  // {
  //   src: '',
  //   sound: 'a',
  //   name: 'apple',
  // },
];

export default {
  name: "videoTraining",
  data() {
    return {
      images: [
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F1%20-%20Introduction.mp4?alt=media&token=4c678826-df66-48c9-9171-3709b93cb0b4",
          title: "Introduction",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F1%20-%20%20Introduction.jpg?alt=media&token=dcfd391b-97bc-4d14-adc9-c8dd650d7b11",
          position: 0,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F2%20-%20Materials%20Overview.mp4?alt=media&token=be77b2c7-cd36-4bc0-bf8c-64697b5ba3b7",
          title: "Materials Overview",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F2%20-%20Materials%20Overview.jpg?alt=media&token=d6be312a-6d3c-45a1-8bfc-ba4a1b5f1b1f",
          position: 1,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F3%20-%20Making%20Sounds%20Overview.mp4?alt=media&token=3684c29b-d83c-4b25-8c41-c2bdc781b259",
          title: "Making Sounds Overview",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F3%20-%20Making%20Sounds%20Overview.jpg?alt=media&token=90334fcc-67a7-4600-b063-d02ca0ca08e6",
          position: 2,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F4%20-%20Lessons%20Introduction.mp4?alt=media&token=f07ac3c1-d117-42b2-bf63-68859d560f03",
          title: " Lessons Introduction",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F4%20-%20Lessons%20Introduction.jpg?alt=media&token=00bf9543-78f6-4c9b-855e-1beef880f5ff",
          position: 3,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F5%20-%20Student%20Introduction.mp4?alt=media&token=4bb8567c-c523-42c8-9f20-f09542bddb79",
          title: "Student Introduction",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F5%20-%20Student%20Introduction.jpg?alt=media&token=bcc3dff6-3a7b-4d6f-b782-5dec287d0401",
          position: 4,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F6%20-%20Procedure%204%20and%205%20-%20Consonants.mp4?alt=media&token=56257178-117a-4ea8-be12-02862ea188b3",
          title: "The Teaching of Consonants",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F6%20-%20The%20Teaching%20of%20Consonants.jpg?alt=media&token=062960b0-d95b-4b0a-bfd7-05eeb7b1f0b9",
          position: 5,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F7%20-%20Procedure%206.mp4?alt=media&token=f989f260-ed5c-4503-97e3-eb86e8524b48",
          title: "Which Word",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F7%20-%20Which%20Word.jpg?alt=media&token=be4ad16b-5259-4f8a-bee9-c721dabdae7d",
          position: 6,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F8%20-%20Procedure%207.mp4?alt=media&token=eeef192f-4747-454f-b53b-4d14cf033294",
          title: "Sequencing With Sound Pictures",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F8%20-%20Sequencing%20With%20Sound%20Pictures.jpg?alt=media&token=de6ce0cb-c567-4aec-8963-33bd894419da",
          position: 7,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F9%20-%20Procedure%208.mp4?alt=media&token=481a9cad-475d-44d9-8731-808ca4ff1f87",
          title: "Sequencing With Colored Tiles",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F9%20-%20Sequencing%20With%20Colored%20Tiles.jpg?alt=media&token=7f604d5b-8152-43c8-b895-e7293f3b3eba",
          position: 8,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F10%20-%20Student%20Tracking%20Sheet.mp4?alt=media&token=edc36786-99cb-49b5-9f6d-d1bc9760a2b1",
          title: "Student Tracking Sheet",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F10%20-%20Student%20Tracking%20Sheet.jpg?alt=media&token=2f58c674-588a-463a-b816-59b61862c29c",
          position: 9,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F11%20-%20Procedure%201.mp4?alt=media&token=b27d3ac4-724c-4fd9-82e5-2240f7a35cea",
          title: "Review",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F11%20-%20Review%20-%20Play%20a%20Game.jpg?alt=media&token=d30386b8-7e38-4da4-8bb8-a874e7b3e399",
          position: 10,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F12%20-%20Procedure%202.mp4?alt=media&token=fe7ed203-98ac-42a1-9077-36547edb49e2",
          title: "Review - Sequencing With Sound Pictures",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F12%20-%20Review%20-%20Sequencing%20With%20Sound%20Pictures.jpg?alt=media&token=02695ee7-3f87-4195-9b6e-ed01b6c5ad9a",
          position: 11,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F13%20-%20Procedure%203.mp4?alt=media&token=c4c147a3-1c3a-41dc-9554-b5ab8bba3990",
          title: "Review - Sequencing With Colored Tiles",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F13%20-%20Review%20-%20Sequencing%20With%20Colored%20Tiles.jpg?alt=media&token=d5c7a9c3-58bd-4de9-aaa2-82df6801307e",
          position: 12,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F14%20-%20Procedure%204%20and%205%20-%20Vowels.mp4?alt=media&token=2f9a3752-59e1-44b0-b3b6-8f20f69de5b4",
          title: "The Teaching of Vowels",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F14%20-%20The%20Teaching%20of%20Vowels.jpg?alt=media&token=4ae9a333-5d5a-4d43-8354-24314ad6aafe",
          position: 13,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F15%20-%20Procedure%209.mp4?alt=media&token=a7e13ed9-8b13-4522-a880-68ba146d5745",
          title: "Changing With Sound Pictures",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F15%20-%20Changing%20With%20Sound%20Pictures.jpg?alt=media&token=6c36fdbd-2c6b-42cb-a25b-3e0ef14fb1c2",
          position: 14,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F16%20-%20Procedure%2010.mp4?alt=media&token=342e8edd-0d6a-489f-b47f-1671a4b7e6a4",
          title: "Changing With Colored Tiles",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F16%20-%20Changing%20With%20Colored%20Tiles.jpg?alt=media&token=1a89bd23-1795-4d62-8aa1-ae3025f09dcf",
          position: 15,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F17%20-%20Error%20Correction.mp4?alt=media&token=4cdf94d5-a7c1-4fc9-a039-4b39f61a7264",
          title: "Error Correction",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F17%20-%20Error%20Correction.jpg?alt=media&token=7bfb9b2a-38b0-48f9-aa3d-2e5851bc2acb",
          position: 16,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F18%20-%20A%20Final%20Note.mp4?alt=media&token=ce1953c5-53c2-40bb-81e2-fab4ec5f6497",
          title: "A Final Note",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F18%20-%20A%20Final%20Note.jpg?alt=media&token=141148e1-f27f-45f9-a091-163a36dc5667",
          position: 17,
        },
        //
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F1.%20A%20Note%20as%20We%20Begin.mp4?alt=media&token=421f6582-5667-4b21-9ef5-664d6491345e",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F%231.png?alt=media&token=26dc60d9-8148-47e3-8341-01e45b39e66e",
          position: 18,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F2.%20Welcome%20-%20Made%20with%20Clipchamp.mp4?alt=media&token=d9a47aaf-3d09-4753-8827-7f03f13a3df6",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F%232.png?alt=media&token=b403d82f-7efa-493e-be23-cfb9f53f9100",
          position: 19,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F3.%20The%20Dashboard.mp4?alt=media&token=5342e933-04eb-423f-876f-7400e07b3574",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F%233.png?alt=media&token=1eba89dc-5032-4f2c-a581-72a559ababdf",
          position: 20,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F4.%20Student%20Profile%20Page.mp4?alt=media&token=515c4e17-ffbf-4544-9c99-d3517d682319",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F%234.png?alt=media&token=1f21ee44-7ae9-4a46-bb3e-a1aee082239e",
          position: 21,
        },
        {
          videoUrl:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F5.%20Beginning%20With%20a%20Student.mp4?alt=media&token=bb866bfa-068a-4ef7-bcae-d3d08e1b96fc",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F%235.png?alt=media&token=038d6668-d648-4972-af29-6730f017c57d",
          position: 22,
        },
        {
          videoUrl: // 6
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F6.%20Introduction.mp4?alt=media&token=3a93bb44-2307-476e-9f9c-3bf1bf8921c7",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F%236.png?alt=media&token=07c9ee06-63f0-4d7d-af37-552a626077a9",
          position: 23,
        },
        {
          videoUrl: // 7
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F7.%20The%20Sandbox%20-%20Made%20with%20Clipchamp.mp4?alt=media&token=1e658c35-fd6f-4436-9c90-319c19f6314a",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ftraining%20vids%2F%237.png?alt=media&token=936aa44c-e888-4bb9-8c56-9fbdf7eecaed",
          position: 24,
        },
        {
          videoUrl: // 8
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F8.%20Teaching%20Face%20to%20Face.mp4?alt=media&token=a3a66043-bfec-48fb-8545-4a29ea789fbb",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F8.png?alt=media&token=c06cff4b-c803-43de-a48d-c4c98686f633",
          position: 25,
        },
        {
          videoUrl: // 9
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2F9.%20In%20Summary.mp4?alt=media&token=9443ea44-0cc1-4d00-bad3-32f3452ec450",
          title: "",
          src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F9.png?alt=media&token=ca3ad3c7-b4e6-4a92-be52-68d29794c27b",
          position: 26,
        },
      ],
      video: {
        title: "",
        currentIndex: "",
        url: "",
      },
      modalDataReady: false,
    };
  },
  created() {
    //data fetch API here
    const that = this;
    // //var storage = firebase.storage();
    // var storageRef = storage.ref()

    // // Create a reference under which you want to list
    // var listRef = storageRef.child('Images')

    // // Find all the prefixes and items.
    // listRef
    //   .listAll()
    //   .then(function (res) {
    //     //console.log(res.items);

    //     res.items.forEach((item) => {
    //       var starsRef = storageRef.child(item.location.path)

    //       //Get metadata properties
    //       starsRef
    //         .getMetadata()
    //         .then(function (metadata) {
    //           // Get the download URL
    //           starsRef
    //             .getDownloadURL()
    //             .then(function (url) {
    //               let position = parseInt(metadata.customMetadata.position)
    //               let imgObj = {
    //                 videoUrl: metadata.customMetadata.videoUrl,
    //                 title: metadata.customMetadata.title,
    //                 src: url,
    //                 position: position,
    //               }

    //               //that.images.splice(position, 0, imgObj);
    //               //that.images[position] = imgObj;
    //               that.$set(that.images, position, imgObj)
    //               //ue.set( target, propertyName/index, value )
    //               //console.log("position", position, "imgObj:", imgObj);
    //             })
    //             .catch(function (error) {
    //               that.$store.dispatch('setInfoDanger', error.code)
    //             })
    //         })
    //         .catch(function (error) {
    //           // Uh-oh, an error occurred!
    //           console.log(error)
    //         })
    //     })
    //   })
    //   .catch(function (error) {
    //     // Uh-oh, an error occurred!
    //     console.error(error)
    //   })
  },
  mounted() {
    const that = this;

    $("#videoPlayerModel").on("hidden.bs.modal", function (e) {
      //that.modalDataReady = false;
      let myVideo = document.getElementById("my-video");

      if (myVideo) {
        myVideo.load();
        myVideo.pause();
      }
    });

    let video = document.getElementById("my-video");

    video.onended = function (e) {
      that.nextVideo(that.video.currentIndex);
      console.log(that.video.currentIndex);
    };
  },
  methods: {
    prevVideo: function (index) {
      if (index > 0) {
        this.openVideoModel(index - 1);
      }
    },
    nextVideo: function (index) {
      if (index < this.images.length) {
        this.openVideoModel(index + 1);
      }
    },
    openVideoModel: function (position) {
      this.video.photoUrl = this.images[position].src;
      this.video.url = this.images[position].videoUrl;
      this.video.title = this.images[position].title;
      this.video.currentIndex = position;

      //this.modalDataReady = true;
      console.log(this.video.currentIndex);

      let myVideo = document.getElementById("my-video");
      if (myVideo) {
        myVideo.src = this.video.url;
      }
    },
  },
  watch: {
    // modalDataReady: function(val) {
    // }
  },
};
</script>

<style scoped>
.faq-component {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  /* font-family: "Calibri"; */
}

.thick-blue-bar {
  background-color: #001065;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
}
.imageHolder {
  float: left;
  background: #edf0ff;
}

.videoImage {
  width: 100% !important;
}

video {
  width: 100%;
}
</style>
