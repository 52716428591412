<template>
  <div id="teacherProfile">
    <div
      class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
    >
      <div class="row" id="rowContainer">
        <div class="col-lg-12">
          <!--begin::Portlet-->
          <div class="kt-portlet">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title" v-if="this.name !== ''">
                  {{ name }}'s Profile
                </h3>
              </div>
            </div>
            <!--begin::Form-->
            <form class="kt-form">
              <div class="kt-portlet__body">
                <div class="kt-section kt-section--first">
                  <div class="form-group">
                    <label>Full Name:</label>
                    <input
                      v-model="newName"
                      type="text"
                      class="form-control"
                      :placeholder="name"
                    />
                    <span class="form-text text-muted">Display Name</span>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__foot">
                <div class="kt-form__actions">
                  <button
                    @click="updateProfile"
                    type="reset"
                    class="btn btn-primary"
                  >
                    Save
                  </button>
                  <a href="/dashboard" class="btn btn-secondary">Cancel</a>
                </div>
              </div>
            </form>
            <!--end::Form-->
            <!--begin::Form-->
            <form class="kt-form">
              <div class="kt-portlet__body">
                <div class="kt-section kt-section--first">
                  <div class="form-group">
                    <a
                      href="javascript:;"
                      @click="passwordResetEmail"
                      class="btn btn-secondary"
                    >
                      Send Reset Password Email
                    </a>
                  </div>
                </div>
              </div>
            </form>
            <!--end::Form-->
          </div>
          <!--end::Portlet-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "./firebaseInit"
// import db from './firebaseDB'

export default {
  name: "teacherProfile",
  data() {
    return {
      name: "",
      newName: "",
      email: "",
      user: "",
      modal: {
        infoDanger: "",
        infoSuccess: "",
      },
    };
  },
  mounted() {
    const that = this;

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        that.name = user.displayName;
        that.email = user.email;
        that.user = user;
      }
    });
  },
  methods: {
    updateProfile: function () {
      const that = this;

      if (that.name === that.newName) {
        that.$store.dispatch(
          "setInfoDanger",
          "You must enter a differnt display name than the one you currently have"
        );
        $("html, body").animate({ scrollTop: 0 }, "fast");
      } else {
        that.user
          .updateProfile({
            displayName: that.newName,
          })
          .then(function () {
            // Update successful.
            that.$store.dispatch("setInfoSuccess", "Updated");
            that.name = that.newName;
          })
          .catch(function (error) {
            // An error happened.
            that.$store.dispatch("setInfoDanger", error);
            $("html, body").animate({ scrollTop: 0 }, "fast");
          });
      }
    },
    passwordResetEmail: function () {
      const that = this;

      if (that.email !== "") {
        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(function () {
            // Email sent.
            that.$store.dispatch("setInfoSuccess", "Email Sent");
          })
          .catch(function (error) {
            // An error happened.
            that.$store.dispatch("setInfoDanger", error);
            //that.$store.dispatch("setInfoDanger", '');
            $("html, body").animate({ scrollTop: 0 }, "fast");
          });
      } else {
        that.$store.dispatch(
          "setInfoDanger",
          "Please enter your email address first."
        );
        $("html, body").animate({ scrollTop: 0 }, "fast");
      }
    },
  },
};
</script>

<style scoped>
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>
