<template>
  <div class="classroomStudent">
    <div class="col-lg-4">...</div>
    <div class="col-lg-8">...</div>
  </div>
</template>

<script>
export default {
  name: "classroomStudent",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped></style>
