<template>
  <div id="settings" class="container"></div>
</template>

<script>
export default {
  name: "settings",
  data() {
    return {};
  },
  mounted() {
    const that = this;
  },
};
</script>

<style scoped></style>
