<template>
  <div class="container">
    <h2>Privacy Policy</h2>
    <div class="flow-text">
      <p>Your privacy</p>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: "signin",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style></style>
