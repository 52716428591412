<template>
  <div class="openData">
    <div class="container">
      <div class="col-lg-12" id="printThis">
        <div class="row centerH">
          <h3>Pre and Post Foundation in Sounds™ application student data</h3>
        </div>

        <div class="row" v-show="students.length">
          <div class="right">
            <button type="button" @click="print" class="btn btn-primary">
              <i class="icon-2x text-dark-50 flaticon-download"></i
              >Download/Print PDF
            </button>
          </div>
          <table id="table_id" class="display">
            <thead>
              <tr>
                <th>Student and Teacher (masked names)</th>
                <th>Pre-Test 1/25</th>
                <th>Correct the first time</th>
                <th>Correct after repeat</th>
                <th>Wrong</th>
                <th>Post-test 5/19</th>
                <th>Correct the first time</th>
                <th>Correct after repeat</th>
                <th>Wrong</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in students" :key="index">
                <td>{{ student.displayName }}</td>
                <td>{{ student.preTest }}</td>
                <td>{{ student.preCorrectFirst }}</td>
                <td>{{ student.preCorrectRepeat }}</td>
                <td>{{ student.preWrong }}</td>
                <td>{{ student.postTest }}</td>
                <td>{{ student.postCorrectFirst }}</td>
                <td>{{ student.postCorrectRepeat }}</td>
                <td>{{ student.postWrong }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-show="!students.length">loading...</div>
        <div v-show="students.length">
          <h5 style="margin: 30px">
            Average student test scores before and after using Foundation in
            Sounds™:
          </h5>
          <canvas id="myChart"></canvas>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

const labels = ["Correct 1st time", "Correct after repeat", "Wrong"];

let studentAverages = [];
let myChart = {};

export default {
  name: "openData",
  data() {
    return {
      students: [],
    };
  },
  created() {
    this.getStudents();
  },
  mounted() {
    const that = this;

    console.log("mounting chart");

    // Chart labels
    const labels = [
      "Pre-Test Number Wrong",
      "Post-Test Number Wrong",
      "Pre-Test Right with Repeat",
      "Post-Test Right with Repeat",
      "Pre-Test Number Correct",
      "Post-Test Number Correct",
    ];

    // Chart data
    const data = {
      labels: labels,
      datasets: [
        {
          label: "My First Dataset",
          data: [
            studentAverages.preWrong,
            studentAverages.postWrong,
            studentAverages.preCorrectRepeat,
            studentAverages.postCorrectRepeat,
            studentAverages.preCorrectFirst,
            studentAverages.postCorrectFirst,
          ],
          backgroundColor: [
            "rgba(153, 102, 255)",
            "rgba(153, 102, 255)",
            "rgba(153, 102, 255)",
            "rgba(153, 102, 255)",
            "rgba(153, 102, 255)",
            "rgba(153, 102, 255)",
            "rgba(153, 102, 255)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
            "rgb(201, 203, 207)",
          ],
          borderWidth: 1,
        },
      ],
    };

    // Chart config
    const config = {
      type: "bar",
      data: data,
      options: {
        plugins: {
          title: {
            display: false,
          },
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };

    // Init ChartJS -- for more info, please visit: https://www.chartjs.org/docs/latest/
    // var myChart = new Chart(ctx, config)

    // const data = {
    //   labels: labels,
    //   datasets: [
    //     {
    //       label: 'Pre-test',
    //       data: [studentAverages.preCorrectFirst, studentAverages.preCorrectRepeat, studentAverages.preWrong],
    //       backgroundColor: 'rgba(255, 99, 132, 0.50)',
    //       borderColor: 'rgba(255, 99, 132, 0.50)',
    //     },
    //     {
    //       label: 'Post-test',
    //       backgroundColor: '#1DC9B7',
    //       borderColor: '#1DC9B7',
    //       data: [studentAverages.postCorrectFirst, studentAverages.postCorrectRepeat, studentAverages.postWrong],
    //     },
    //   ],
    // }
    // const config = {
    //   type: 'line',
    //   data: data,
    //   options: {
    //     responsive: true,
    //     interaction: {
    //       mode: 'index',
    //       intersect: false,
    //     },
    //     stacked: false,
    //     plugins: {
    //       title: {
    //         display: true,
    //         text: 'Chart.js Line Chart - Multi Axis',
    //       },
    //     },
    //     scales: {
    //       y: {
    //         type: 'linear',
    //         display: true,
    //         position: 'left',
    //       },
    //       y1: {
    //         type: 'linear',
    //         display: true,
    //         position: 'right',

    //         // grid line settings
    //         grid: {
    //           drawOnChartArea: false, // only want the grid lines for one axis to show up
    //         },
    //       },
    //     },
    //   },
    // }
    myChart = new Chart(document.getElementById("myChart"), config);
  },
  methods: {
    async getStudents() {
      const that = this;

      const response = await axios.post("/api/openData");
      // that.students = response.data.data

      let studentTotals = {
        preCorrectFirst: [],
        preCorrectRepeat: [],
        preWrong: [],
        //
        postCorrectFirst: [],
        postCorrectRepeat: [],
        postWrong: [],
      };
      for (let i = 0; i < response.data.data.length; i++) {
        if (
          !response.data.data[i].postCorrectFirst ||
          !response.data.data[i].postCorrectRepeat ||
          !response.data.data[i].postWrong
        ) {
          continue;
        }

        const postTest = "";
        const preTest = "";
        const displayName = response.data.data[i].displayName;
        const preCorrectFirst = parseFloat(
          response.data.data[i].preCorrectFirst
        )
          ? parseFloat(response.data.data[i].preCorrectFirst)
          : 0;
        const preCorrectRepeat = parseFloat(
          response.data.data[i].preCorrectRepeat
        )
          ? parseFloat(response.data.data[i].preCorrectRepeat)
          : 0;
        const preWrong = parseFloat(response.data.data[i].preWrong)
          ? parseFloat(response.data.data[i].preWrong)
          : 0;

        const postCorrectFirst = parseFloat(
          response.data.data[i].postCorrectFirst
        )
          ? parseFloat(response.data.data[i].postCorrectFirst)
          : 0;
        const postCorrectRepeat = parseFloat(
          response.data.data[i].postCorrectRepeat
        )
          ? parseFloat(response.data.data[i].postCorrectRepeat)
          : 0;
        const postWrong = parseFloat(response.data.data[i].postWrong)
          ? parseFloat(response.data.data[i].postWrong)
          : 0;

        if (
          preCorrectFirst + preCorrectRepeat + preWrong == 15 &&
          postCorrectFirst + postCorrectRepeat + postWrong == 15
        ) {
          console.log("here?");
          studentTotals.preCorrectFirst.push(preCorrectFirst);
          studentTotals.preCorrectRepeat.push(preCorrectRepeat);
          studentTotals.preWrong.push(preWrong);

          studentTotals.postCorrectFirst.push(postCorrectFirst);
          studentTotals.postCorrectRepeat.push(postCorrectRepeat);
          studentTotals.postWrong.push(postWrong);

          that.students.push({
            displayName,
            postCorrectFirst,
            postCorrectRepeat,
            postTest,
            postWrong,
            preCorrectFirst,
            preCorrectRepeat,
            preTest,
            preWrong,
          });
        }
      }

      studentAverages = {
        preCorrectFirst:
          studentTotals.preCorrectFirst.reduce(
            (partialSum, a) => partialSum + a,
            0
          ) / studentTotals.preCorrectFirst.length,
        preCorrectRepeat:
          studentTotals.preCorrectRepeat.reduce(
            (partialSum, a) => partialSum + a,
            0
          ) / studentTotals.preCorrectRepeat.length,
        preWrong:
          studentTotals.preWrong.reduce((partialSum, a) => partialSum + a, 0) /
          studentTotals.preWrong.length,
        //
        postCorrectFirst:
          studentTotals.postCorrectFirst.reduce(
            (partialSum, a) => partialSum + a,
            0
          ) / studentTotals.postCorrectFirst.length,
        postCorrectRepeat:
          studentTotals.postCorrectRepeat.reduce(
            (partialSum, a) => partialSum + a,
            0
          ) / studentTotals.postCorrectRepeat.length,
        postWrong:
          studentTotals.postWrong.reduce((partialSum, a) => partialSum + a, 0) /
          studentTotals.postWrong.length,
      };

      console.log("got all data...");

      setTimeout(() => {
        let table = new DataTable("#table_id", {
          paging: false,
        });

        console.log("updating chart");
        myChart.data.datasets = [
          {
            label: "My First Dataset",
            data: [
              studentAverages.preWrong,
              studentAverages.postWrong,
              studentAverages.preCorrectRepeat,
              studentAverages.postCorrectRepeat,
              studentAverages.preCorrectFirst,
              studentAverages.postCorrectFirst,
            ],
            backgroundColor: [
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderWidth: 1,
          },
        ];

        myChart.update();
      }, 0);
    },
    print() {
      const element = document.getElementById("printThis");
      window.print();
      // html2pdf(element)
    },
  },
};
</script>

<style scoped>
.centerH {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.right {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 20px;
}

table.dataTable.display > tbody > tr.odd > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgb(38 214 120 / 33%);
}

table.dataTable.display > tbody > tr.odd > {
  box-shadow: inset 0 0 0 9999px rgb(38 214 120 / 21%);
  color: #c9421db9;
}
</style>
