module.exports = {
  save: async (averageProficiency, totalPoints, totalTries, userID, suid, lesson = null, procedure = null) => {

    // If lesson and procedure are not passed in, then we get the current lesson and procedure from localStorage
    if (!lesson && !procedure) {
      const info = localStorage.getItem('classroomInfoProgress').split(', ')

      lesson = info[0].toLowerCase().replace(/\s/g, '')
      procedure = info[1] ? info[1].toLowerCase() : 'introduction'
    }

    if ('procedure 1' === procedure) {
      //If on lesson 1
      if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 9, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 2', //Update to the next lesson
            currentLessonProgress: 12, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 17, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 2', //Update to the next lesson
            currentLessonProgress: 12, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 25, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 2', //Update to the next lesson
            currentLessonProgress: 12, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 33, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 2', //Update to the next lesson
            currentLessonProgress: 12, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 41, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 2', //Update to the next lesson
            currentLessonProgress: 12, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 49, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 2', //Update to the next lesson
            currentLessonProgress: 12, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 57, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 2', //Update to the next lesson
            currentLessonProgress: 12, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 65, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 2', //Update to the next lesson
            currentLessonProgress: 10, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 75, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 2', //Update to the next lesson
            currentLessonProgress: 10, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 85, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 2', //Update to the next lesson
            currentLessonProgress: 10, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 95, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 2', //Update to the next lesson
            currentLessonProgress: 10, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 2' === procedure) {
      if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 10, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 3', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 18, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 3', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 26, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 3', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 34, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 3', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 42, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 3', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 50, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 3', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 58, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 3', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 66, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 3', //Update to the next lesson
            currentLessonProgress: 20, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 76, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 3', //Update to the next lesson
            currentLessonProgress: 20, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 86, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 3', //Update to the next lesson
            currentLessonProgress: 20, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 96, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 3', //Update to the next lesson
            currentLessonProgress: 20, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson13' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson13',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 96, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 13, Procedure 3', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 3' === procedure) {
      if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 11, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 4', //Update to the next lesson
            currentLessonProgress: 25, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 19, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 4', //Update to the next lesson
            currentLessonProgress: 37, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 27, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 4', //Update to the next lesson
            currentLessonProgress: 37, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 35, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 4', //Update to the next lesson
            currentLessonProgress: 37, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 43, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 4', //Update to the next lesson
            currentLessonProgress: 37, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 51, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 4', //Update to the next lesson
            currentLessonProgress: 37, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 59, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 4', //Update to the next lesson
            currentLessonProgress: 37, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 67, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 4', //Update to the next lesson
            currentLessonProgress: 30, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 77, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 4', //Update to the next lesson
            currentLessonProgress: 30, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 87, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 4', //Update to the next lesson
            currentLessonProgress: 30, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 97, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 4', //Update to the next lesson
            currentLessonProgress: 30, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson13' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson13',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 97, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 4', //Update to the next lesson
            currentLessonProgress: 30, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 4' === procedure) {
      //If on lesson 1
      if ('lesson1' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson1',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 4, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 1, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 12, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 20, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 28, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 36, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 44, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 52, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 60, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 5', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 68, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 5', //Update to the next lesson
            currentLessonProgress: 40, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 78, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 5', //Update to the next lesson
            currentLessonProgress: 40, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 88, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 5', //Update to the next lesson
            currentLessonProgress: 40, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 98, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 5', //Update to the next lesson
            currentLessonProgress: 40, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 5' === procedure) {
      if ('lesson1' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson1',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 5, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 1, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
      if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 13, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 21, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 29, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 37, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 45, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 53, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 61, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 6', //Update to the next lesson
            currentLessonProgress: 62, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 69, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 6', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 79, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 6', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 89, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 6', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 99, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 6', //Update to the next lesson
            currentLessonProgress: 50, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 6' === procedure) {
      if ('lesson1' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson1',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 6, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 1, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 14, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 22, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 30, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 38, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 46, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 54, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 62, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 7', //Update to the next lesson
            currentLessonProgress: 75, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 70, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 7', //Update to the next lesson
            currentLessonProgress: 60, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 80, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 7', //Update to the next lesson
            currentLessonProgress: 60, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 90, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 7', //Update to the next lesson
            currentLessonProgress: 60, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 100, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 7', //Update to the next lesson
            currentLessonProgress: 60, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 7' === procedure) {
      if ('lesson1' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson1',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 7, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 1, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 15, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 23, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 31, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 39, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 47, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 55, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 63, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 8', //Update to the next lesson
            currentLessonProgress: 87, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 71, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 8', //Update to the next lesson
            currentLessonProgress: 70, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 81, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 8', //Update to the next lesson
            currentLessonProgress: 70, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 91, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 8', //Update to the next lesson
            currentLessonProgress: 70, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 101, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 8', //Update to the next lesson
            currentLessonProgress: 70, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 8' === procedure) {
      if ('lesson1' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson2',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 8, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 2, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson2' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson3',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 16, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 3, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson3' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson4',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 24, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 4, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson4' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson5',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 32, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 5, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson5' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson6',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 40, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 6, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson6' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson7',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 48, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 7, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson7' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 56, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson8' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson8',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 64, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 8, Procedure 9', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 72, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 9', //Update to the next lesson
            currentLessonProgress: 80, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 82, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 9', //Update to the next lesson
            currentLessonProgress: 80, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 92, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 9', //Update to the next lesson
            currentLessonProgress: 80, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 102, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 9', //Update to the next lesson
            currentLessonProgress: 80, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 9' === procedure) {
      if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson9',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 73, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 9, Procedure 10', //Update to the next lesson
            currentLessonProgress: 90, //% complete
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 83, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 10', //Update to the next lesson
            currentLessonProgress: 90, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 93, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 10', //Update to the next lesson
            currentLessonProgress: 90, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 103, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 10', //Update to the next lesson
            currentLessonProgress: 90, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    } else if ('procedure 10' === procedure) {
      if ('lesson9' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson10',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 74, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 10, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson10' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson11',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 84, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 11, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson11' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 94, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 12, Procedure 1', //Update to the next lesson
            currentLessonProgress: 0, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 104, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 13, Procedure 2', //Update to the next lesson
            currentLessonProgress: 100, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      } else if ('lesson12' === lesson) {
        await db
          .collection('users')
          .doc(userID)
          .collection('students')
          .doc(suid)
          .update({
            currentLesson: 'lesson12',
            graduatedStudent: false,
            currentNoteCount: 0,
            totalPoints: parseInt(totalPoints),
            totalTries: parseInt(totalTries),
            averageProficiency: averageProficiency.toFixed(2),
            studentProgress: 104, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
            studentReadableProgress: 'Lesson 13, Procedure 2', //Update to the next lesson
            currentLessonProgress: 100, //% complete of lesson 1
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error)
          })
      }
    }
  },
}
