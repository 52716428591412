<template>
  <div class="beatTheClock">
    <div class="col-lg-12">
      <div class="kt-portlet" data-ktportlet="true" id="kt_portlet_tools_2">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
              <i class="flaticon2-graph-1 kt-label-font-color-2"></i>
            </span>
            <h3 class="kt-portlet__head-title">Beat The Clock Game&nbsp;</h3>
            <h3 class="kt-portlet__head-title" style="margin-left: 110px">
              <span class="highlightMeBtn" @click="resetScore()"
                >Reset score</span
              >
              &nbsp;Points: <b>{{ correct }}</b> out of: <b>{{ totalTries }}</b>
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
              <div class="kt-portlet__head-group">
                <!-- <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-secondary btn-circle"
                  title="Undo"
                >
                  <i class="la la-undo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-secondary btn-circle"
                  title="Redo"
                >
                  <i class="la la-mail-forward"></i>
                </a> -->
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-success btn-circle"
                  title="Rules"
                  data-toggle="modal"
                  data-target="#matchingRulesModal"
                >
                  <i class="la la-exclamation-circle"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-info btn-circle"
                  v-show="openedGame && showToolBarButtons"
                  title="Game Settings"
                  data-toggle="modal"
                  data-target="#gameSettingsModal"
                >
                  <i class="la la-gear"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-danger btn-circle"
                  title="End Game"
                  @click="close"
                >
                  <i class="la la-close"></i>
                </a>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_mjlxyndbma"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    will-change: transform;
                    visibility: hidden;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(484px, -38px, 0px);
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 28px"></div>
                  <div class="tooltip-inner">Reload</div>
                </div>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_8wf7n27ova"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    will-change: transform;
                    visibility: hidden;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(442px, -38px, 0px);
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 34px"></div>
                  <div class="tooltip-inner">Collapse</div>
                </div>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_ca8wjj4ub2"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    transform: translate3d(516px, -39px, 0px);
                    top: 0px;
                    left: 0px;
                    will-change: transform;
                    visibility: hidden;
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 32px"></div>
                  <div class="tooltip-inner">Remove</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="kt-portlet__content">
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <!-- <div class="outerButton">
                </div> -->
                <div class="progress" v-show="showTimer">
                  <div
                    class="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    id="progressTimer"
                    style="width: 0%"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="row">
                <!--begin::Portlet-->

                <div class="imageHolder" v-show="openedGame">
                  <div id="videoContainer">
                    <video id="soundVideo" width="320" height="240" controls>
                      <source :src="videoSrc" type="video/mp4" />
                      <source :src="videoSrc" type="video/mov" />
                      Your browser does not support the video tag.
                    </video>
                    <div>
                      <button
                        type="button"
                        class="btn btn-danger btn-lg btn-block"
                        :disabled="!timerStarted"
                        v-show="timerStarted"
                        @click="cardClicked('neither', 0, '')"
                      >
                        Neither
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-lg btn-block innerButton"
                        v-show="!timerStarted"
                        @click="startTimer()"
                        :disabled="!loadedImages"
                      >
                        Start game
                      </button>
                    </div>
                  </div>
                  <img
                    :src="currentImageOneSrc"
                    :id="'image_' + currentImageOneName"
                    class="tileImage"
                    @click="
                      cardClicked(currentImageOneName, 1, currentImageOneSrc)
                    "
                  />
                  <img
                    :src="currentImageTwoSrc"
                    :id="'image_' + currentImageTwoName"
                    class="tileImage"
                    @click="
                      cardClicked(currentImageTwoName, 2, currentImageTwoSrc)
                    "
                  />
                  <!-- <img
                    :src="currentImageThreeSrc"
                    :id="'image_' + currentImageThreeName"
                    class="tileImage"
                    @click="cardClicked(currentImageThreeName, 3, currentImageThreeSrc)"
                  /> -->
                  <!-- <img
                    :src="currentImageFourSrc"
                    :id="'image_' + currentImageFourName"
                    class="tileImage"
                    @click="cardClicked(currentImageFourName, 4, currentImageFourSrc)"
                  /> -->
                </div>

                <!--end::Portlet-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Modal -->
    <div
      class="modal fade"
      id="matchingRulesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="matchingRulesModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-l modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="matchingRulesModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-exclamation-1"></i> Rules
            </h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="modal-body">
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                  When the Beat the Clock game is selected, a video, a red
                  "Neither" button, and two Sound Picture will appear. In the
                  video a person will say one of the sounds that have been
                  taught. If the sound made in the video matches the first sound
                  of the first Sound Picture, the student will click on that
                  Sound Picture. If the sound made in the video matches the
                  first sound of the second Sound Picture, the student will
                  click on that Sound Picture. If the sound made in the video
                  does not match the first sound of either Sound Picture, the
                  student will click on the red "Neither" button. A point is
                  awarded for each correct response. New videos with new sounds
                  will appear until the timer runs out. The student will attempt
                  to make as many correct responses as possible in the alloted
                  time. The tutor can adust the amount of time given by clicking
                  on the blue dot in the upper right hand corner once the game
                  has been selected.
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-outline-brand" data-dismiss="modal">Close</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <!-- Start Modal -->
    <div
      class="modal fade"
      id="gameSettingsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="gameSettingsModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-l modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="gameSettingsModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-exclamation-1"></i> Game
              Options
            </h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="modal-body">
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                  <div class="form-group">
                    <label>
                      Timer
                      <b>{{ readableTotalTime }}</b> (seconds)
                    </label>

                    <input
                      type="range"
                      class="custom-range"
                      min="5"
                      max="300"
                      value="30"
                      v-model="totalSeconds"
                      id="customRange2"
                    />
                  </div>

                  <div class="form-group row">
                    <label class="col-3 col-form-label">Display Timer</label>
                    <div class="col-3">
                      <span class="kt-switch kt-switch--icon">
                        <label>
                          <input
                            type="checkbox"
                            checked="checked"
                            v-model="showTimer"
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                    <!-- sd -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-outline-brand" data-dismiss="modal">Close</button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- End Modal -->
  </div>
</template>

<script>
let videoData = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fa_compressed.mov?alt=media&token=07d4fa24-9d18-43a9-9cd1-8afc846bfdf7",
    sound: "a",
    name: "apple",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fb.mp4?alt=media&token=00165a8f-87af-4f25-9a9f-184c242d1796",
    sound: "b",
    name: "balloon",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fch.mp4?alt=media&token=3479ffc1-4606-4ca0-8b19-c6cccb625abd",
    sound: "ch",
    name: "chop",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fg_compressed.mp4?alt=media&token=67511786-a4cd-48b4-b3c2-f2f1a81ae126",
    sound: "g",
    name: "goat",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fi.MOV.mov?alt=media&token=946fce0f-ede1-47c3-a876-85f690bb86a6",
    sound: "i",
    name: "itchy",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fj_compressed.MOV?alt=media&token=c79af521-c43d-4bcb-ac2a-3da7154e58f1",
    sound: "j",
    name: "jam",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fk_compressed.MOV?alt=media&token=2dc98887-1728-4f91-98b8-434dd0336dbc",
    sound: "k",
    name: "kite",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fl_compressed.MOV?alt=media&token=012bdde4-c49a-48a1-a944-0ebbcf96fa8f",
    sound: "l",
    name: "lamp",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fm_compressed.MOV?alt=media&token=faf66298-b49b-40da-9214-332c8a533d12",
    sound: "m",
    name: "map",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fn_compressed.MOV?alt=media&token=9946afdd-b673-428f-9d88-8501b15352e1",
    sound: "n",
    name: "nut",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fo_compressed.MOV?alt=media&token=4809140f-f3fb-4ff4-bd31-ca6f4be48114",
    sound: "o",
    name: "olive",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fp_compressed.MOV?alt=media&token=89df166a-382a-45f4-8a3a-22b979d7733f",
    sound: "p",
    name: "pig",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fr_compressed.MOV?alt=media&token=07fc3822-d41d-4085-8acb-af71acdc32e1",
    sound: "r",
    name: "rat",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fs_compressed.MOV?alt=media&token=9bb7a2d1-b1f7-4de5-b0eb-ab676b96c5c9",
    sound: "s",
    name: "sun",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fsh.MOV.mov?alt=media&token=be1499f7-4cfd-4458-b25d-028f0e3a6da6",
    sound: "sh",
    name: "ship",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ft.MOV.mov?alt=media&token=0e952ba4-9366-4eb3-bdd3-0474a607daf9",
    sound: "t",
    name: "ten",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fth_compressed.mp4?alt=media&token=bfcd7e92-9bdf-4225-ab7e-81cd4c71394b",
    sound: "th",
    name: "thumb",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fu.MOV.mov?alt=media&token=6cd1e257-795f-4bd6-bbc1-0fe3d5196a6d",
    sound: "u",
    name: "upper",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fv.MOV.mov?alt=media&token=25fff4a7-cc2d-4af1-b076-0f8e78de3d89",
    sound: "v",
    name: "vase",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ff_compressed.mp4?alt=media&token=9d98b8c9-f874-47c2-9aa9-01053958cc59",
    sound: "f",
    name: "fish",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fe_compressed.MOV?alt=media&token=d426a1fb-9f97-4803-840f-b2ba746d197f",
    sound: "e",
    name: "eddie",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fd.mp4?alt=media&token=e93ba769-ef03-4c1a-94cd-35d59da35782",
    sound: "d",
    name: "duck",
  },
];

export default {
  name: "beatTheClock",
  data() {
    return {
      thisGameOpened: false,
      images: [],
      currentImageOneSrc: "",
      currentImageOneName: "",
      currentImageTwoSrc: "",
      currentImageTwoName: "",
      currentImageThreeSrc: "",
      currentImageThreeName: "",
      currentImageFourSrc: "",
      currentImageFourName: "",
      cardIndex: 0,
      chosenCard: "",
      totalTime: 240,
      timerStarted: false,
      totalSeconds: 60,
      currentTime: 0,
      readableTotalTime: 60,
      stackNumber: 0,
      showTimer: false,
      loadedImages: false,
      openedGame: true,
      videoSrc: "",
      randomVideoObject: {},
      correct: 0,
      totalTries: 0,
      showToolBarButtons: false,
    };
  },
  async mounted() {
    const that = this;
    that.images = [];
    var storageRef = storage.ref();

    this.thisGameOpened = true;

    // Create a reference under which you want to list
    var listRef = storageRef.child("Images/Tiles");

    const studentUid = localStorage.getItem('classroomInfoStudentID')

    const doc = await db
      .collection("users")
      .doc(studentUid)
      .get()
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    if (doc.exists) {
      for (var tile in doc.data().tiles) {
        let obj = {
          name: tile,
          visable: doc.data().tiles[tile],
        };

        //Only push visable images
        if (obj.visable) {
          // //If in the array add it to the page
          // const currentLesson = that.$store.getters.getClassroomInfoWhiteListedTiles
          // if (true === currentLesson.includes(obj.name.toLowerCase())) {
          that.images.push(obj);
          // }
        }
      }

      // Find all the prefixes and items.
      const res = await listRef.listAll().catch((error) => {
        // Uh-oh, an error occurred!
        console.error(error);
      });

      var backSideRef = storageRef.child(
        "Images/classroomBackgrounds/Back of Cards.jpg"
      );
      backSideRef.getDownloadURL().then((backSidePath) => {
        res.items.forEach((item) => {
          var starsRef = storageRef.child(item.location.path);
          // Get the download URL
          starsRef
            .getDownloadURL()
            .then((url) => {
              let path = item.location.path;
              let name = path
                .split("Images/Tiles/")[1]
                .split(".jpg")[0]
                .toLowerCase();

              for (let i = 0; i < that.images.length; i++) {
                if (that.images[i].name == name) {
                  let obj = {
                    name: that.images[i].name,
                    visable: that.images[i].visable,
                    src: url,
                    backSidePath: backSidePath,
                  };

                  //Only push visable images
                  if (obj.visable) {
                    that.$set(that.images, i, obj);
                  }
                }
              }
              that.loadedImages = true;
            })
            .catch((error) => {
              that.$store.dispatch("setInfoDanger", error.code);
            });
        });
      });
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },
  methods: {
    cardClicked(cardName, stackNumber, src) {
      const that = this;
      if (src.includes("Back%20of%20Cards")) {
        return;
      }

      console.log("cardName", cardName);
      console.log("that.currentImageTwoName", that.currentImageTwoName);
      console.log("that.currentImageOneName", that.currentImageOneName);
      console.log("that.randomVideoObject", that.randomVideoObject);

      if (
        that.randomVideoObject.name === cardName ||
        (cardName == "neither" &&
          that.currentImageTwoName != that.randomVideoObject.name &&
          that.currentImageOneName != that.randomVideoObject.name)
      ) {
        console.log(".........added a point +1");
        //Correct
        that.correct++;
        // let addOnePoint = this.$store.getters.getClassroomInfoPoints + 1
        // let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1
        // this.$store.dispatch('setClassroomInfoPoints', addOnePoint)
        // this.$store.dispatch('setClassroomInfoTotalTries', addOneTry)
      } else {
        console.log(".........didn't add a point");
        let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1;
        this.$store.dispatch("setClassroomInfoTotalTries", addOneTry);
      }

      that.totalTries++;

      that.nextCard();
    },
    // finishedCard(correct, stackNumber) {
    //   if (correct) {
    //     let addOnePoint = this.$store.getters.getClassroomInfoPoints + 1
    //     let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1
    //     this.$store.dispatch('setClassroomInfoPoints', addOnePoint)
    //     this.$store.dispatch('setClassroomInfoTotalTries', addOneTry)
    //   } else {
    //     let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1
    //     this.$store.dispatch('setClassroomInfoTotalTries', addOneTry)
    //   }

    //   $('#checkAnswer').modal('hide')

    //   if (1 === stackNumber) {
    //     //Image one
    //     const newIndexOne = this.getRandomIntInclusive()
    //     this.currentImageOneSrc = this.images[newIndexOne].src
    //     this.currentImageOneName = this.images[newIndexOne].name
    //   } else if (2 === stackNumber) {
    //     //Image two
    //     const newIndexTwo = this.getRandomIntInclusive()
    //     this.currentImageTwoSrc = this.images[newIndexTwo].src
    //     this.currentImageTwoName = this.images[newIndexTwo].name
    //   } else if (3 === stackNumber) {
    //     //Image three
    //     const newIndexThree = this.getRandomIntInclusive()
    //     this.currentImageThreeSrc = this.images[newIndexThree].src
    //     this.currentImageThreeName = this.images[newIndexThree].name
    //   }
    //   //  else if (4 === stackNumber) {
    //   //   //Image four
    //   //   const newIndexFour = this.getRandomIntInclusive()
    //   //   this.currentImageFourSrc = this.images[newIndexFour].src
    //   //   this.currentImageFourName = this.images[newIndexFour].name
    //   // }
    // },
    shuffleArray() {
      for (let i = this.images.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.images[i], this.images[j]] = [this.images[j], this.images[i]];
      }
    },
    getRandomIntInclusive() {
      const min = Math.ceil(0);
      const max = Math.floor(this.images.length);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    },
    twoHundredFiftyMils() {
      this.currentTime++;
      //If at the end
      if (
        this.currentTime === this.totalTime &&
        true === this.thisGameOpened &&
        window.location.href.includes("classroom/teacher")
      ) {
        alert("Timer Up.");
        this.openedGame = false;
        this.timerStarted = false;
        this.close();
      }
      let newPercentage = 100 * (this.currentTime / this.totalTime);
      $("#progressTimer").css("width", newPercentage + "%");
    },
    startTimer() {
      const that = this;

      // First
      clearInterval(this.timer);
      $("#progressTimer").css("width", 0 + "%");
      $("#progressTimer").css("width", "0%");

      that.timerStarted = true;
      that.openedGame = true;
      that.showToolBarButtons = true;

      that.nextCard();

      that.timer = window.setInterval(that.twoHundredFiftyMils, 250);
    },
    nextCard() {
      const that = this;

      const video = document.getElementById("soundVideo");
      console.log(video);

      if (video.src) {
        console.log("has src and is about to remove it");
        video.pause();
        video.removeAttribute("src"); // empty source
        video.load();
      }

      //Reset
      that.currentImageOneSrc = "";
      that.currentImageOneName = "";
      that.currentImageTwoSrc = "";
      that.currentImageTwoName = "";

      videoData = videoData.sort(() => Math.random() - 0.5); //Shuffles the array
      that.randomVideoObject =
        videoData[Math.floor(Math.random() * videoData.length)]; //Grabs random item
      console.log(that.randomVideoObject);

      // // Randomly select a video from the videoData array of videos and set it to the randomVideoObject, but make we should more often than not get a video object that has a name value that matches the that.currentImageName value
      // if (Math.random() > 0.7) {
      //   that.randomVideoObject =
      //     videoData[Math.floor(Math.random() * videoData.length)]; //Grabs random item
      //   console.log("found random video");
      // } else {
      //   that.randomVideoObject = videoData.find(
      //     (x) => x.name === that.currentImageName
      //   );
      //   console.log("found matching video", that.randomVideoObject.name);
      // }

      //We should only play video that is for this lesson
      while (
        -1 ===
        that.images.findIndex((x) => x.name === that.randomVideoObject.name)
      ) {
        that.randomVideoObject =
          videoData[Math.floor(Math.random() * videoData.length)]; //Grabs random item
        console.log("randomVidObj inside while", that.randomVideoObject);
      }

      // //Find index and remove from array
      // const x = videoData.findIndex((z) => z.name === that.randomVideoObject.name)
      // videoData.splice(x, 1)

      // Sets video source
      that.videoSrc = that.randomVideoObject.src;

      video.load();
      setTimeout(() => {
        video.play();
      }, 0);

      that.showTimer = true;
      that.shuffleArray();

      //Flip coin
      const randomNumber = Math.random();

      if (randomNumber > 0.5) {
        const index = that.images.findIndex(
          (x) => x.name === that.randomVideoObject.name
        );
        console.log("coin flipped heads");

        if (Math.random() > 0.5) {
          //Image one
          that.currentImageOneSrc = that.images[index].src;
          that.currentImageOneName = that.images[index].name;

          that.shuffleArray();

          //Image two
          const newIndexTwo = that.getRandomIntInclusive();
          that.currentImageTwoSrc = that.images[index].src;
          that.currentImageTwoName = that.images[index].name;

          console.log("first image should match, newIndexTwo", newIndexTwo);
        } else {
          //Image two
          that.currentImageTwoSrc = that.images[index].src;
          that.currentImageTwoName = that.images[index].name;

          that.shuffleArray();
          //Image one
          const newIndexOne = that.getRandomIntInclusive();
          that.currentImageOneSrc = that.images[newIndexOne].src;
          that.currentImageOneName = that.images[newIndexOne].name;

          console.log("second image should match, newIndexTwo", newIndexOne);
        }
      } else {
        console.log("coin flipped tails");
        //Image one
        const newIndexOne = that.getRandomIntInclusive();
        that.currentImageOneSrc = that.images[newIndexOne].src;
        that.currentImageOneName = that.images[newIndexOne].name;

        //Image two
        const newIndexTwo = that.getRandomIntInclusive();
        that.currentImageTwoSrc = that.images[newIndexTwo].src;
        that.currentImageTwoName = that.images[newIndexTwo].name;
      }
    },
    close() {
      clearInterval(this.timer);
      $("#progressTimer").css("width", 0 + "%");
      $("#progressTimer").css("width", "0%");
      this.thisGameOpened = false;
      this.showToolBarButtons = false;
      this.$store.dispatch("setClassroomInfoBeatTheClockFinished", true);
      this.$emit("displayGame", false);
    },
    resetScore() {
      this.correct = 0;
      this.totalTries = 0;
    },
  },
  watch: {
    totalSeconds: function (val, preVal) {
      //Reset timer
      clearInterval(this.timer);
      $("#progressTimer").css("width", 0 + "%");
      $("#progressTimer").css("width", "0%");
      this.currentTime = 0;

      this.totalTime = this.totalSeconds * 4;

      this.readableTotalTime = this.totalTime / 4;

      this.timer = window.setInterval(this.twoHundredFiftyMils, 250);
    },
  },
};
</script>

<style scoped>
.imageHolder {
  display: flex;
  flex-wrap: wrap;
}

.imageHolder img {
  flex-grow: 1;
  height: 358px;
  margin-right: 10px;
  cursor: pointer;
}

.imageHolder #videoContainer {
  flex-grow: 1;
  margin-right: 10px;
}

#soundVideo {
  margin-top: -30px;
}

.voting-buttons {
  font-size: 35px;
  margin: auto;
  left: 0px;
  right: 0px;
  margin-bottom: 15px;
}

.voting-buttons .dislike {
  color: #fd397a;
}

.centerButtons {
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
}

.progress {
  margin-bottom: 30px;
  width: 100%;
}

.outerButton {
  width: 100%;
}

.highlightMeBtn {
  background-color: #dfdfdf;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
