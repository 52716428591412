<template>
  <div id="students" v-if="loaded">
    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Your Students</h3>
        </div>
        <div class="kt-portlet__head-toolbar" v-show="true">
          <div class="kt-portlet__head-wrapper">
            &nbsp;
            <a
              href="javascript:;"
              id="newStudentButton"
              :class="[
                0 == students.length ? 'new-student-flash' : '',
                'btn btn-brand btn-bold btn-upper btn-font-sm',
              ]"
              data-toggle="modal"
              v-if="showNewStudentBtn"
              data-target="#newStudentModal"
            >
              <i class="la la-plus"></i> New Student
            </a>
          </div>
        </div>
      </div>
      <div class="kt-portlet__body kt-portlet__body--fit">
        <!--begin: Datatable -->
        <div
          class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded"
        >
          <table
            class="kt-datatable__table"
            id="html_table"
            width="100%"
            style="display: block; min-height: 300px"
          >
            <thead class="kt-datatable__head">
              <tr class="kt-datatable__row" style="left: 0px">
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Name</span>
                </th>
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Next Procedure</span>
                </th>
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Days Left</span>
                </th>
                <!-- <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Visualize</span>
                </th> -->
                <!-- <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Visualize</span>
                </th> -->
                <!-- <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Procedure Selector</span>
                </th> -->
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Start Lesson</span>
                </th>
              </tr>
            </thead>
            <tbody class="kt-datatable__body ps">
              <div v-if="0 == students.length">
                <tr data-row="0" class="kt-datatable__row" style="left: 0px">
                  <td class="kt-datatable__cell" style="width: 100%">
                    You currently do not have any students.
                  </td>
                </tr>
              </div>

              <tr
                v-for="(student, key) in students"
                :key="key"
                data-row="0"
                class="kt-datatable__row"
                style="left: 0px"
              >
                <td class="kt-datatable__cell" style="width: 17%">
                  <a
                    @click="studentProfile(student)"
                    href="javascript:;"
                    id="studentProfileButton"
                    class="btn btn-outline-brand"
                  >
                    <i class="la la-user"></i>
                    {{ student.studentFullName }}
                  </a>
                </td>
                <td class="kt-datatable__cell" style="width: 17%">
                  {{ student.studentReadableProgress }}
                </td>
                <td class="kt-datatable__cell" style="width: 17%">
                  <div v-if="true == student.needsRenewal" class="color-yellow">
                    <a
                      href="https://www.foundationinsounds.com/shop"
                      target="_blank"
                      class="btn btn-outline-warning"
                    >
                      Waiting for payment...
                    </a>
                  </div>
                  <span v-else-if="'Expired' !== student.expDate">{{
                    student.expDate
                  }}</span>
                  <div v-else>
                    <a
                      href="javascript:;"
                      class="btn btn-outline-warning"
                      @click="renewStudent(student.id, student.studentFullName)"
                    >
                      Renew Student
                    </a>
                  </div>
                </td>
                <!-- <td class="kt-datatable__cell" style="width: 17%">
                  <a
                    href="#studentGlance"
                    @click="graphStudent(student.id, student.studentFullName)"
                    class="btn btn-outline-brand"
                  >
                    <i class="flaticon-graph"></i>Graph Student
                  </a>
                </td> -->
                <!-- <td class="kt-datatable__cell" style="width: 17%">
                  <div v-if="student.disabled" class="color-yellow">
                    <a
                      href="https://www.foundationinsounds.com/shop"
                      target="_blank"
                      >Waiting for payment...<br />Check back soon</a
                    >
                  </div>
                  <ProcedureSelector v-else :student="student" />
                </td> -->
                <td
                  class="kt-datatable__cell"
                  style="width: 17%"
                  v-if="!student.disabled"
                >
                  <a
                    @click="setStudentInfo(student)"
                    href="javascript:;"
                    class="btn btn-outline-brand"
                    id="startLessonButton"
                    v-bind:class="{
                      disabled:
                        'Expired' === student.expDate || student.disabled,
                    }"
                  >
                    <i class="la la-play-circle"></i> Start Lesson
                  </a>
                </td>
                <td class="kt-datatable__cell" style="width: 17%" v-else>
                  <a
                    href="javascript:;"
                    @click="pay(student.paymentLink)"
                    class="pay-to-start btn btn-outline-success"
                    id="startLessonButton"
                  >
                    <i class="la la-play-circle"></i> Pay to Start Lesson
                  </a>
                </td>
              </tr>

              <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                  class="ps__thumb-x"
                  tabindex="0"
                  style="left: 0px; width: 0px"
                ></div>
              </div>
              <div class="ps__rail-y" style="top: 0px; right: 0px">
                <div
                  class="ps__thumb-y"
                  tabindex="0"
                  style="top: 0px; height: 0px"
                ></div>
              </div>
            </tbody>
          </table>
        </div>
        <!--end: Datatable -->
      </div>
    </div>
    <renewStudent
      v-bind:renewStudentID="renewStudentID"
      v-bind:renewStudentFullName="renewStudentFullName"
    ></renewStudent>
  </div>
</template>

<script>
import ProcedureSelector from "./procedureSelector.vue";
import renewStudent from "./renewStudent";
import { io } from "socket.io-client";

const socket = io();

export default {
  name: "students",
  data() {
    return {
      students: [],
      renewStudentID: "",
      renewStudentFullName: "",
      loaded: false,
      showNewStudentBtn: false,
      accountType: "",
    };
  },
  components: { ProcedureSelector, renewStudent },
  async created() {
    const that = this;

    await that.checkStudentData();

    const tokenResult = await firebase
      .auth()
      .currentUser.getIdTokenResult(true);
    const isAdmin = !!tokenResult.claims.admin;
    const isTutor = !!tokenResult.claims.tutor;
    const accountType = tokenResult.claims.accountType;

    that.accountType = accountType;

    if (that.accountType == "homeschooler") {
      that.showNewStudentBtn = true;
    }
  },
  async mounted() {
    const that = this;

    while (true) {
      // Wait for 200ms
      await new Promise((resolve) => setTimeout(resolve, 200));

      const tokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);
      const isAdmin = !!tokenResult.claims.admin;
      const isTutor = !!tokenResult.claims.tutor;
      const accountType = tokenResult.claims.accountType;

      that.accountType = accountType;

      if (that.accountType == "homeschooler") {
        that.showNewStudentBtn = true;
      }

      if (accountType) {
        break;
      }
    }

    // Listen for changes to the payment info
    socket.on("payment_update", async (message) => {
      console.log("----------payment_update", message);

      setTimeout(async () => {
        await that.checkStudentData();
      }, 2000);
    });
  },
  methods: {
    async checkStudentData() {
      const that = this;

      // Empty the students array
      that.students = [];

      const querySnapshot = await db
        .collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .get()
        .catch(function (error) {
          console.error("Error getting document:", error);
        });

      querySnapshot.docs.forEach(async (doc) => {
        let expDate = moment.unix(doc.data().expDate);
        let today = moment();
        let daysLeft =
          0 > expDate.diff(today, "days")
            ? "Expired"
            : Math.abs(expDate.diff(today, "days")); //If day has already passed, just display 'expired' not anything else

        // If student is deleted, don't show them in the list
        if (doc.data().deleted) {
          return;
        }

        // If any of the students are paid then we alert the App.vue via an emitted event
        if (doc.data().paid) {
          that.$emit("student-paid", true);
        }

        let studentObj = {
          studentFullName: doc.data().studentFullName,
          expDate: daysLeft,
          studentReadableProgress: that.capitalizeFirstLetter(
            doc.data().studentReadableProgress
          ),
          id: doc.data().myUid,
          currentLesson: doc.data().currentLesson,
          totalPoints: doc.data().totalPoints,
          totalTries: doc.data().totalTries,
          disabled: doc.data().disabled,
          needsRenewal: doc.data().needsRenewal,
          paymentLink: doc.data().paymentLink,
        };

        that.students.push(studentObj);
      });

      that.loaded = true;
    },
    graphStudent(studentId, studentName) {
      this.$store.dispatch("setStudentGlanceStudentIds", [studentId]);
      this.$store.dispatch("setStudentGlanceTitle", studentName);
      this.$store.dispatch("setStudentGlanceViewingAllStudents", false);
      this.$store.dispatch(
        "setStudentGlanceDiffStudentClick",
        !this.$store.getters.getStudentGlanceDiffStudentClick
      );
      $("#studentGlance").effect(
        "shake",
        { times: 2, distance: 7, direction: "left" },
        500
      );
    },
    studentProfile(student) {
      localStorage.setItem("classroomInfoStudentID", student.id);

      this.$router.push({ path: "/studentProfile" });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setStudentInfo(student) {
      let selectedProcedure = $("#student-id-" + student.id)
        .find(":selected")
        .text();

      selectedProcedure = selectedProcedure.trim();

      const selectedLesson = $("#student-id-" + student.id)
        .find(":selected")
        .closest("optgroup")
        .data("value");

      let currentLesson;
      let studentReadableProgress;

      const expDate = student.expDate;
      const studentFullName = student.studentFullName;
      const totalPoints = student.totalPoints;
      const totalTries = student.totalTries;
      const id = student.id;

      if ("Introduction" == selectedProcedure) {
        //If introduction
        currentLesson = "introduction";
        studentReadableProgress = "introduction";
      } else if ("Sandbox" == selectedProcedure) {
        console.log("sandbox selected");
        //If sandbox
        currentLesson = "sandbox";
        studentReadableProgress = "sandbox";
      } else if (!selectedLesson) {
        //If no selection was made
        currentLesson = student.currentLesson;
        studentReadableProgress = student.studentReadableProgress;
      } else {
        //If there was a selection
        currentLesson = selectedLesson.replace(/\s/g, "").toLowerCase();
        studentReadableProgress = selectedLesson + ", " + selectedProcedure;
      }

      localStorage.setItem("classroomInfoCurrentLesson", currentLesson);
      console.log("--setting-- currentLesson", currentLesson);

      this.$store.dispatch("setClassroomInfoStudentName", studentFullName);
      this.$store.dispatch("setClassroomInfoExpDate", expDate);

      localStorage.setItem("classroomInfoProgress", studentReadableProgress);
      window.dispatchEvent(
        new CustomEvent("classroomInfoProgress-changed", {
          detail: {
            storage: localStorage.getItem("classroomInfoProgress"),
          },
        })
      );

      this.$store.dispatch("setClassroomInfoPoints", totalPoints);
      this.$store.dispatch("setClassroomInfoTotalTries", totalTries);

      localStorage.setItem("classroomInfoStudentID", id);

      //Set which tiles aren't in the current lesson
      switch (currentLesson) {
        case "sandbox":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            "introduction"
          );
          break;
        case "introduction":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            "introduction"
          );
          break;
        case "lesson1":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson1
          );
          break;
        case "lesson2":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson2
          );
          break;
        case "lesson3":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson3
          );
          break;
        case "lesson4":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson4
          );
          break;
        case "lesson5":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson5
          );
          break;
        case "lesson6":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson6
          );
          break;
        case "lesson7":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson7
          );
          break;
        case "lesson8":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson8
          );
          break;
        case "lesson9":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson9
          );
          break;
        case "lesson10":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson10
          );
          break;
        case "lesson11":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson11
          );
          break;
        case "lesson12":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson12
          );
          break;
        case "lesson13":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson13
          );
          break;
      }

      this.$router.push({ path: "/classroom/teacher" });
    },
    async renewStudent(studentID, studentFullName) {
      const that = this;

      if (confirm("Are you sure you want to renew " + studentFullName + "?")) {
        // Open https://www.foundationinsounds.com/shop in a new tab
        window.open("https://www.foundationinsounds.com/shop", "_blank");

        const querySnapshot = await db
          .collection("users")
          .doc(localStorage.getItem("userID"))
          .collection("students")
          .doc(studentID)
          .update({
            needsRenewal: true,
          })
          .catch(function (error) {
            console.error("Error getting document:", error);
          });

        // refresh the page
        location.reload();
      }
    },
    async pay(paymentLink) {
      const that = this;

      // Send request to server with socket ID
      // Then open the payment link in a new tab
      const idToken = await firebase.auth().currentUser.getIdToken(true);

      const res = await that.$http.post("/api/socket-id", {
        token: idToken,
        socketId: socket.id,
      });

      if (!res.data.success) {
        console.error("Error getting document:", res.data.error);
      }

      // Open payment link in same tab
      window.open(paymentLink, "_self");
    },
  },
};
</script>

<style scoped>
.doneGreen {
  background: #c5fff9;
}

.doneYellow {
  background: #fbffc5;
}

.btn-outline-warning:hover {
  color: white;
}

.color-yellow a {
  color: #f0ad4e;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.new-student-flash {
  animation: pulsate 1s infinite;
}

.pay-to-start {
  animation: pulsate 1s infinite;
}
</style>
