<template>
  <div id="soundStopLightModal" style="display: none">
    <div
      class="modal position-relative d-block"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-l" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Sounds Stop Light</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>

          <img
            class="col s4"
            style="max-height: 100vh"
            src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSounds%20Stop%20Light.jpg?alt=media&token=e7db96a3-3d3b-40a4-8cb3-53f32782a99f"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import procedureOne from "./procedureOne";

export default {
  name: "soundStopLightModal",
  components: { procedureOne },
  data() {
    return {
      images: [],
    };
  },
  mounted() {
    const that = this;
  },
};
</script>

<style scoped></style>
