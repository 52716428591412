<template>
  <div>Loading...</div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {};
  },
  async mounted() {
    const that = this;

    // Get email form url params
    const urlParams = new URLSearchParams(window.location.search);
    let email = urlParams.get("email");

    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }

      // The client SDK will parse the code from the link for you.
      const result = await firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.error(error);

          // Show error to user
          that.$store.dispatch("setInfoDanger", error.message);
        });

      // Clear email from storage.
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser

      // Get the user
      const user = result.user;

      // Sign in user
      that.signinUser(user, urlParams);
    } else if (urlParams.get("viewAs")) {
      // Get the tutorUid
      const customToken = urlParams.get("customToken");

      // Sign in with custom token
      const userCredential = await firebase.auth().signInWithCustomToken(customToken);

      const user = userCredential.user;

      // Sign in user
      that.signinUser(user, urlParams);
    } else {
      // Show error to user
      that.$store.dispatch("setInfoDanger", "Invalid link");
      console.error("Invalid link:", urlParams);
    }
  },
  methods: {
    async signinUser(user, urlParams) {
      const that = this;

      // Get user's role in custom claims
      const tokenResult = await user.getIdTokenResult();
      const isAdmin = !!tokenResult.claims.admin;
      const isTutor = !!tokenResult.claims.tutor;

      const accountType =
        urlParams.get("accountType") || tokenResult.claims.accountType;

      // Check if user is a tutor, everyone should be, if not then send to backend to create user
      if (!isTutor) {
        // Post to backend to create user
        const response = await axios.post("/api/newAdminUser", {
          token: tokenResult,
          accountType: accountType,
        });

        if (response.data.error) {
          that.$store.dispatch("setInfoDanger", response.data.error);
          return;
        }

        // force refresh token
        await firebase.auth().currentUser.getIdToken(true);

        // For refresh token again
        // Get user's role in custom claims
        await firebase.auth().currentUser.getIdTokenResult(true);

        that.$store.dispatch("setAuthStatus", true);
        that.$store.dispatch("setAdminStatus", true);
        localStorage.setItem("userID", user.uid);
      } else {
        that.$store.dispatch("setAuthStatus", true);
        that.$store.dispatch("setAdminStatus", isAdmin);
        localStorage.setItem("userID", user.uid);
      }

      // Wait 1000ms before redirecting
      setTimeout(() => {
        if (accountType == "learningCenter" || accountType == "tutorAdmin") {
          that.$router.push("/admin");
        } else {
          that.$router.push("/dashboard");
        }
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
