<template>
  <div class="container">
    <div class="bg-white rounded p-10">
      <!--begin::Card-->
      <br />
      <div class="card card-custom card-fit card-border">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Welcome...</h3>
          </div>
        </div>
        <br />
        <div class="card-body pt-2">
          Sounds! They are all around us, and they can be very different
          depending on where we find ourselves. Whether we are walking on a
          beach, up the slopes of a mountain, in a city park, or on a busy
          street, sounds paint a portrait of the world.
        </div>
        <div class="card-body pt-2">
          It is often the simple things in life that are taken for granted. What
          if the world were silent? Many know how that feels. A walk on the
          beach without the crash of waves, a day in the park without the
          laughter of children, an orca whale without its song.
        </div>
        <div class="card-body pt-2">
          Words are the foundation of knowledge, communication, and education,
          but it is easy for us to forget that the foundation of words is
          sounds. Early readers are as different as you and I, with varying
          likes and dislikes, personalities, and aptitudes. Regardless of the
          differences, all students have this one thing in common: A solid
          foundation in sounds is necessary for reading success.
        </div>
        <div class="card-body pt-2">
          We know that behind every successful student is a parent, a coach, a
          teacher, or a tutor who has sacrificed. Without people like you,
          students could not succeed. So as you begin, thank you for your
          involvement and dedication in assisting your student: we are delighted
          to have this opportunity to work with you.
        </div>

        <div id="buttonNext">
          <a class="btn btn-primary" href="/dashboard">Proceed to Dashboard</a>
        </div>
      </div>
      <!--end::Card-->
    </div>
  </div>
</template>

<script>
export default {
  name: "welcomeLetter",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
#buttonNext {
  margin: 10px;
  align-content: center;
  align-self: center;
}
</style>
