<template>
    <div v-if="loaded" id="procedureSelector">
      <div>
        <select
        class="form-control"
        :id="'student-id-' + student.id"
        >
        <option v-if="!isClassroom" selected>Current Procedure</option>
        <option v-if="isClassroom" selected>Select Procedure</option>
        <option class="doneGreen" disabled>
            Green = Completed
        </option>
        <option
            class="sandbox"
            id="sandbox"
            value="sandbox"
        >
            Sandbox
        </option>
        <option
            class="doneYellow"
            id="introduction"
            value="introduction"
        >
            Introduction
        </option>
        <optgroup
            v-for="(lesson, index) in lessons"
            :key="index"
            :data-value="'Lesson ' + (parseInt(index) + parseInt(1))"
            :label="
            'Lesson ' +
            (parseInt(index) + parseInt(1)) +
            ' ' +
            lessonsSound[index]
            "
        >
            <option
            v-for="procedure in lesson"
            :key="procedure"
            v-bind:class="{
                doneGreen: isFinished(index, procedure, student.id),
            }"
            >
            Procedure {{ procedure }}
            </option>
        </optgroup>
        </select>
      </div>
      <div id="procedureSelectorStartButton" v-show="isClassroom">
        <a href="javascript:;" class="btn btn-primary btn-icon" title="Start" @click="setStudent()"><i class="fas fa-play"></i></a>
      </div>
    </div>
  </template>
  
  <script>


let studentLessonDataObj = {};

  export default {
    data() {
      return {
        students: [],
        loaded: false,
        lessons: [
        [4, 5, 6, 7, 8], //lesson 1
        [1, 2, 3, 4, 5, 6, 7, 8], //lesson 2
        [1, 2, 3, 4, 5, 6, 7, 8], //lesson 3
        [1, 2, 3, 4, 5, 6, 7, 8], //lesson 4
        [1, 2, 3, 4, 5, 6, 7, 8], //lesson 5
        [1, 2, 3, 4, 5, 6, 7, 8], //lesson 6
        [1, 2, 3, 4, 5, 6, 7, 8], //lesson 7
        [1, 2, 3, 4, 5, 6, 7, 8], //lesson 8
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 9
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 10
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 11
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //lesson 12
        [1, 2, 3], //lesson 13
      ],
      lessonsSound: [
        "/m/, /n/", //lesson 1
        "/f/, /v/", //lesson 2
        "/th", //lesson 3
        "/k/, /g/", //lesson 4
        "/s/, /sh/", //lesson 5
        "/i/, /e/", //lesson 6
        "/u/", //lesson 7
        "/a/, /o/", //lesson 8
        "/l/, /r/", //lesson 9
        "/d/, /t/", //lesson 10
        "/ch/, /j/", //lesson 11
        "/b/ /p/", //lesson 12
        "review", //lesson 13
      ],
      };
    },
    async created() {
        const that = this;
        that.checkStudentData();
    },

    props: {
      student: Object,
      isClassroom: Boolean,
    },
    methods: {
    async checkStudentData() {
        const that = this;

        const querySnapshot = await db
            .collection("users")
            .doc(localStorage.getItem('userID'))
            .collection("students")
            .get()
            .catch(function (error) {
            console.error("Error getting document:", error);
            });

        querySnapshot.docs.forEach((doc) => {
            let expDate = moment.unix(doc.data().expDate);
            let today = moment();
            let daysLeft =
            0 > expDate.diff(today, "days")
                ? "Expired"
                : Math.abs(expDate.diff(today, "days")); //If day has already passed, just display 'expired' not anything else

            // If student is deleted, don't show them in the list
            if (doc.data().deleted) {
            return;
            }

            let studentObj = {
              studentFullName: doc.data().studentFullName,
              expDate: daysLeft,
              studentReadableProgress: that.capitalizeFirstLetter(
                  doc.data().studentReadableProgress
              ),
              id: doc.data().myUid,
              currentLesson: doc.data().currentLesson,
              totalPoints: doc.data().totalPoints,
              totalTries: doc.data().totalTries,
              disabled: doc.data().disabled,
              needsRenewal: doc.data().needsRenewal,
            };

            that.students.push(studentObj);
        });

        for (let i = 0; i < that.students.length; i++) {
            const suid = that.students[i].id;

            const refLessons = db.collection("users").doc(suid);

            const querySnapshot = await refLessons.get().catch(function (error) {
            console.error("Error getting document:", error);
            });
            studentLessonDataObj[suid] = querySnapshot.data().lessons;
        }
        that.loaded = true;
    },
    isFinished(lesson, procedure, suid) {
      lesson = lesson + 1;
      const key = "lesson" + lesson + "Procedure" + procedure;

      // console.log('here', lesson, procedure)
      // Write the student prop to console
      // console.log('student', this.student)

      return studentLessonDataObj[suid][key];
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setStudent() {
      // In classroom only

      const that = this;
      let selectedProcedure = $("#student-id-" + that.student.id)
        .find(":selected")
        .text();

      selectedProcedure = selectedProcedure.trim();

      const selectedLesson = $("#student-id-" + that.student.id)
        .find(":selected")
        .closest("optgroup")
        .data("value");

      let currentLesson;
      let studentReadableProgress;

      const expDate = that.student.expDate;
      const studentFullName = that.student.studentFullName;
      const totalPoints = that.student.totalPoints;
      const totalTries = that.student.totalTries;
      const id = that.student.id;

      if ("Introduction" == selectedProcedure) {
        //If introduction
        currentLesson = "introduction";
        studentReadableProgress = "introduction";
      } else if ("Sandbox" == selectedProcedure) {
        console.log('sandbox selected')
        //If sandbox
        currentLesson = "sandbox";
        studentReadableProgress = "sandbox";
      } else if (!selectedLesson) {
        //If no selection was made
        that.$root.$emit("nextProd");

        // Don't run the rest of the function if next prod is called
        return
      } else {
        //If there was a selection
        currentLesson = selectedLesson.replace(/\s/g, "").toLowerCase();
        studentReadableProgress = selectedLesson + ", " + selectedProcedure;
      }

      localStorage.setItem('classroomInfoCurrentLesson', currentLesson)
      console.log('--setting-- currentLesson', currentLesson)
      
      that.$store.dispatch("setClassroomInfoStudentName", studentFullName);
      that.$store.dispatch("setClassroomInfoExpDate", expDate);

      localStorage.setItem('classroomInfoProgress', studentReadableProgress)
      window.dispatchEvent(
        new CustomEvent('classroomInfoProgress-changed', {
          detail: {
            storage: localStorage.getItem('classroomInfoProgress'),
          },
        })
      )

      that.$store.dispatch("setClassroomInfoPoints", totalPoints);
      that.$store.dispatch("setClassroomInfoTotalTries", totalTries);

      localStorage.setItem('classroomInfoStudentID', id)

      //Set which tiles aren't in the current lesson
      switch (currentLesson) {
        case "sandbox":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            "introduction"
          );
          break;
        case "introduction":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            "introduction"
          );
          break;
        case "lesson1":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson1
          );
          break;
        case "lesson2":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson2
          );
          break;
        case "lesson3":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson3
          );
          break;
        case "lesson4":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson4
          );
          break;
        case "lesson5":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson5
          );
          break;
        case "lesson6":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson6
          );
          break;
        case "lesson7":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson7
          );
          break;
        case "lesson8":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson8
          );
          break;
        case "lesson9":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson9
          );
          break;
        case "lesson10":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson10
          );
          break;
        case "lesson11":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson11
          );
          break;
        case "lesson12":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson12
          );
          break;
        case "lesson13":
          this.$store.dispatch(
            "setClassroomInfoWhiteListedTiles",
            this.$store.getters.getClassroomInfoLesson13
          );
          break;
      }

      that.$root.$emit("loadEverything");
      //Reload the page
      location.reload();
    },
    },
  };
  </script>
  
<style scoped>

.doneGreen {
  background: #c5fff9;
}

.doneYellow {
  background: #fbffc5;
}

.color-yellow a {
  color: #f0ad4e;
}

#procedureSelector {
  display: inline-flex;
}

#procedureSelectorStartButton {
  margin-left: 10px;
}

</style>