<template>
  <div style="margin-left: 20%; background-color: white; margin-right: 20%; padding: 20px; border-radius: 5px;">
    <h1 style="color:black;">Thank you for your order!</h1>
    <p>
      To continue click here
      <a class="btn btn-label-brand btn-upper btn-sm btn-bold" v-if="isTutor" href="/admin">continue</a>
      <a class="btn btn-label-brand btn-upper btn-sm btn-bold" v-else href="/dashboard">continue</a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isTutor: false,
    };
  },
  async mounted() {
    const that = this;

    const tokenResult = await firebase
      .auth()
      .currentUser.getIdTokenResult(true);
    const isAdmin = !!tokenResult.claims.admin;
    const isTutor = !!tokenResult.claims.tutor;
    const accountType = tokenResult.claims.accountType;

    if (accountType == 'tutorAdmin') {
      that.isTutor = true;
    } else {
      that.isTutor = false;
    }
  },
};
</script>
