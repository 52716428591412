<template>
  <div id="dashboard">
    <!-- <div id="myShepherdOverlay"></div> -->
    <div id="pageSpinner" v-show="isLoading">
      <div
        class="spinner-grow"
        id="spinner"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      v-show="!isLoading"
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor relative-container"
    >
      <!-- start small row -->
      <div class="kt-subheader kt-grid__item" id="kt_subheader">
        <div class="kt-subheader__main">
          <h3 class="kt-subheader__title">Dashboard</h3>
        </div>
        <div class="kt-subheader__toolbar">
          <div class="kt-subheader__wrapper">
            <div class="btn calenderBtn">
              <span class="kt-opacity-7" id="kt_dashboard_daterangepicker_title"
                >Today:</span
              >&nbsp;
              <span
                class="kt-font-bold"
                id="kt_dashboard_daterangepicker_date"
                >{{ today }}</span
              >
              <i
                class="flaticon-calendar-with-a-clock-time-tools kt-padding-l-5 kt-padding-r-0"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <!-- end small row -->

      <div
        class="kt-content kt-grid__item kt-grid__item--fluid"
        id="kt_content"
      >
        <div class="row" id="dashboardOverView">
          <div class="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
            <!--begin::Portlet-->

            <div class="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
              <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget-3 kt-widget-3--brand">
                  <div class="kt-widget-3__content">
                    <div class="kt-widget-3__content-info">
                      <div class="kt-widget-3__content-section">
                        <!-- <div class="kt-widget-3__content-title">Days left</div> -->
                        <div
                          class="kt-widget-3__content-desc"
                          style="font-size: 16px"
                        >
                          <span
                            class="color: rgba(255, 255, 255, 0.7) !important"
                            v-if="startDate != 'Unknown'"
                          >
                            Start date: {{ startDate }}</span
                          >
                          <span
                            class="color: rgba(255, 255, 255, 0.7) !important"
                            v-else
                          >
                            Today's date</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="kt-widget-3__content-section" v-if="accountType != 'tutorAdmin'">
                      <span
                        class="kt-widget-3__content-number"
                        id="daysAsCoach"
                        v-if="startDate != 'Unknown'"
                      >
                        {{ endDate }}
                        <span>Days left</span>
                      </span>
                      <span
                        class="kt-widget-3__content-number"
                        id="daysAsCoach"
                        v-else
                      >
                        {{ today }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--end::Portlet-->
          </div>

          <div class="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
            <!--begin::Portlet-->
            <div class="kt-portlet kt-portlet--height-fluid">
              <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">Total Students</h3>
                </div>
              </div>
              <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget-21">
                  <div class="kt-widget-21__title">
                    <div class="kt-widget-21__label">{{ totalStudents }}</div>
                    <img
                      src="./assets/media/misc/iconbox_bg.png"
                      class="kt-widget-21__bg"
                      alt="bg"
                    />
                  </div>
                  <div class="kt-widget-21__data">
                    <!--Doc: For the chart legend bullet colors can be changed with state helper classes: kt-bg-success, kt-bg-info, kt-bg-danger. Refer: components/custom/colors.html -->
                    <div class="kt-widget-21__legends">
                      <div class="kt-widget-21__legend">
                        <i class="kt-bg-brand"></i>
                        <span style="font-size: 14px; color: #5d78ff"
                          >In-Training</span
                        >
                      </div>
                      <div class="kt-widget-21__legend">
                        <i class="kt-shape-bg-color-4"></i>
                        <span style="font-size: 14px; color: #001065"
                          >Graduated</span
                        >
                      </div>
                    </div>
                    <div class="kt-widget-21__chart">
                      <div class="chartjs-size-monitor">
                        <div class="chartjs-size-monitor-expand">
                          <div class></div>
                        </div>
                        <div class="chartjs-size-monitor-shrink">
                          <div class></div>
                        </div>
                      </div>
                      <!--Doc: For the chart initialization refer to "widgetTechnologiesChart" function in "src\theme\app\scripts\custom\dashboard.js" -->
                      <canvas
                        id="kt_widget_technologies_chart"
                        style="height: 100px; width: 100px; display: block"
                        width="125"
                        height="125"
                        class="chartjs-render-monitor"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Portlet-->
          </div>

          <!-- <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1"> -->
          <!--begin::Portlet-->

          <!-- <div class="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
              <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget-3 kt-widget-3--success">
                  <div class="kt-widget-3__content">
                    <div class="kt-widget-3__content-info">
                      <div class="kt-widget-3__content-section">
                        <div class="kt-widget-3__content-title">Average Student Proficiency</div>
                        <div class="kt-widget-3__content-desc" style="font-size: 16px; color: rgba(255, 255, 255, 0.7) !important">Since {{ startDate }}</div>
                      </div>
                    </div>
                    <div class="kt-widget-3__content-section">
                      <span class="kt-widget-3__content-number" style="font-size: 14px" id="daysAsCoach">
                        {{ averageStudentsProficiency }}
                        <span>%</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

          <!--end::Portlet-->
          <!-- </div> -->
        </div>

        <div v-if="!isLoading">
          <!-- <div class="row" id="middleCards">
            <div class="col-lg-12 col-xl-6 order-lg-2 order-xl-1">
              <studentsProgress></studentsProgress>
            </div>

            <div class="col-lg-12 col-xl-6 order-lg-2 order-xl-1">
              <studentGlance></studentGlance>
            </div>
          </div> -->

          <div class="row">
            <div
              class="col-lg-12 col-xl-12 order-lg-2 order-xl-1"
              id="addStudentInfo"
            >
              <!--begin::Portlet-->

              <students id="students"></students>

              <!--end::Portlet-->
            </div>
          </div>
        </div>
      </div>

      <Overlay
        :show="showOverlay"
        :payment-link="paymentLinkTutorAdmin"
      ></Overlay>
    </div>

    <!-- finalModal -->
    <div
      class="modal fade"
      role="dialog"
      id="finalModal"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <p v-show="!newStudentPaymentRequired">
              New Student has been successfully created!
            </p>
            <p v-show="newStudentPaymentRequired">
              New Student has been successfully created, but is
              <b>disabled</b> until payment is received.
            </p>
            <p v-show="newStudentPaymentRequired">
              To pay for the new student, please click the button below.
              <a
                href="https://www.foundationinsounds.com/shop"
                target="_blank"
                class="btn btn-primary"
                style="margin-top: 20px"
              >
                Go to payment
              </a>
            </p>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-primary" id="saveAndFinishModal">Save & Mark as Complete</button>
            <button type="button" class="btn btn-primary" id="finishModal">Finish Without Saving</button> -->
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end finalModal -->
  </div>
</template>

<script>
// import Shepherd from 'shepherd.js'

import students from "./Students";
import studentsProgress from "./studentsProgress";
import studentGlance from "./studentGlance";

//import "core-js/stable";
import "regenerator-runtime/runtime";
import Overlay from "./Overlay.vue";

// import db from './firebaseDB'

export default {
  name: "dashboard",
  data() {
    return {
      today: moment().format("ddd, MMMM Do"),
      isLoading: true,
      totalStudents: 0,
      currentStudents: 0,
      graduatedStudents: 0,
      averageStudentsProficiency: 0,
      totalStudentsProficiency: 0,
      startDate: "",
      daysAsCoach: "",
      newStudentPaymentRequired: false,
      endDate: "",
      showOverlay: false,
      paymentLinkTutorAdmin: "",
      accountType: "",
    };
  },
  created() {
    const that = this;
  },
  components: { students, studentsProgress, studentGlance, Overlay },
  async mounted() {
    const that = this;
    let uid = localStorage.getItem("userID") || null;
    let isAdmin = null;
    let accountType = null;
    let querySnapshot = null;

    async function findUID() {
      await new Promise((resolve) => {
        firebase.auth().onAuthStateChanged(async function (user) {
          if (user) {
            // Get user's role in custom claims
            const tokenResult = await user.getIdTokenResult();
            isAdmin = !!tokenResult.claims.admin;
            accountType = tokenResult.claims.accountType;
            that.accountType = accountType;

            if (accountType != "tutorAdmin") {
              that.showOverlay = false;
            }

            console.log("here --------------");

            querySnapshot = await db
              .collection("users")
              .doc(user.uid)
              .get()
              .catch(function (error) {
                console.error("Error getting document:", error);
              });

            // Check if user has paid
            if (!querySnapshot.data().paid && accountType == "tutorAdmin") {
              that.paymentLinkTutorAdmin = querySnapshot.data().paymentLink;
              that.showOverlay = true;
            } else if (!querySnapshot.data().paid && (accountType == "learningCenter" || accountType == "tutorAdmin")) {
              // Redirect to /admin page
              that.$router.push('/admin')
            } else {
              that.showOverlay = false;
            }

            that.$store.dispatch("setAuthStatus", true);
            that.$store.dispatch("setAdminStatus", isAdmin);

            localStorage.setItem("userID", user.uid);
            uid = localStorage.getItem("userID");

            await that.stuffToLoad(accountType, isAdmin, querySnapshot, uid);

            that.isLoading = false;
            resolve();
          }
        });
      });
    }

    if (!uid || !accountType || !isAdmin) {
      await findUID();
    } else {
      that.isLoading = false;
    }
  },
  methods: {
    async stuffToLoad(accountType, isAdmin, querySnapshot, uid) {
      const that = this;

      // Convert Unix timestamp to a Moment object
      const momentDate = moment.unix(parseInt(querySnapshot.data().date));

      // Add 6 months to the Moment object
      const sixMonthsLater = momentDate.add(6, "months");

      // Calculate the number of days left
      const currentDate = moment();
      const daysLeft = sixMonthsLater.diff(currentDate, "days");

      // Get the resulting date as a string (e.g., 'MMMM Do YYYY')
      const formattedDate = sixMonthsLater.format("MMMM Do YYYY");

      that.endDate = daysLeft ? daysLeft : "Unknown";

      that.startDate = moment
        .unix(parseInt(querySnapshot.data().date))
        .isValid()
        ? moment
            .unix(parseInt(querySnapshot.data().date))
            .format("MMMM Do YYYY")
        : "Unknown";
      let startDate = moment.unix(querySnapshot.data().date);
      let today = moment();
      that.daysAsCoach = Math.abs(startDate.diff(today, "days"));

      that.daysAsCoach =
        that.startDate === "Unknown" ? "Unknown" : that.daysAsCoach + 1;
      console.log("that.daysAsCoach", that.daysAsCoach);

      const querySnapshotTwo = await db
        .collection("users")
        .doc(uid)
        .collection("students")
        .get()
        .catch(function (error) {
          console.error("Error getting document:", error);
        });

      querySnapshotTwo.docs.forEach((doc) => {
        that.totalStudents++;
        let expDate = moment.unix(doc.data().expDate);
        let today = moment();
        let daysLeft =
          0 > expDate.diff(today, "days")
            ? "Expired"
            : Math.abs(expDate.diff(today, "days")); //If day has already passed, just display 'expired' not anything else

        if ("Expired" == daysLeft) {
          that.graduatedStudents++;
        } else {
          that.currentStudents++;
        }

        that.totalStudentsProficiency += doc.data().averageProficiency;
      });

      that.averageStudentsProficiency =
        that.totalStudentsProficiency / that.totalStudents;
      that.averageStudentsProficiency = Number.isNaN(
        that.averageStudentsProficiency
      )
        ? 0
        : that.averageStudentsProficiency;

      var config = {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [that.graduatedStudents, that.currentStudents],
              backgroundColor: ["#646c9a", "#5d78ff"],
            },
          ],
          labels: ["Graduated", "In-Training"],
        },
        options: {
          cutoutPercentage: 75,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: "top",
          },
          title: {
            display: false,
            text: "Technology",
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: "#5d78ff",
            titleFontColor: "#ffffff",
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
        },
      };

      var ctx = document
        .getElementById("kt_widget_technologies_chart")
        .getContext("2d");
      var myDoughnut = new Chart(ctx, config);

      if (that.$route.query.addedStudent) {
        // if paymentRequired is true, then notify the user that they need to pay before the student is enabled
        if (that.$route.query.paymentRequired) {
          that.newStudentPaymentRequired = true;
        } else {
          that.newStudentPaymentRequired = false;
        }

        $("#finalModal").modal("show");

        //Remove the URL params
        window.history.pushState({}, document.title, "/" + "dashboard");
      }
    },
  },
};
</script>

<style scoped>
@import "./assets/shepherd.css";

body {
  background-color: #f2f3f8 !important;
}

#pageSpinner {
  height: 100vh;
}

#spinner {
  margin-left: 50%;
  margin-top: 20%;
}

.relative-container {
  position: relative;
  /* Add other styles as needed, e.g., width, height */
}

#dashboard {
  background-color: #b1987a !important; /* b1987a ece4da */
}

#kt_content {
  padding-top: 0px;
}

#daysAsCoach {
  font-size: 3.2rem;
  font-weight: 600;
}

.calenderBtn {
  padding: 5px 10px;
  background-color: #5d78ff;
  color: white;
}

.calenderBtn span {
  color: white;
}
</style>
