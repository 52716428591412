<template>
  <div class="error">
    <div class="kt-grid kt-grid--ver kt-grid--root">
      <div class="kt-error404-v2">
        <div class="kt-error404-v2__content">
          <div class="kt-error404-v2__title text-white">404</div>
          <div class="kt-error404-v2__desc text-white">
            <strong>OOPS!</strong> Something went wrong here.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.error {
  background: url(./assets/media/misc/404-bg2.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

::-webkit-scrollbar {
  display: block;
}

.kt-error404-v2--enabled {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.kt-error404-v2 {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;

  .kt-error404-v2__content {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .kt-error404-v2__title {
      font-size: 12rem;
      color: kt-state-color(brand, inverse);
      font-weight: 700;
    }

    .kt-error404-v2__desc {
      color: kt-state-color(brand, inverse);
      font-size: 1.5rem;
    }
  }
}
</style>
