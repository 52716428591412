<template>
  <div id="whiteboardContainer">
    <div id="controls">
      <label>Color: </label>
      <input type="color" v-model="color" />

      <!-- <label>Line thickness: </label>
      <input type="range" v-model="lineWidth" min="1" max="50" /> -->

      <a href="javascript:;" class="btn btn-primary" @click="setEraser">Set Eraser</a>
      <a href="javascript:;" class="btn btn-primary" @click="clearWhiteBoard">Clear</a>
      <a href="javascript:;" class="btn btn-primary" @click="addTextbox">Add Textbox</a>
      <a href="javascript:;" class="btn btn-primary" @click="setDrawingMode">Drawing Mode</a>
      <div v-for="shape in shapes" :key="shape.id" style="display: inline" v-show="showLetterStrokes">
        <img :src="shape.img" height="80" draggable @dragstart="startDrag(shape)" />
      </div>
    </div>

    <canvas id="c" ref="canvas" />
  </div>
</template>

<script>
import { fabric } from 'fabric'
import draggable from 'vuedraggable'

export default {
  data() {
    return {
      canvas: null,
      color: '#000000',
      lineWidth: 10,
      defaultBackground: '#FFFFFF',
      shapes: [
        {
          id: 1,
          type: 'O',
          img: 'https://firebasestorage.googleapis.com/v0/b/foundation-in-letters.appspot.com/o/Images%2FShapes%2F1.png?alt=media&token=2e9f5db7-fc94-4d4e-a8fb-82077581f548',
        },
        {
          id: 2,
          type: '\\',
          img: 'https://firebasestorage.googleapis.com/v0/b/foundation-in-letters.appspot.com/o/Images%2FShapes%2F2.png?alt=media&token=6d2463f6-93fb-4464-a85f-f657d47e6661',
        },
        {
          id: 3,
          type: '/',
          img: 'https://firebasestorage.googleapis.com/v0/b/foundation-in-letters.appspot.com/o/Images%2FShapes%2F3.png?alt=media&token=3cfefb18-1a2d-4dde-b9f5-30033e9aedeb',
        },
        {
          id: 4,
          type: '-',
          img: 'https://firebasestorage.googleapis.com/v0/b/foundation-in-letters.appspot.com/o/Images%2FShapes%2F4.png?alt=media&token=f7ab982e-9104-4eec-ab3e-12d192525f3b',
        },
        {
          id: 5,
          type: '|',
          img: 'https://firebasestorage.googleapis.com/v0/b/foundation-in-letters.appspot.com/o/Images%2FShapes%2F5.png?alt=media&token=f9550da8-96ad-4b9f-9440-26e773bb7240',
        },
        {
          id: 6,
          type: 'C',
          img: 'https://firebasestorage.googleapis.com/v0/b/foundation-in-letters.appspot.com/o/Images%2FShapes%2F6.png?alt=media&token=a1a599f7-c4d3-4ac9-9bfa-a732d7538970',
        },
      ],
      selectedShape: null,
      draggingShape: null,
    }
  },
  props: ['showLetterStrokes'],
  components: {
    draggable,
  },
  mounted() {
    this.canvas = new fabric.Canvas(this.$refs.canvas, {
      isDrawingMode: true,
    })

    this.setCanvasSize()

    this.canvas.on('selection:cleared', () => {
      this.canvas.isDrawingMode = true
    })

    this.canvas.on('mouse:up', (options) => {
      if (this.selectedShape) {
        this.addShape(this.selectedShape, options.e.clientX, options.e.clientY)
        this.selectedShape = null
      }
    })

    // Add event listeners to the Fabric.js canvas
    this.canvas.upperCanvasEl.addEventListener('dragover', this.allowDrop)
    this.canvas.upperCanvasEl.addEventListener('drop', this.dropShape)
  },
  beforeDestroy() {
    // Remove event listeners from the Fabric.js canvas
    this.canvas.upperCanvasEl.removeEventListener('dragover', this.allowDrop)
    this.canvas.upperCanvasEl.removeEventListener('drop', this.dropShape)
  },
  watch: {
    color(newValue) {
      this.canvas.freeDrawingBrush.color = newValue
    },
    lineWidth(newValue) {
      this.canvas.freeDrawingBrush.width = newValue
    },
  },
  methods: {
    setCanvasSize() {
      this.canvas.freeDrawingBrush.color = this.color
      this.canvas.freeDrawingBrush.width = this.lineWidth

      $('.canvas-container').css('height', '')
      $('.canvas-container').css('width', '')
      $('canvas').css('height', '')
      $('canvas').css('width', '')

      $('.lower-canvas').css('width', '-webkit-fill-available')
      $('.upper-canvas').css('width', '-webkit-fill-available')

      setTimeout(() => {
        // Get whiteboardContainer's width and height
        let whiteboardContainerHeight = $('#whiteboardContainer').height()
        let whiteboardContainerWidth = $('#whiteboardContainer').width()

        this.canvas.setWidth(whiteboardContainerWidth)
        this.canvas.setHeight(whiteboardContainerHeight)
      }, 100)
    },
    setEraser() {
      this.color = this.defaultBackground
      this.canvas.freeDrawingBrush.color = this.defaultBackground
    },
    clearWhiteBoard() {
      this.canvas.clear()
    },
    addTextbox() {
      const textbox = new fabric.Textbox('Enter text', {
        left: 10,
        top: 10,
        width: 150,
        fontFamily: 'arial',
        fill: this.color,
        fontSize: 20,
      })
      this.canvas.isDrawingMode = false
      this.canvas.add(textbox)
      this.canvas.setActiveObject(textbox)
    },
    setDrawingMode() {
      this.canvas.isDrawingMode = true
    },
    selectShape(shape) {
      this.selectedShape = shape
    },
    setCanvasSize() {
      this.canvas.freeDrawingBrush.color = this.color
      this.canvas.freeDrawingBrush.width = this.lineWidth

      $('.canvas-container').css('height', '')
      $('.canvas-container').css('width', '')
      $('canvas').css('height', '')
      $('canvas').css('width', '')

      $('.lower-canvas').css('width', '-webkit-fill-available')
      $('.upper-canvas').css('width', '-webkit-fill-available')

      setTimeout(() => {
        // Get whiteboardContainer's width and height
        let whiteboardContainerHeight = $('#whiteboardContainer').height()
        let whiteboardContainerWidth = $('#whiteboardContainer').width()

        this.canvas.setWidth(whiteboardContainerWidth)
        this.canvas.setHeight(whiteboardContainerHeight)
      }, 100)

      // Add mouse event listener for canvas
      this.canvas.on('mouse:up', (options) => {
        if (this.selectedShape) {
          this.addShape(this.selectedShape, options.e.clientX, options.e.clientY)
          this.selectedShape = null
        }
      })
    },
    addShape(shape, x, y) {
      this.canvas.isDrawingMode = false
      fabric.Image.fromURL(shape.img, (img) => {
        // Scale the image
        img.scaleToWidth(100)

        // Adjust the position of the image
        img.set({
          left: x - img.getScaledWidth() / 2,
          top: y - img.getScaledHeight() / 2,
        })

        this.canvas.add(img)
        this.canvas.renderAll()
      })
    },

    startDrag(shape) {
      console.log('startDrag')
      this.draggingShape = shape
    },
    allowDrop(event) {
      event.preventDefault()
      console.log('allowDrop')
    },
    dropShape(event) {
      console.log('dropShape')
      if (this.draggingShape) {
        // Get the bounding rectangle of the canvas
        const rect = this.canvas.upperCanvasEl.getBoundingClientRect()

        // Calculate the drop coordinates relative to the canvas
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top

        this.addShape(this.draggingShape, x, y)
        this.draggingShape = null
      }
    },
  },
}
</script>

<style scoped>
#whiteboardContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  /* background-color: pink; */
  z-index: 1;
  border-radius: 5px;
}

canvas {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: white;
}
#controls {
  margin-bottom: 10px;
}

label {
  color: black;
}
</style>
