<template>
  <div class="matchingGame">
    <div class="col-lg-12">
      <div class="kt-portlet" data-ktportlet="true" id="kt_portlet_tools_2">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
              <i class="flaticon2-graph-1 kt-label-font-color-2"></i>
            </span>
            <h3 class="kt-portlet__head-title">Matching Game</h3>
            <h3 class="kt-portlet__head-title" style="margin-left: 110px">
              <span class="highlightMe" v-if="studentsTurn"
                >Student's turn</span
              >
              <span class="highlightMe" v-else>Tutor's turn</span>
              &nbsp;Student's points: <b>{{ studentPoints }}</b> | Tutor's
              points: <b>{{ tutorPoints }}</b>
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
              <div class="kt-portlet__head-group">
                <!-- <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-secondary btn-circle"
                  title="Undo"
                >
                  <i class="la la-undo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-secondary btn-circle"
                  title="Redo"
                >
                  <i class="la la-mail-forward"></i>
                </a> -->
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-success btn-circle"
                  title="Rules"
                  data-toggle="modal"
                  data-target="#matchingRulesModal"
                >
                  <i class="la la-exclamation-circle"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-danger btn-circle"
                  title="End Game"
                  @click="close"
                >
                  <i class="la la-close"></i>
                </a>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_mjlxyndbma"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    will-change: transform;
                    visibility: hidden;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(484px, -38px, 0px);
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 28px"></div>
                  <div class="tooltip-inner">Reload</div>
                </div>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_8wf7n27ova"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    will-change: transform;
                    visibility: hidden;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(442px, -38px, 0px);
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 34px"></div>
                  <div class="tooltip-inner">Collapse</div>
                </div>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_ca8wjj4ub2"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    transform: translate3d(516px, -39px, 0px);
                    top: 0px;
                    left: 0px;
                    will-change: transform;
                    visibility: hidden;
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 32px"></div>
                  <div class="tooltip-inner">Remove</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="kt-portlet__content">
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <div
                  v-show="!endedRound"
                  :key="key"
                  v-for="(image, key) in images"
                  v-if="image.visable"
                  class="col-lg-3 col-xl-2 order-lg-1 order-xl-1"
                >
                  <!--begin::Portlet-->
                  <a
                    href="javascript:;"
                    @click="flipImage('image_' + key, image.name)"
                  >
                    <div
                      class="kt-portlet kt-portlet--height-fluid imageHolder"
                    >
                      <img
                        :src="image.backSidePath"
                        :data-true-src="image.src"
                        :data-fake-src="image.backSidePath"
                        :id="'image_' + key"
                        class="tileImage"
                      />
                    </div>
                  </a>
                  <!--end::Portlet-->
                </div>
                <div v-show="endedRound" style="margin: auto">
                  <a href="javascript:;" @click="resetGame()"
                    ><h3 class="kt-portlet__head-title">
                      <span class="highlightMe"> Start over </span>
                    </h3></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="matchingRulesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="matchingRulesModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-l modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="matchingRulesModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-exclamation-1"></i> Rules
            </h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="modal-body">
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                  Player one turns over two cards, if the two cards turned over
                  match, a point is awarded to that player and that player takes
                  another turn. A player continues their turn until two cards
                  are turned over that do not match at which time player two
                  receives a turn.
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-outline-brand" data-dismiss="modal">Close</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "matchingGame",
  data() {
    return {
      images: [],
      numImagesOpen: 0,
      firstImageId: "",
      secondImageId: "",
      firstImageName: "",
      secondImageName: "",
      studentsTurn: true,
      studentPoints: 0,
      tutorPoints: 0,
      endedRound: false,
    };
  },
  mounted() {
    this.setimages();
  },
  methods: {
    flipImage(imageId, name) {
      const that = this;

      console.log('$("#" + imageId).attr("src")', $("#" + imageId).attr("src"));
      // Check if the image is already empty
      if ("" === $("#" + imageId).attr("src") || undefined === $("#" + imageId).attr("src") || null === $("#" + imageId).attr("src")){
        return;
      }

      let actualSrc = $("#" + imageId).data("true-src");

      //Seve the IDs for checking later
      if ("" === that.firstImageId && 0 === that.numImagesOpen) {
        that.firstImageId = imageId;
        that.firstImageName = name;
      } else if ("" === that.secondImageId && 1 === that.numImagesOpen) {
        that.secondImageId = imageId;
        that.secondImageName = name;
      }

      if (that.numImagesOpen < 2) {
        $("#" + imageId).attr("src", actualSrc);
      }

      //If correct
      if (
        that.firstImageName === that.secondImageName &&
        that.firstImageId !== that.secondImageId
      ) {
        let firstImageId = that.firstImageId;
        let secondImageId = that.secondImageId;

        that.firstImageId = "";
        that.secondImageId = "";

        that.numImagesOpen = 0;

        setTimeout(() => {
          // $('#' + firstImageId).attr('src', '')
          // $('#' + secondImageId).attr('src', '')
          $("#" + firstImageId).removeAttr("src");
          $("#" + secondImageId).removeAttr("src");

          if (that.studentsTurn) {
            that.studentPoints++;
          } else {
            that.tutorPoints++;
          }

          console.log("num images open is 2");

          // Commented this out to allow for a 2nd turn when right
          // that.studentsTurn = !that.studentsTurn

          // let addOnePoint = this.$store.getters.getClassroomInfoPoints + 1
          // let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1
          // this.$store.dispatch('setClassroomInfoPoints', addOnePoint)
          // this.$store.dispatch('setClassroomInfoTotalTries', addOneTry)

          let srcString = "";
          const imagElements = document.getElementsByClassName("tileImage");
          for (let i = 0; i < imagElements.length; i++) {
            srcString += imagElements[i].src;
          }
          if (!srcString) {
            that.endedRound = true;
          }
        }, 3000);
      } else if (1 === that.numImagesOpen) {
        //Else if wrong and if on 2nd image
        let actualSrcOne = $("#" + that.firstImageId).data("fake-src");
        let actualSrcTwo = $("#" + that.secondImageId).data("fake-src");

        let firstImageId = that.firstImageId;
        let secondImageId = that.secondImageId;

        that.firstImageId = "";
        that.secondImageId = "";

        that.firstImageName = "";
        that.secondImageName = "";

        that.numImagesOpen = 0;

        //Wait 3 sec before hiding images
        setTimeout(() => {
          $("#" + firstImageId).attr("src", actualSrcOne);
          $("#" + secondImageId).attr("src", actualSrcTwo);

          console.log("num images open is 2");
          that.studentsTurn = !that.studentsTurn;

          //Add a try but no point
          let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1;
          this.$store.dispatch("setClassroomInfoTotalTries", addOneTry);
        }, 3000);
      } else {
        that.numImagesOpen++;
      }
    },
    shuffleArray() {
      for (let i = this.images.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.images[i], this.images[j]] = [this.images[j], this.images[i]];
      }
    },
    close() {
      this.$store.dispatch("setClassroomInfoMatchingGameFinished", true);
      this.$emit("displayGame", false);
    },
    setimages() {
      const that = this;
      that.images = [];
      var storageRef = storage.ref();

      // Create a reference under which you want to list
      var listRef = storageRef.child("Images/Tiles");

      const studentUid = localStorage.getItem('classroomInfoStudentID')

      db.collection("users")
        .doc(studentUid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            //For twice the photos
            for (let k = 0; k < 2; k++) {
              for (var tile in doc.data().tiles) {
                let obj = {
                  name: tile,
                  visable: doc.data().tiles[tile],
                };

                // //If in the array add it to the page
                // const currentLesson = that.$store.getters.getClassroomInfoWhiteListedTiles
                // if (true === currentLesson.includes(obj.name.toLowerCase())) {
                that.images.push(obj);
                // }
              }

              // Find all the prefixes and items.
              listRef
                .listAll()
                .then((res) => {
                  var backSideRef = storageRef.child(
                    "Images/classroomBackgrounds/Back of Cards.jpg"
                  );

                  backSideRef.getDownloadURL().then((backSidePath) => {
                    res.items.forEach((item) => {
                      var starsRef = storageRef.child(item.location.path);
                      // Get the download URL
                      starsRef
                        .getDownloadURL()
                        .then((url) => {
                          let path = item.location.path;
                          let name = path
                            .split("Images/Tiles/")[1]
                            .split(".jpg")[0]
                            .toLowerCase();

                          for (let i = 0; i < that.images.length; i++) {
                            if (that.images[i].name == name) {
                              //that.images[i].src = url;Back of Cards.jpg
                              let obj = {
                                name: that.images[i].name,
                                visable: that.images[i].visable,
                                src: url,
                                backSidePath: backSidePath,
                              };

                              that.$set(that.images, i, obj);
                            }
                          }
                        })
                        .catch((error) => {
                          that.$store.dispatch("setInfoDanger", error.code);
                        });
                    });
                  });
                })
                .catch((error) => {
                  // Uh-oh, an error occurred!
                  console.error(error);
                });
            }

            that.shuffleArray();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    resetGame() {
      this.images = [];
      this.numImagesOpen = 0;
      this.firstImageId = "";
      this.secondImageId = "";
      this.firstImageName = "";
      this.secondImageName = "";
      this.studentsTurn = true;
      this.studentPoints = 0;
      this.tutorPoints = 0;
      this.endedRound = false;

      this.setimages();
    },
  },
};
</script>

<style scoped>
.tileImage {
  width: 100% !important;
  max-height: 150px;
}

.highlightMe {
  background-color: #dfdfdf;
  padding: 5px;
  border-radius: 3px;
}
</style>
