<template>
  <div class="procedureOne">
    <div
      class="row"
      v-if="
        false === matchingGameSelected &&
        false === thumbsGameSelected &&
        false === beatTheClockGameSelected
      "
    >
      <div
        class="col-lg-12"
        v-if="!matchingGameSelected"
        style="cursor: pointer"
      >
        <a @click="matchingGameSelected = true" href="javascript:;">
          <label class="kt-option">
            <div class="col-lg-4">
              <label class="kt-checkbox kt-checkbox--bold kt-checkbox--success">
                <input type="checkbox" />
                <span></span>
              </label>

              <span class="kt-option__head">
                <span class="kt-option__title">Matching Game</span>
              </span>
            </div>
            <div class="col-lg-8">
              <img
                style="width: 300px; float: left"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FCapture.PNG?alt=media&token=dcfba9c6-4aa8-4afe-9b5a-88df00387e66"
              />
            </div>
          </label>
        </a>
      </div>
      <div class="col-lg-12" v-if="!thumbsGameSelected">
        <a @click="thumbsGameSelected = true" href="javascript:;">
          <label class="kt-option">
            <div class="col-lg-4">
              <label class="kt-checkbox kt-checkbox--bold kt-checkbox--success">
                <input type="checkbox" />
                <span></span>
              </label>

              <span class="kt-option__head">
                <span class="kt-option__title">Thumbs Up Game</span>
              </span>
            </div>
            <div class="col-lg-8">
              <img
                style="width: 100px; float: left"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FCapture%20thumbs.PNG?alt=media&token=e0064a78-7468-48b6-9104-be07dcb8f11c"
              />
            </div>
          </label>
        </a>
      </div>
      <div class="col-lg-12" v-if="!beatTheClockGameSelected">
        <a @click="beatTheClockGameSelected = true" href="javascript:;">
          <label class="kt-option">
            <div class="col-lg-4">
              <label class="kt-checkbox kt-checkbox--bold kt-checkbox--success">
                <input type="checkbox" />
                <span></span>
              </label>

              <span class="kt-option__head">
                <span class="kt-option__title">Beat the Clock Game</span>
              </span>
            </div>
            <div class="col-lg-8">
              <img
                style="width: 300px; float: left"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FCapture%20beat.PNG?alt=media&token=45de5d73-1ede-4158-a366-b43a0ea7e706"
              />
            </div>
          </label>
        </a>
      </div>
    </div>
    <matchingGame
      @displayGame="hideGame"
      v-if="matchingGameSelected"
    ></matchingGame>
    <thumbsGame @displayGame="hideGame" v-if="thumbsGameSelected"></thumbsGame>
    <beatTheClockGame
      @displayGame="hideGame"
      v-if="beatTheClockGameSelected"
    ></beatTheClockGame>
  </div>
</template>

<script>
import matchingGame from "./matchingGame";
import thumbsGame from "./thumbsGame";
import beatTheClockGame from "./beatTheClockGame";

export default {
  name: "procedureOne",
  data() {
    return {
      matchingGameSelected: false,
      thumbsGameSelected: false,
      beatTheClockGameSelected: false,
    };
  },
  components: { matchingGame, thumbsGame, beatTheClockGame },
  mounted() {
    const that = this;

    // that.$store.dispatch("setClassroomInfoPoints", 0);
    // that.$store.dispatch("setClassroomInfoTotalTries", 0);

    // that.$root.$on("saveClassSession", () => {
    //   that.saveClassSession();
    // });
  },
  methods: {
    // async saveClassSession() {
    //   const that = this;

    //   const suid = localStorage.getItem('classroomInfoStudentID')
    //   const totalPoints = this.$store.getters.getClassroomInfoPoints;
    //   const totalTries = this.$store.getters.getClassroomInfoTotalTries;

    //   const averageProficiency = parseFloat(100 * (totalPoints / totalTries));

    //   //If on lesson 1
    //   if ("lesson2" === localStorage.getItem('classroomInfoCurrentLesson')) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson2",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 9, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 2, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 12, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson3" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson3",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 17, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 3, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 12, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson4" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson4",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 25, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 4, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 12, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson5" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson5",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 33, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 5, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 12, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson6" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson6",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 41, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 6, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 12, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson7" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson7",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 49, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 7, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 12, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson8" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson8",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 57, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 8, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 12, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson9" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson9",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 65, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 9, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 10, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson10" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson10",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 75, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 10, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 10, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson11" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson11",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 85, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 11, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 10, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   } else if (
    //     "lesson12" === localStorage.getItem('classroomInfoCurrentLesson')
    //   ) {
    //     await db
    //       .collection("users")
    //       .doc(localStorage.getItem('userID')
    //       .collection("students")
    //       .doc(suid)
    //       .update({
    //         currentLesson: "lesson12",
    //         graduatedStudent: false,
    //         totalPoints: parseInt(totalPoints),
    //         totalTries: parseInt(totalTries),
    //         averageProficiency: averageProficiency.toFixed(2),
    //         studentProgress: 95, //141 total procedures (so studentProgress is the number of procedures complted up to this point)
    //         studentReadableProgress: "Lesson 12, Procedure 2", //Update to the next lesson
    //         currentLessonProgress: 10, //% complete of lesson 1
    //       })
    //       .catch(function (error) {
    //         // The document probably doesn't exist.
    //         console.error("Error updating document: ", error);
    //       });
    //   }

    //   //Flush VueX state
    //   //   this.$store.dispatch("setClassroomInfoPoints", 0);
    //   //   this.$store.dispatch("setClassroomInfoTotalTries", 0);

    //   localStorage.setItem('classroomInfoCurrentLesson', '')
    //   console.log('--setting-- currentLesson to empty')
    //   debugger

    //   this.$store.dispatch("setClassroomInfoWhiteListedTiles", []);

    //   this.$store.dispatch("setClassroomInfoMatchingGameFinished", false);
    //   this.$store.dispatch("setClassroomInfoThumbsGameFinished", false);
    //   this.$store.dispatch("setClassroomInfoBeatTheClockFinished", false);
    //   this.$store.dispatch("setClassroomInfoSeqPicsFinished", false);
    //   this.$store.dispatch("setClassroomInfoSeqTilesFinished", false);
    //   this.$store.dispatch("setClassroomInfoStopLightFinished", false);
    //   this.$store.dispatch("setClassroomInfoPhotoCardFinished", false);
    //   this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
    //   this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
    //   this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
    //   this.$store.dispatch("setClassroomInfoWhiteListedTiles", false);
    // },
    hideGame() {
      this.matchingGameSelected = false;
      this.thumbsGameSelected = false;
      this.beatTheClockGameSelected = false;
    },
  },
};
</script>

<style scoped>
.kt-option {
  background-color: white;
}
</style>
