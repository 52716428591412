<template>
  <div id="gamesModal" style="display: none">
    <div
      class="modal position-relative d-block"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Play games from Procedure One</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </div>
          <procedureOne></procedureOne>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import procedureOne from "./procedureOne";

export default {
  name: "gamesModal",
  components: { procedureOne },
  data() {
    return {
      images: [],
    };
  },
  mounted() {
    const that = this;
  },
};
</script>

<style scoped></style>
