<template>
  <div>
    <div id="adminDashboard">
      <div id="pageSpinner" v-show="isLoading">
        <div
          class="spinner-grow"
          id="spinner"
          style="width: 3rem; height: 3rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-show="!isLoading"
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
      >
        <!-- start small row -->
        <div class="kt-subheader kt-grid__item" id="kt_subheader">
          <div class="kt-subheader__main">
            <h2
              class="kt-subheader__title"
              style="font-size: 20px; font-weight: bold"
            >
              Admin Panel
            </h2>
          </div>
          <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
              <a
                href="javascript:;"
                id="newStudentButton"
                class="btn btn-brand btn-bold btn-upper btn-font-sm"
                data-toggle="modal"
                data-target="#newStudentModal"
              >
                <i class="la la-plus"></i> Add a Student
              </a>
              <!-- <div class="btn calenderBtn">
                <a
                  href="javascript:;"
                  @click="openBilling"
                  id="newBillingButton"
                  class="btn btn-brand btn-bold btn-upper btn-font-sm"
                >
                  <i class="la la-bank"></i> Billing
                </a>
              </div> -->
            </div>
          </div>
        </div>
        <!-- end small row -->

        <div
          class="kt-content kt-grid__item kt-grid__item--fluid"
          id="kt_content"
        >
          <div v-if="!isLoading">
            <div class="row">
              <div
                class="col-lg-12 col-xl-12 order-lg-2 order-xl-1"
                id="addStudentInfo"
              >
                <!--begin::Portlet-->

                <!-- <students id="students"></students> -->
                <Tutors id="tutors"></Tutors>

                <!--end::Portlet-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <OverlayAdminPage
      :show="payLeaningCenter"
      :payment-link="paymentLinkLeaningCenter"
    ></OverlayAdminPage> -->
  </div>
</template>

<script>
//import "core-js/stable";
import "regenerator-runtime/runtime";
import Tutors from "./Tutors.vue";
import axios from "axios";
import OverlayAdminPage from "./OverlayAdminPage.vue";

// import db from './firebaseDB'

export default {
  name: "adminDashboard",
  data() {
    return {
      today: moment().format("ddd, MMMM Do"),
      isLoading: true,
      accountType: "",
      paymentLinkLeaningCenter: "",
      payLeaningCenter: true,
    };
  },
  created() {
    const that = this;
  },
  components: { Tutors, OverlayAdminPage },
  async mounted() {
    const that = this;
    let uid = localStorage.getItem("userID");
    let isAdmin = null;
    let accountType = null;
    let querySnapshot = null;

    async function findUID() {
      await new Promise((resolve) => {
        firebase.auth().onAuthStateChanged(async function (user) {
          if (user) {
            // Get user's role in custom claims
            const tokenResult = await user.getIdTokenResult();
            isAdmin = !!tokenResult.claims.admin;
            accountType = tokenResult.claims.accountType;

            if (accountType != "tutorAdmin") {
              that.showOverlay = false;
            }

            console.log("here --------------");

            querySnapshot = await db
              .collection("users")
              .doc(user.uid)
              .get()
              .catch(function (error) {
                console.error("Error getting document:", error);
              });

            // Get accountType from custom claims
            that.accountType = tokenResult.claims.accountType;
            accountType = tokenResult.claims.accountType;

            that.$store.dispatch("setAuthStatus", true);
            that.$store.dispatch("setAdminStatus", isAdmin);

            localStorage.setItem("userID", user.uid);
            uid = localStorage.getItem("userID");

            // Get the payment link for the learning center from the user's document
            const userData = querySnapshot.data();
            that.paymentLinkLeaningCenter = userData.paymentLink;
            console.log(
              "that.paymentLinkLeaningCenter",
              that.paymentLinkLeaningCenter
            );
            console.log("userData.paid", userData.paid);
            console.log("accountType", accountType);

            // If account type is learning center, then show the payment link if they haven't paid
            if (accountType == "learningCenter" || accountType == 'tutorAdmin') {
              if (userData.paid) {
                that.payLeaningCenter = false;
              }
            }

            that.isLoading = false;
            resolve();
          }
        });
      });
    }

    if (!uid || !accountType || !isAdmin) {
      await findUID();
    } else {
      that.isLoading = false;
    }
  },
  methods: {
    async openBilling() {
      const that = this;

      // Set mouse cursor to loading spinner
      window.document.body.style.cursor = "wait";

      const token = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);

      // Get secure billing portal link from server
      await axios
        .post("/api/billing/portal", {
          token: token,
        })
        .then((response) => {
          // Set mouse cursor back to default
          window.document.body.style.cursor = "default";

          window.open(response.data.url, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });

      // Set mouse cursor back to default
      window.document.body.style.cursor = "default";
    },
  },
};
</script>

<style>
body,
html,
.adminDashboard {
  background-color: #b1987a !important;
}
</style>

<style scoped></style>
