<template>
  <div id="renewStudent">
    <div
      class="modal fade"
      id="renewStudentModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="renewStudentModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="renewStudentModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-user-settings"></i> Renew
              Student: <b>{{ renewStudentFullName }}</b>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <br />
            <!-- <p v-if="!message" style="font-size: 15px">
              Renewing <b>{{ renewStudentFullName }}</b> will bill you $0.00 for an additional 90 day student subscription.
            </p> -->
            <h4 v-if="message" class="text-center">{{ message }}</h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-brand"
              @click="renewStudent()"
              v-bind:class="{ disabled: isSubmitting }"
            >
              Renew Student
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import firebase from "./firebaseInit"

export default {
  name: "renewStudent",
  props: ["renewStudentID", "renewStudentFullName"],
  data() {
    return {
      message: false,
      isSubmitting: false,
    };
  },
  mounted() {},
  methods: {
    renewStudent() {
      const that = this;

      that.isSubmitting = true;

      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          let data = {
            studentFullName: that.studentFullName,
            renewStudentID: that.renewStudentID,
            token: idToken,
          };

          that.$http.post("/api/renewStudent", data).then(
            function (res) {
              if (res.body.success) {
                that.$store.dispatch(
                  "setInfoSuccess",
                  "Student subscription successfully extended!"
                );
                $("html, body").animate({ scrollTop: 0 }, "fast");
                that.message = "Student subscription successfully extended!";
                //$('#renewStudentModal').modal('hide');
                setTimeout(function () {
                  window.location.reload();
                }, 2000);
              } else {
                that.isSubmitting = false;
                //TODO handle errors better
                //$('#renewStudentModal').modal('hide');
                that.$store.dispatch(
                  "setInfoDanger",
                  "Oops something went wrong, please try again later or contact support if the problem persists: " +
                    res.body.message
                );
                that.message =
                  "Oops something went wrong, please try again later or contact support if the problem persists: " +
                  res.body.message;
                $("html, body").animate({ scrollTop: 0 }, "fast");
              }
            },
            function (err) {
              console.error(err);
              that.isSubmitting = false;
            }
          );
        })
        .catch(function (error) {
          that.isSubmitting = false;
          that.$store.dispatch(
            "setInfoDanger",
            "Oops something went wrong, please try again later or contact support if the problem persists."
          );
          that.message =
            "Oops something went wrong, please try again later or contact support if the problem persists.";
          $("html, body").animate({ scrollTop: 0 }, "fast");
          //$('#renewStudentModal').modal('hide');
        });
    },
  },
};
</script>

<style scoped></style>
