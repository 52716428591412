<template>
  <div id="tutors" v-if="loaded">
    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Your tutors</h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          <div class="kt-portlet__head-wrapper">
            &nbsp;
            <a
              href="javascript:;"
              id="newTutorButton"
              class="btn btn-brand btn-bold btn-upper btn-font-sm"
              data-toggle="modal"
              v-show="showNewTutorButton"
              data-target="#newTutorModal"
            >
              <i class="la la-plus"></i> New Tutor
            </a>
          </div>
        </div>
      </div>
      <div class="kt-portlet__body kt-portlet__body--fit">
        <!--begin: Datatable -->
        <div
          class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded"
        >
          <table
            class="kt-datatable__table"
            id="html_table"
            width="100%"
            style="display: block; min-height: 300px"
          >
            <thead class="kt-datatable__head">
              <tr class="kt-datatable__row" style="left: 0px">
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Name</span>
                </th>
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Email</span>
                </th>
                <!-- <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Days as a tutor</span>
                </th> -->
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>View as</span>
                </th>
                <th
                  class="kt-datatable__cell kt-datatable__cell--sort"
                  style="width: 17%"
                >
                  <span>Enabled/disabled</span>
                </th>
              </tr>
            </thead>
            <tbody class="kt-datatable__body ps">
              <div v-if="0 == tutors.length">
                <tr data-row="0" class="kt-datatable__row" style="left: 0px">
                  <td class="kt-datatable__cell" style="width: 100%">
                    You currently do not have any tutors.
                  </td>
                </tr>
              </div>

              <tr
                v-for="(tutor, key) in tutors"
                :key="key"
                data-row="0"
                class="kt-datatable__row"
                style="left: 0px"
              >
                <td class="kt-datatable__cell" style="width: 17%">
                  <span v-if="myUid === tutor.myUid">(you)</span>
                  <span v-else>{{ tutor.tutorFullName }}</span>
                </td>
                <td class="kt-datatable__cell" style="width: 17%">
                  {{ tutor.tutorEmail }}
                  <span v-if="myUid === tutor.myUid">(you)</span>
                </td>
                <!-- <td class="kt-datatable__cell" style="width: 17%">
                  {{ getPrettyDate(tutor.daysAsATutor) }}
                </td> -->
                <td class="kt-datatable__cell" style="width: 17%">
                  <a
                    v-if="!tutor.disabled"
                    href="javascript:;"
                    @click="viewAs(tutor.myUid)"
                    class="btn btn-outline-brand"
                  >
                    <i class="flaticon-eye"></i> {{ tutor.myUid === myUid ? "View as" : "Sign in" }}
                  </a>
                </td>
                <td class="kt-datatable__cell" style="width: 17%">
                  <div>
                    <div v-show="isLoading">loading...</div>
                    <div v-show="!tutor.disabled && !isLoading">Enabled</div>
                    <a
                      :href="tutor.paymentLink"
                      v-show="tutor.disabled && !isLoading"
                      :class="[
                        tutor.disabled ? 'btn-outline-brand-view-as' : '',
                        'btn btn-outline-brand',
                      ]"
                    >
                      Pay for tutor
                    </a>
                  </div>
                </td>
              </tr>

              <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                  class="ps__thumb-x"
                  tabindex="0"
                  style="left: 0px; width: 0px"
                ></div>
              </div>
              <div class="ps__rail-y" style="top: 0px; right: 0px">
                <div
                  class="ps__thumb-y"
                  tabindex="0"
                  style="top: 0px; height: 0px"
                ></div>
              </div>
            </tbody>
          </table>
        </div>
        <!--end: Datatable -->
      </div>
    </div>

    <NewTutor />
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import NewTutor from "./NewTutor.vue";

export default {
  name: "tutors",
  data() {
    return {
      tutors: [],
      loaded: true,
      myUid: "",
      isLoading: false,
      showNewTutorButton: false,
    };
  },
  components: { NewTutor },
  async created() {
    const that = this;

    // Get the user's uId
    that.myUid = localStorage.getItem("userID");

    // Get tutors from firebase
    const querySnapshot = await db
      .collection("users")
      .doc(localStorage.getItem("userID"))
      .collection("tutors")
      .get()
      .catch(function (error) {
        console.error("Error getting document:", error);
      });

    // Loop through tutors and add to tutors array
    querySnapshot.docs.forEach((doc) => {
      const tutorData = doc.data();

      var tutor = {
        tutorFullName: tutorData.tutorFullName,
        tutorEmail: tutorData.tutorEmail,
        daysAsATutor: tutorData.dateCreated,
        myUid: tutorData.myUid,
        disabled: tutorData.disabled,
        paymentLink: tutorData.paymentLink,
      };

      that.tutors.push(tutor);
    });
  },
  async mounted() {
    const that = this;

    const tokenResult = await firebase
      .auth()
      .currentUser.getIdTokenResult(true);
    const isAdmin = !!tokenResult.claims.admin;
    const isTutor = !!tokenResult.claims.tutor;
    const accountType = tokenResult.claims.accountType;

    console.log("accountType:", accountType);

    that.showNewTutorButton = accountType != "homeschooler";
  },
  methods: {
    getPrettyDate(date) {
      // Take unix timestamp and convert to a pretty date with Moment
      return moment.unix(date).format("MMM Do YYYY");
    },
    async viewAs(tutorUid) {
      const that = this;

      that.$store.dispatch("setAdminStatus", false);

      if (this.myUid === tutorUid) {
        that.$router.push("/dashboard");
      } else {

        // Confirm with user
        if (!confirm("Are you sure you want to view as this tutor? You will be signed out of your current session and signed in as the tutor.")) {
          return;
        }

        // Post to generateTutorToken
        const tokenResult = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true);

        const response = await axios.post("/api/generateTutorToken", {
          token: tokenResult,
          tutorUid: tutorUid,
        });

        // Check for errors
        if (response.data.error) {
          console.error("Error generating tutor token:", response.data.error);
          return;
        }

        const data = response.data;

        const customToken = data.token;

        // First signout and then navigate to the /authenticate page with the custom token in the URL
        firebase
          .auth()
          .signOut()
          .then(function () {
            // Sign-out successful.
            that.$store.dispatch("setAuthStatus", false);
            that.adminStatus = false;
            that.$store.dispatch("setAdminStatus", false);
            localStorage.removeItem("userID");

            that.$router.push({
              path: "/authenticate",
              query: {
                customToken: customToken,
                viewAs: true,
              },
            });
          })
          .catch(function (error) {
            // An error happened.
          });
      }
    },
  },
};
</script>

<style scoped>
.doneGreen {
  background: #c5fff9;
}

.doneYellow {
  background: #fbffc5;
}

.btn-outline-warning:hover {
  color: white;
}

.color-yellow a {
  color: #f0ad4e;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.btn-outline-brand-view-as {
  animation: pulsate 1.5s infinite;
  border-color: #3699ff;
  color: #3699ff;
}
</style>
