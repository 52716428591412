<template>
  <div id="classroomSettings">
    <div
      class="modal fade"
      id="classroomSettingsModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="classroomSettingsModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="classroomSettingsModalCenterTitle">
              <i class="kt-menu__link-icon flaticon2-settings"></i> Classroom
              Settings
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <a
                href="javascript:;"
                @click="openModal()"
                style="margin-left: 35px"
                class="btn btn-primary btn-wide"
                ><i class="la la-cogs"></i>Open Sounds Stop Light
              </a>
              <a
                href="javascript:;"
                @click="unselectAll()"
                style="margin-left: 35px"
                class="btn btn-primary btn-wide"
                >Unselect all
              </a>
              <a
                href="javascript:;"
                @click="selectAll()"
                style="margin-left: 35px"
                class="btn btn-primary btn-wide"
                >Select all
              </a>
            </div>
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <div
                  :key="key"
                  v-for="(image, key) in images"
                  class="col-lg-3 col-xl-2 order-lg-1 order-xl-1"
                >
                  <!--begin::Portlet-->
                  <div class="kt-portlet kt-portlet--height-fluid imageHolder">
                    <div class="kt-portlet__head kt-portlet__head--noborder">
                      <div class="kt-portlet__head-label">
                        <span class="kt-switch kt-switch--icon">
                          <label>
                            <input
                              type="checkbox"
                              v-model="image.visable"
                              checked="checked"
                              name
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="kt-portlet__body kt-portlet__body--fluid">
                      <img :src="image.src" class="tileImage" />
                    </div>
                  </div>
                  <!--end::Portlet-->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-brand"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-brand"
              data-dismiss="modal"
              @click="saveSettings"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let allTiles;

const imageURLs = [
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9",
  "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4",
];

let imageData = [];

export default {
  name: "classroomSettings",
  data() {
    return {
      images: [],
    };
  },
  mounted() {
    const that = this;

    $("#classroomSettingsModal").on("shown.bs.modal", () => {
      that.opened();
    });
  },
  methods: {
    opened() {
      const that = this;
      that.images = [];
      const studentUid = localStorage.getItem("classroomInfoStudentID");

      db.collection("users")
        .doc(studentUid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            allTiles = doc.data().tiles;

            for (var tile in doc.data().tiles) {
              let obj = {
                name: tile,
                visable: doc.data().tiles[tile],
              };

              //If in the array add it to the page
              // const currentLesson = that.$store.getters.getClassroomInfoWhiteListedTiles
              // if (true === currentLesson.includes(obj.name.toLowerCase())) {
              imageData.push(obj);
              // }
            }
            for (let i = 0; i < imageURLs.length; i++) {
              const imageURL = imageURLs[i];
              let name = imageURL
                .split("Images%2FTiles%2F")[1]
                .split(".jpg")[0]
                .toLowerCase();

              let obj = imageData.find((x) => x.name === name);
              obj.src = imageURL;

              // let obj = {
              //   name: imageData[i].name,
              //   visable: imageData[i].visable,
              //   src: imageURL,
              // }

              that.images.push(obj);
              // that.$set(that.images, i, obj)
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    openModal() {
      $("#classroomSettingsModal").modal("hide");
      $("#soundStopLightModal").modal("show");
    },
    saveSettings() {
      const that = this;
      const studentUid = localStorage.getItem("classroomInfoStudentID");

      for (let i = 0; i < that.images.length; i++) {
        let name = that.images[i].name;
        let boolean = that.images[i].visable;

        if (boolean !== true && boolean !== false) {
          boolean = true;
        }

        allTiles[name] = boolean;
      }

      let ref = db.collection("users").doc(studentUid);

      return ref
        .update({
          tiles: allTiles,
        })
        .then(function () {
          console.log("Document successfully updated!");
          window.location.reload();
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    unselectAll() {
      const that = this;
      for (let i = 0; i < that.images.length; i++) {
        that.images[i].visable = false;
      }
    },
    selectAll() {
      const that = this;
      for (let i = 0; i < that.images.length; i++) {
        that.images[i].visable = true;
      }
    },
  },
};
</script>

<style scoped>
.tileImage {
  width: 100% !important;
  margin-top: -20px;
}
</style>
