<template>
  <div id="sign-in">
    <!-- begin:: Page -->

    <div class="kt-grid kt-grid--ver kt-grid--root" v-show="!hasDecided">
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid kt-grid--hor kt-login-v2"
        id="kt_login_v2"
      >
        <!--begin::Item-->
        <div class="kt-grid__item kt-grid--hor">
          <!--begin::Heade-->
          <div class="kt-login-v2__head">
            <div class="kt-login-v2__logo">
              <a href="/">
                <img
                  alt="Logo"
                  style="width: 33px; height: 33px"
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F09ecaa_2c390b832a89448697f16121a49e61da_mv2.png?alt=media&token=c25ed621-3189-496a-9910-f9c093c32e14"
                />
              </a>
            </div>
            <div class="kt-login-v2__signup">
              <span>Already have an account?</span>
              <a href="/signin" class="kt-link kt-font-brand">Sign In</a>
            </div>
          </div>
          <!--begin::Head-->
        </div>
        <!--end::Item-->

        <div>
          <div
            class="card text-center shadow-sm"
            style="margin: auto; max-width: fit-content"
          >
            <!-- <div class="card-body">
                <h1
                  class="card-title"
                  style="margin-top: 10px; margin-bottom: 10px;"
                >
                  Select the plan that's right for you
                </h1>
              </div> -->
          </div>
          <div class="customArea" style="margin-top: 243px">
            <div
              class="card shadow-sm"
              style="margin-left: 10px; min-width: 400px"
            >
              <div class="card-header">
                <h3 class="my-0 font-weight-normal">Sign in</h3>
              </div>
              <div class="card-body">
                <router-link
                  to="/signin"
                  class="btn btn-lg btn-block btn-primary"
                  style="padding: 15px 30px"
                >
                  Sign in
                </router-link>
              </div>
            </div>

            <div
              class="card shadow-sm"
              style="margin-left: 10px; min-width: 400px"
            >
              <div class="card-header">
                <h3 class="my-0 font-weight-normal">Sign up</h3>
              </div>
              <div class="card-body">
                <router-link
                  to="/signup"
                  class="btn btn-lg btn-block btn-primary"
                  style="padding: 15px 30px"
                >
                  Sign up
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!--end::Wrapper-->
      </div>
    </div>

    <div class="kt-grid kt-grid--ver kt-grid--root" v-show="hasDecided">
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid kt-grid--hor kt-login-v2"
        id="kt_login_v2"
      >
        <!--begin::Item-->
        <div class="kt-grid__item kt-grid--hor">
          <!--begin::Heade-->
          <div class="kt-login-v2__head">
            <div class="kt-login-v2__logo">
              <a href="/">
                <img
                  alt="Logo"
                  style="width: 33px; height: 33px"
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F09ecaa_2c390b832a89448697f16121a49e61da_mv2.png?alt=media&token=c25ed621-3189-496a-9910-f9c093c32e14"
                />
              </a>
            </div>
            <div class="kt-login-v2__signup">
              <span>Already have an account?</span>
              <a href="/signin" class="kt-link kt-font-brand">Sign In</a>
            </div>
          </div>
          <!--begin::Head-->
        </div>
        <!--end::Item-->

        <!--begin::Item-->
        <div class="kt-grid__item kt-grid kt-grid--ver kt-grid__item--fluid">
          <!--begin::Body-->
          <div class="kt-login-v2__body">
            <!--begin::Wrapper-->
            <div class="kt-login-v2__wrapper"></div>
            <!--end::Wrapper-->

            <!--begin::Wrapper-->
            <div class="kt-login-v2__wrapper">
              <div class="kt-login-v2__container">
                <div class="kt-login-v2__title">
                  <!-- <h3>Create an administrator account</h3> -->
                  <h4 style="margin-top: 20px">
                    Create a
                    <b>{{
                      selectedAccountType == "tutorAdmin"
                        ? "tutor"
                        : selectedAccountType
                    }}</b>
                    admin account
                  </h4>
                </div>

                <!--begin::Form-->
                <form class="kt-login-v2__form kt-form" autocomplete="off">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="E-mail"
                      v-model="email"
                      autocomplete="on"
                      v-on:keyup.enter="auth()"
                    />
                  </div>
                  <!-- <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        placeholder="Password"
                        v-on:keyup.enter="auth()"
                        v-model="password"
                        autocomplete="off"
                      />
                    </div> -->
                  <!--begin::Action-->
                  <div class="kt-login-v2__actions">
                    <!-- <a
                        href="javascript:;"
                        @click="passwordResetEmail()"
                        class="kt-link kt-link--brand"
                        >Forgot Password?</a
                      > -->
                    <a
                      href="javascript:;"
                      @click="sendMagicLink()"
                      class="btn btn-brand btn-elevate btn-pill"
                      v-bind:class="{
                        disabled: !email || submittingForm,
                      }"
                      >Send confirmation email</a
                    >
                  </div>
                  <!--end::Action-->
                </form>
                <!--end::Form-->

                <!--begin::Separator-->
                <!-- <div class="kt-separator kt-separator--space-lg kt-separator--border-solid"></div> -->
                <!--end::Separator-->
              </div>
            </div>
            <!--end::Wrapper-->
          </div>
          <!--begin::Body-->
        </div>
        <!--end::Item-->

        <!--begin::Item-->
        <div class="kt-grid__item">
          <div class="kt-login-v2__footer">
            <div class="kt-login-v2__link">
              <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Text%2Fedited_pp.pdf?alt=media&token=a974cca2-ea51-48b6-9a29-f5871e14c5c3"
                class="kt-link kt-font-brand"
                >Privacy</a
              >
              <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Text%2Fedited_tos.pdf?alt=media&token=a3696e71-7b41-4d37-a8b0-bdb4694b0712"
                class="kt-link kt-font-brand"
                >Terms</a
              >
              <a
                href="javascript:;"
                class="kt-link kt-font-brand"
                data-toggle="modal"
                data-target="#supportModal"
                >Contact</a
              >
            </div>

            <div class="kt-login-v2__info">
              <a href="javascript:;" class="kt-link"
                >&copy; {{ year }} Jamo Learning Inc</a
              >
            </div>
          </div>
        </div>
        <!--end::Item-->
      </div>
    </div>

    <!-- Start Support Modal -->
    <div
      class="modal fade"
      id="supportModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="supportModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="supportModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-support"></i>
              Contact Support
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <br />
            <h1 class="text-center">+1 719 476-0189</h1>
            <br />
            <br />
            <h4 class="text-center text-muted">
              -- Or send us an email below --
            </h4>
            <br />
            <br />

            <form
              id="fs-frm"
              name="simple-contact-form"
              class="kt-form"
              accept-charset="utf-8"
            >
              <fieldset id="fs-frm-inputs" class="form-group">
                <label for="full-name">Full Name</label>
                <input
                  class="form-control"
                  v-model="fullName"
                  type="text"
                  name="name"
                  id="full-name"
                  placeholder="First and Last"
                  required
                />
                <br />
                <label for="email-address">Email Address</label>
                <input
                  class="form-control"
                  v-model="email"
                  type="email"
                  name="_replyto"
                  id="email-address"
                  placeholder="email@email.com"
                  required
                />
                <br />
                <label for="message">Message</label>
                <textarea
                  class="form-control"
                  v-model="message"
                  rows="5"
                  name="message"
                  id="message"
                  placeholder="Your question here."
                  required
                ></textarea>
              </fieldset>
              <a
                href="javascript:;"
                @click="submitContactForm()"
                class="btn btn-primary"
                >Submit</a
              >
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End Support Modal -->

    <!-- end:: Page -->
  </div>
</template>

<script>
import $ from "jquery"; //needed?

import axios from "axios";

export default {
  name: "signin",
  data() {
    return {
      email: "",
      password: "",
      year: new Date().getFullYear(),
      submittingForm: false,
      fullName: "",
      emailContact: "",
      message: "",
      hasDecided: false,
      selectedAccountType: "",
    };
  },
  mounted() {
    const that = this;

    // Get selectedAccountType from URL query
    const urlParams = new URLSearchParams(window.location.search);
    const accountType = urlParams.get("accountType");

    if (accountType) {
      that.hasDecided = true;
      that.selectedAccountType = accountType;
    }
  },
};
</script>

<style scoped>
#sign-in {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FScreenshot%202023-04-05%20134239.png?alt=media&token=00046bb7-3567-45e8-b64c-fe508af89ff8");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* filter: blur(8px); */
  /* -webkit-filter: blur(8px); */
}
.customArea {
  display: flex;
  justify-content: center; /* This centers the cards horizontally when stacked side by side */
}
</style>
