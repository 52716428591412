<template>
  <div class="container">
    <h2>Terms of Service</h2>
    TOS
  </div>
</template>

<script>
export default {
  name: "signin",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style></style>
