<template>
  <div id="studentsProgress">
    <!--begin::Portlet-->
    <div class="kt-portlet kt-portlet--height-fluid">
      <!--  style="background: #FFE2E5;" -->
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Students Progress</h3>
        </div>
      </div>
      <div class="kt-portlet__body kt-portlet__body--fluid">
        <div class="kt-widget-9">
          <div class="kt-widget-9__panel">
            <div class="kt-widget-9__legends">
              <div class="kt-widget-9__legend">
                <div class="kt-widget-9__legend-bullet kt-bg-brand"></div>
                <div
                  class="kt-widget-9__legend-label"
                  style="font-size: 14px; color: #5d78ff"
                >
                  Lesson Completion
                </div>
              </div>
              <!-- <div class="kt-widget-9__legend">
                <div class="kt-widget-9__legend-bullet kt-label-bg-color-1"></div>
                <div class="kt-widget-9__legend-label">Total Proficiency</div>
              </div> -->
            </div>
          </div>
          <div class="kt-widget-9__chart">
            <div class="chartjs-size-monitor">
              <div class="chartjs-size-monitor-expand">
                <div class></div>
              </div>
              <div class="chartjs-size-monitor-shrink">
                <div class></div>
              </div>
            </div>
            <!--Doc: For the chart initialization refer to "widgetSalesStatisticsChart" function in "src\theme\app\scripts\custom\dashboard.js" -->
            <canvas
              id="kt_chart_sales_statistics"
              height="375"
              width="937"
              class="chartjs-render-monitor"
              style="display: block; height: 300px; width: 750px"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
    <!--end::Portlet-->
  </div>
</template>

<script>
// import db from './firebaseDB'

let studentReadableProgress = [];

export default {
  name: "studentsProgress",
  data() {
    return {
      studentNames: [],
      studentsProgress: [],
      studentsProficiency: [],
    };
  },
  created() {
    const that = this;

    let i = 0;
    var docRef = db
      .collection("users")
      .doc(localStorage.getItem('userID'))
      .collection("students")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          // Break if more than 10 students
          if (i > 9) {
            return;
          }
          // Get student full name, abbreviate the first name
          let studentName =
            doc.data().studentFullName.split(" ")[0].charAt(0).toUpperCase() +
            ". " +
            doc.data().studentFullName.split(" ")[1];
          // Capitalize the first letter of the student's second name
          studentName =
            studentName.split(".")[0] +
            ". " +
            studentName.split(" ")[1].charAt(0).toUpperCase() +
            studentName.split(" ")[1].slice(1);
          that.studentNames.push(studentName);
          that.studentsProgress.push(doc.data().studentProgress);
          that.studentsProficiency.push(doc.data().averageProficiency);

          const SRP = doc
            .data()
            .studentReadableProgress.split(",")[0]
            .match(/\d+/)
            ? doc.data().studentReadableProgress.split(",")[0].match(/\d+/)[0]
            : 0;

          studentReadableProgress.push(SRP);
          i++;
        });
      })
      .catch(function (error) {
        console.error("Error getting document:", error);
      })
      .finally(function () {
        var color = Chart.helpers.color;
        var barChartData = {
          labels: that.studentNames,
          datasets: [
            {
              label: "Progress",
              backgroundColor: "#5d78ff", //color(KTApp.getStateColor('brand')).alpha(1).rgbString(),
              borderWidth: 0,
              data: studentReadableProgress,
            },
            // {
            //   label: 'Proficiency',
            //   backgroundColor: '#F0F3FF',//color(KTApp.getBaseColor('shape', 1)).alpha(1).rgbString(),
            //   borderWidth: 0,
            //   data: that.studentsProficiency,
            // }
          ],
        };

        var ctx = document
          .getElementById("kt_chart_sales_statistics")
          .getContext("2d");
        var myBar = new Chart(ctx, {
          type: "bar",
          data: barChartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: false,
            scales: {
              xAxes: [
                {
                  categoryPercentage: 0.35,
                  barPercentage: 0.7,
                  display: true,
                  scaleLabel: {
                    display: false,
                    labelString: "Month",
                  },
                  gridLines: false,
                  ticks: {
                    display: true,
                    beginAtZero: true,
                    fontColor: "#C5CBE3", //KTApp.getBaseColor('shape', 3),
                    fontSize: 13,
                    padding: 10,
                  },
                },
              ],
              yAxes: [
                {
                  categoryPercentage: 0.35,
                  barPercentage: 0.7,
                  display: true,
                  scaleLabel: {
                    display: false,
                    labelString: "Value",
                  },
                  gridLines: {
                    color: "#C5CBE3", //KTApp.getBaseColor('shape', 2),
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineColor: "#C5CBE3", //KTApp.getBaseColor('shape', 2),
                    zeroLineBorderDash: [3, 4],
                  },
                  ticks: {
                    max: 14,
                    stepSize: 1,
                    display: true,
                    beginAtZero: true,
                    fontColor: "#C5CBE3", //KTApp.getBaseColor('shape', 3),
                    fontSize: 13,
                    padding: 10,
                  },
                },
              ],
            },
            title: {
              display: false,
            },
            hover: {
              mode: "index",
            },
            tooltips: {
              enabled: true,
              intersect: false,
              mode: "nearest",
              bodySpacing: 5,
              yPadding: 10,
              xPadding: 10,
              caretPadding: 0,
              displayColors: false,
              backgroundColor: "#5d78ff", //KTApp.getStateColor('brand'),
              titleFontColor: "#ffffff",
              cornerRadius: 4,
              footerSpacing: 0,
              titleSpacing: 0,
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 5,
                bottom: 5,
              },
            },
          },
        });
      });
  },
  mounted() {},
};
</script>

<style scoped></style>
