<template>
  <div id="videoTraining">
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="row">
        <div
          :key="key"
          v-for="(image, key) in images"
          class="col-lg-6 col-xl-4 order-lg-1 order-xl-1"
        >
          <!--begin::Portlet-->
          <div class="kt-portlet kt-portlet--height-fluid imageHolder">
            <div class="kt-portlet__head kt-portlet__head--noborder">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">{{ image.title }}</h3>
              </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
              <a :href="image.src" download target="_blank">
                <img :src="image.thumbnailSrc" class="videoImage" />
              </a>
            </div>
          </div>
          <!--end::Portlet-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "videoTraining",
  data() {
    return {
      images: [
        {
          title: "Sequencing with Success",
          thumbnailSrc: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Resources%2Fscope.png?alt=media&token=38c1439f-fe9d-4bca-92c2-96fac93864b4",
          src: "https://drive.google.com/file/d/1bjpE1Pk3hJ3U8nr5Tfu0P11MDPNdOqgZ/view?usp=sharing",
        },
        {
          title: "Help with Sequencing",
          thumbnailSrc: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Resources%2Fhelp.png?alt=media&token=2cd6c776-a0b7-4f74-b12a-15f67a145368",
          src: "https://drive.google.com/file/d/1EKwBQWm0v8aSzrCnSwed-RBMufD3oncn/view?usp=sharing",
        },
        {
          title: "Scope and Sequence 2",
          thumbnailSrc: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Resources%2Fscope%202.png?alt=media&token=f29a724f-f83c-479a-8a60-68e8e4b1576c",
          src: "https://drive.google.com/file/d/1lv0H2Vfk7_7_cfmM-8rtPpbJTY9XBzI6/view?usp=sharing",
        },
        {
          title: "IEP Goals",
          thumbnailSrc: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Resources%2FEIP.png?alt=media&token=64699769-a434-4433-9043-bd803a24d8b2",
          src: "https://drive.google.com/file/d/1JTw4ywwqE4SAFndhcNQ7NbciHaFdUNir/view?usp=sharing",
        },
        {
          title: "Games for the Foundation in Sounds Virtual Classroom",
          thumbnailSrc: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Resources%2Fgames.png?alt=media&token=557ff788-ba81-438c-8eaa-4d3c2c43d343",
          src: "https://drive.google.com/file/d/1SbvPTYz_N72Zv6fwmEijszwF0GW1zf1n/view?usp=sharing",
        },
        {
          title: "10 Objects",
          thumbnailSrc: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Resources%2F10%20obs.png?alt=media&token=9550d7f9-50d7-4ab7-82ac-90c7171c069f",
          src: "https://drive.google.com/file/d/1MV5fPPbc4S7a_Zw2CJGB-xV6lRGxXFDV/view?usp=sharing",
        },
      ],
      video: {
        title: "",
        currentIndex: "",
        url: "",
      },
      modalDataReady: false,
    };
  },
};
</script>

<style scoped>
.imageHolder {
  float: left;
  background: #edf0ff;
}

.videoImage {
  width: 100% !important;
}

video {
  width: 100%;
}

#videoTraining h3 {
  font-size: 20px;
}
</style>
