<template>
  <div class="introduction">
    <div class="row topPart">
		<div id="button" v-show="false === showImage">
      <a href="javascript:;" v-show="(false === showImage && !showSounds) && showSoundsBtnTwo" class="btn btn-primary btnMassive" @click="showSounds = true">Show Sounds</a>
      <a href="javascript:;" v-show="showSounds && showSoundsBtnTwo" class="btn btn-primary" @click="showSounds = false">Hide Sounds</a>
			<a href="javascript:;" v-show="(false === showImage) && showObjsBtnTwo" class="btn btn-primary btnMassive" @click="showImage = true">Show 10 Objects</a>
		</div>
    <div id="hiddenSounds" v-show="showSounds && showSoundsBtnTwo">
			<div class="row">
        <div id="audios">

          <div class="row">

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('bell')">
                <div class="card colorDifferent-10 card-custom gutter-b">
                  <div class="card-body">
                    <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                        </g>
                    </svg><!--end::Svg Icon-->Sound 1</span>
                  </div>
                </div>
              </a>
            </div>

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('bird')">
                <div class="card colorDifferent-9 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 2</span>
                  </div>
                </div>
              </a>
            </div>

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('carHorn')">
                <div class="card colorDifferent-8 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 3</span>
                  </div>
                </div>
              </a>
            </div>

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('clap')">
                <div class="card colorDifferent-7 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 4</span>
                  </div>
                </div>
              </a>
            </div>

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('cough')">
                <div class="card colorDifferent-6 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 5</span>
                  </div>
                </div>
              </a>
            </div>

          </div>
          <div class="row">

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('dogBark')">
                <div class="card colorDifferent-1 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 6</span>
                  </div>
                </div>
              </a>
            </div>

            <div class="soundBox">
            <a href="javascript:;" @click="playSound('doorbell')">
              <div class="card colorDifferent-2 card-custom gutter-b">
                <div class="card-body">
              <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
              </svg><!--end::Svg Icon-->Sound 7</span>
                </div>
              </div>
            </a>
            </div>

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('sneeze')">
                <div class="card colorDifferent-3 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 8</span>
                  </div>
                </div>
              </a>
            </div>

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('splash')">
                <div class="card colorDifferent-4 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 9</span>
                  </div>
                </div>
              </a>
            </div>

            <div class="soundBox">
              <a href="javascript:;" @click="playSound('thunder')">
                <div class="card colorDifferent-5 card-custom gutter-b">
                  <div class="card-body">
                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/keen/releases/2021-04-21-040700/theme/demo1/dist/../src/media/svg/icons/Media/Music.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M8,17.0400072 L8,8 C8,7.56261503 8.28424981,7.17598102 8.70172501,7.04552002 L17.701725,4.54552002 C18.3456556,4.34429171 19,4.82535976 19,5.5 L19,17 C19,17.0001911 18.9999999,17.0003822 18.9999998,17.0005733 C18.9996127,18.1048793 17.880473,19 16.5,19 C15.1192881,19 14,18.1045695 14,17 C14,15.8954305 15.1192881,15 16.5,15 C16.6712329,15 16.838445,15.0137721 17,15.0400072 L17,8 L10,9.875 L10,19 C10,19.0001911 9.99999995,19.0003822 9.99999984,19.0005733 C9.99961272,20.1048793 8.88047301,21 7.5,21 C6.11928813,21 5,20.1045695 5,19 C5,17.8954305 6.11928813,17 7.5,17 C7.67123292,17 7.83844503,17.0137721 8,17.0400072 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                </svg><!--end::Svg Icon-->Sound 10</span>
                  </div>
                </div>
              </a>
            </div>

          </div>

        </div>
	    </div>
    </div>
		<div id="hiddenImage" v-show="showImage && showObjsBtnTwo">
			<div class="row">
				<div class="progress">
					<div
					class="progress-bar progress-bar-striped progress-bar-animated"
					role="progressbar"
					id="progressTimer"
					style="width: 0%"
					aria-valuenow="0"
					aria-valuemin="0"
					aria-valuemax="100"
					></div>
				</div>
      </div>
			<img src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2Fintro.PNG?alt=media&token=4a70ed32-a7c2-4580-8793-59693d03012e"/>
		</div>
  </div>
</template>

<script>
import { Howl, Howler } from 'howler'

import bell from './assets/sounds/bell.wav'
import bird from './assets/sounds/bird.wav'
import carHorn from './assets/sounds/car-horn.wav'
import clap from './assets/sounds/clap-short.wav'
import cough from './assets/sounds/cough.wav'
import dogBark from './assets/sounds/dog-bark.wav'
import doorbell from './assets/sounds/doorbell.wav'
import sneeze from './assets/sounds/sneeze.wav'
import splash from './assets/sounds/splash.wav'
import thunder from './assets/sounds/thunder-short.mp3'

export default {
  name: 'introduction',
  props: ['showObjsBtn', 'showSoundsBtn'],
  data() {
    return {
      showImage: false,
      showSounds: false,
      showObjsBtnTwo: false,
      showSoundsBtnTwo: false,
      totalTime: 40,
      currentTime: 0,
    }
  },
  mounted() {
    const that = this

    that.$root.$on('saveClassSession', () => {
      that.saveClassSession()
    })
  },
  watch: {
    showImage: function (isImageShown) {
      const that = this

      if (isImageShown) {
        clearInterval(that.timer)
        that.currentTime = 0
        let newPercentage = 100 * (that.currentTime / that.totalTime)
        $('#progressTimer').css('width', newPercentage + '%')

        that.timer = window.setInterval(that.twoHundredFiftyMils, 250)
      }
    },
    showObjsBtn: function (newVal, oldVal) {
      const that = this

      that.showObjsBtnTwo = newVal
    },
    showSoundsBtn: function (newVal, oldVal) {
      const that = this

      that.showSoundsBtnTwo = newVal
    },
  },
  methods: {
    twoHundredFiftyMils() {
      const that = this

      that.currentTime++

      //If at the end
      if (that.currentTime === that.totalTime && true === that.showImage) {
        clearInterval(that.timer)
        that.showImage = false
      }

      let newPercentage = 100 * (that.currentTime / that.totalTime)

      $('#progressTimer').css('width', newPercentage + '%')
    },
    async saveClassSession() {
      const that = this

      localStorage.setItem('classroomInfoCurrentLesson', '')
      console.log('--setting-- currentLesson to empty')

      this.$store.dispatch('setClassroomInfoWhiteListedTiles', [])

      this.$store.dispatch('setClassroomInfoMatchingGameFinished', false)
      this.$store.dispatch('setClassroomInfoThumbsGameFinished', false)
      this.$store.dispatch('setClassroomInfoBeatTheClockFinished', false)
      this.$store.dispatch('setClassroomInfoSeqPicsFinished', false)
      this.$store.dispatch('setClassroomInfoSeqTilesFinished', false)
      this.$store.dispatch('setClassroomInfoStopLightFinished', false)
      this.$store.dispatch('setClassroomInfoPhotoCardFinished', false)
      this.$store.dispatch('setClassroomInfoWhiteListedTiles', false)
      this.$store.dispatch('setClassroomInfoWhiteListedTiles', false)
      this.$store.dispatch('setClassroomInfoWhiteListedTiles', false)
      this.$store.dispatch('setClassroomInfoWhiteListedTiles', false)
    },
    playSound(soundID) {
      let sound = ''

      if ('bell' === soundID) {
        sound = bell
      } else if ('bird' === soundID) {
        sound = bird
      } else if ('carHorn' === soundID) {
        sound = carHorn
      } else if ('clap' === soundID) {
        sound = clap
      } else if ('cough' === soundID) {
        sound = cough
      } else if ('dogBark' === soundID) {
        sound = dogBark
      } else if ('doorbell' === soundID) {
        sound = doorbell
      } else if ('sneeze' === soundID) {
        sound = sneeze
      } else if ('splash' === soundID) {
        sound = splash
      } else if ('thunder' === soundID) {
        sound = thunder
      }

      const soundHowl = new Howl({
        src: [sound],
      })

      soundHowl.play()
    },
  },
}
</script>

<style scoped>
.topPart {
  height: 300px;
}

.btnMassive {
  padding: 30px;
  font-size: 16px;
}

#button {
  margin: auto;
}

.progress {
  margin-bottom: 30px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

#hiddenSounds .row {
  margin: auto;
  margin-bottom: 10px;
}

#audios {
  display: contents;
}

.soundBox {
  margin-right: 10px;
  width: 125px;
}

figcaption {
  color: black;
  font-size: 15px;
  padding: 5px;
}

#hiddenImage {
  margin: auto;
}

.card {
  border: 0;
}

.colorDifferent-1 {
  background: #c2e9fb 100%;
}
.colorDifferent-2 {
  background: #96e6a1 100%;
}
.colorDifferent-3 {
  background: #8fd3f4 100%;
}
.colorDifferent-4 {
  background: #e2ebf0 100%;
}
.colorDifferent-5 {
  background: #f68084 100%;
}
.colorDifferent-6 {
  background: #d57eeb 100%;
}
.colorDifferent-7 {
  background: #8ec5fc 100%;
}
.colorDifferent-8 {
  background: #f5576c 100%;
}
.colorDifferent-9 {
  background: #fddb92 100%;
}
.colorDifferent-10 {
  background: #38f9d7 100%;
}
</style>
