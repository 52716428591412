<template>
  <div id="studentGlance">
    <!--begin::Portlet-->
    <div class="kt-portlet kt-portlet--height-fluid">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3
            v-if="!$store.getters.getStudentGlanceYearGlance"
            class="kt-portlet__head-title"
          >
            Week at A Glance
          </h3>
          <h3
            v-if="$store.getters.getStudentGlanceYearGlance"
            class="kt-portlet__head-title"
          >
            Year at A Glance
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          <div class="kt-portlet__head-toolbar-wrapper">
            <div class="dropdown dropdown-inline">
              <button
                v-if="!$store.getters.getStudentGlanceViewingAllStudents"
                @click="resetToViewAllStudents()"
                button
                type="button"
                class="btn btn-default btn-font-sm btn-bold btn-upper"
                aria-expanded="false"
              >
                View All Students
              </button>
              <button
                v-if="$store.getters.getStudentGlanceYearGlance"
                @click="toggleGlance('week')"
                type="button"
                class="btn btn-default btn-font-sm btn-bold btn-upper"
                aria-expanded="false"
              >
                Show Week
              </button>
              <button
                v-if="!$store.getters.getStudentGlanceYearGlance"
                @click="toggleGlance('year')"
                type="button"
                class="btn btn-default btn-font-sm btn-bold btn-upper"
                aria-expanded="false"
              >
                Show Year
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="kt-portlet__body kt-portlet__body--fluid">
        <div class="kt-widget-9">
          <div class="kt-widget-9__panel">
            <div class="kt-widget-9__legends">
              <div class="kt-widget-9__legend">
                <div class="kt-widget-9__legend-bullet kt-bg-brand"></div>
                <div
                  class="kt-widget-9__legend-label"
                  style="font-size: 14px; color: #5d78ff"
                >
                  Minutes
                </div>
              </div>
            </div>
            <div class="kt-widget-9__toolbar">
              <div class="dropdown dropdown-inline">
                {{ $store.getters.getStudentGlanceTitle }}
                <div class="small text-center">
                  {{ beginningDateTitle }} - {{ endDateTitle }}
                </div>
              </div>
            </div>
            <div class="kt-widget-9__toolbar">
              <div class="dropdown dropdown-inline">
                <button
                  @click="prevDateSet()"
                  type="button"
                  class="btn btn-brand btn-sm btn-icon"
                >
                  <i class="fa fa-angle-left"></i>
                </button>
                <button
                  @click="nextDateSet()"
                  type="button"
                  class="btn btn-brand btn-sm btn-icon"
                >
                  <i class="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="kt-widget-9__chart">
            <div class="chartjs-size-monitor">
              <div class="chartjs-size-monitor-expand">
                <div class></div>
              </div>
              <div class="chartjs-size-monitor-shrink">
                <div class></div>
              </div>
            </div>
            <div id="glanceContainer">
              <canvas
                id="kt_chart_revenue_growth"
                height="375"
                width="937"
                class="chartjs-render-monitor"
                style="display: block; height: 300px; width: 750px"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Portlet-->
  </div>
</template>

<script>
// import db from './firebaseDB'

export default {
  name: "studentGlance",
  data() {
    return {
      beginningDateTitle: "",
      endDateTitle: "",
      allStudents: [],
      studentIds: [],
      dateBeingViewed: "",
      data: [],
    };
  },
  created() {
    const that = this;

    that.$store.dispatch("setStudentGlanceStudentIds", []);
    that.$store.dispatch("setStudentGlanceTitle", "Total Student Minutes");
    that.$store.dispatch("setStudentGlanceViewingAllStudents", true);
    that.$store.dispatch("setStudentGlanceYearGlance", false);

    var docRef = db
      .collection("users")
      .doc(localStorage.getItem('userID'))
      .collection("students")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          that.allStudents.push(doc.data().studentFullName);
          that.studentIds.push(doc.data().myUid);
        });
      })
      .catch(function (error) {
        console.error("Error getting document:", error);
      })
      .finally(function () {
        var date = moment();
        that.$store.dispatch("setStudentGlanceStudentIds", that.studentIds);

        that.getDataAndSetChart(date, "week");
      });
  },
  mounted() {},
  methods: {
    mountChart(lables) {
      $("#kt_chart_revenue_growth").remove();
      $("#glanceContainer").append(
        '<canvas id="kt_chart_revenue_growth" height="375" width="937" class="chartjs-render-monitor" style="display: block; height: 300px; width: 750px;"></canvas>'
      );

      var max =
        true === this.$store.getters.getStudentGlanceYearGlance ? 100 : 120;
      var stepSize =
        true === this.$store.getters.getStudentGlanceYearGlance ? 10 : 10;

      var color = Chart.helpers.color;
      var barChartData = {
        labels: lables,
        datasets: [
          {
            label: "Minutes",
            backgroundColor: "#5d78ff",
            borderWidth: 0,
            data: this.data,
            borderColor: "#5d78ff",
            borderWidth: 3,
            backgroundColor: "rgb(93, 120, 255, 0.07)",
            fill: true,
          },
        ],
      };

      var ctx = document
        .getElementById("kt_chart_revenue_growth")
        .getContext("2d");
      var myBar = new Chart(ctx, {
        type: "bar",
        data: barChartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: false,
          scales: {
            xAxes: [
              {
                categoryPercentage: 0.35,
                barPercentage: 0.7,
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Month",
                },
                gridLines: false,
                ticks: {
                  display: true,
                  beginAtZero: true,
                  fontColor: "#C5CBE3",
                  fontSize: 13,
                  padding: 10,
                },
              },
            ],
            yAxes: [
              {
                categoryPercentage: 0.35,
                barPercentage: 0.7,
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                },
                gridLines: {
                  color: "#C5CBE3",
                  drawBorder: false,
                  offsetGridLines: false,
                  drawTicks: false,
                  borderDash: [3, 4],
                  zeroLineWidth: 1,
                  zeroLineColor: "#C5CBE3",
                  zeroLineBorderDash: [3, 4],
                },
                ticks: {
                  max: max,
                  stepSize: stepSize,
                  display: true,
                  beginAtZero: true,
                  fontColor: "#C5CBE3",
                  fontSize: 13,
                  padding: 10,
                },
              },
            ],
          },
          title: {
            display: false,
          },
          hover: {
            mode: "index",
          },
          elements: {
            line: {
              tension: 0.5,
            },
            point: {
              radius: 0,
            },
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: "#5d78ff",
            titleFontColor: "#ffffff",
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 5,
              bottom: 5,
            },
          },
        },
      }); //chart obj
    },
    getDataAndSetChart(date, type) {
      var that = this;

      that.data =
        "week" === type
          ? [0, 0, 0, 0, 0, 0, 0]
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if ("week" === type) {
        var sunday = moment(date).weekday(0).startOf("day");
        var trueSunday = moment(date).weekday(0).startOf("day");
        var monday = moment(date).weekday(1).startOf("day");
        var tuesday = moment(date).weekday(2).startOf("day");
        var wednesday = moment(date).weekday(3).startOf("day");
        var thursday = moment(date).weekday(4).startOf("day");
        var friday = moment(date).weekday(5).startOf("day");
        var saturday = moment(date).weekday(6).startOf("day");
        var saturdayNight = sunday.endOf("day").weekday(6);

        that.beginningDateTitle = trueSunday.format("ddd, MMM Do");
        that.endDateTitle = sunday.format("ddd, MMM Do");

        for (
          let i = 0;
          i < that.$store.getters.getStudentGlanceStudentIds.length;
          i++
        ) {
          db.collection("users")
            .doc(that.$store.getters.getStudentGlanceStudentIds[i])
            .collection("classes")
            .get()
            .then((querySnapshotTwo) => {
              querySnapshotTwo.forEach((doc) => {
                let classDate = moment(doc.id, "DD-MM-YYYY");

                if (classDate.isBetween(sunday, monday, undefined, "[)")) {
                  that.data[0] = that.data[0] + doc.data().minutesForToday;
                } else if (
                  classDate.isBetween(monday, tuesday, undefined, "[)")
                ) {
                  that.data[1] = that.data[1] + doc.data().minutesForToday;
                } else if (
                  classDate.isBetween(tuesday, wednesday, undefined, "[)")
                ) {
                  that.data[2] = that.data[2] + doc.data().minutesForToday;
                } else if (
                  classDate.isBetween(wednesday, thursday, undefined, "[)")
                ) {
                  that.data[3] = that.data[3] + doc.data().minutesForToday;
                } else if (
                  classDate.isBetween(thursday, friday, undefined, "[)")
                ) {
                  that.data[4] = that.data[4] + doc.data().minutesForToday;
                } else if (
                  classDate.isBetween(friday, saturday, undefined, "[)")
                ) {
                  that.data[5] = that.data[5] + doc.data().minutesForToday;
                } else if (
                  classDate.isBetween(saturday, saturdayNight, undefined, "[)")
                ) {
                  that.data[6] = that.data[6] + doc.data().minutesForToday;
                }
              });
            })
            .catch(function (error) {
              console.error("Error getting document:", error);
            })
            .finally(function () {
              if (
                that.$store.getters.getStudentGlanceStudentIds.length - 1 ==
                i
              ) {
                //end of students loop
                let weekDayLables = [
                  "Sun " + trueSunday.format("M/D"),
                  "Mon " + monday.format("M/D"),
                  "Tue " + tuesday.format("M/D"),
                  "Wed " + wednesday.format("M/D"),
                  "Thu " + thursday.format("M/D"),
                  "Fri " + friday.format("M/D"),
                  "Sat " + saturday.format("M/D"),
                ];
                that.mountChart(weekDayLables, that.data);
              }
            }); //finally
        } //for loop
      } else if ("year" === type) {
        //year
        var january = moment(date).month(0).startOf("month");
        var febuary = moment(date).month(1).startOf("month");
        var march = moment(date).month(2).startOf("month");
        var april = moment(date).month(3).startOf("month");
        var may = moment(date).month(4).startOf("month");
        var june = moment(date).month(5).startOf("month");
        var july = moment(date).month(6).startOf("month");
        var augest = moment(date).month(7).startOf("month");
        var september = moment(date).month(8).startOf("month");
        var october = moment(date).month(9).startOf("month");
        var november = moment(date).month(10).startOf("month");
        var december = moment(date).month(11).startOf("month");
        var decemberEnd = december.endOf("month");

        that.beginningDateTitle = january.format("Do, MMM YYYY");
        that.endDateTitle = decemberEnd.format("Do, MMM YYYY");

        for (
          let i = 0;
          i < that.$store.getters.getStudentGlanceStudentIds.length;
          i++
        ) {
          db.collection("users")
            .doc(that.$store.getters.getStudentGlanceStudentIds[i])
            .collection("classes")
            .get()
            .then((querySnapshotTwo) => {
              querySnapshotTwo.forEach((doc) => {
                let classDate = moment(doc.id, "DD-MM-YYYY");

                if (classDate.isBetween(january, febuary, undefined, "[]")) {
                  that.data[0] = parseFloat(
                    parseFloat(that.data[0]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (
                  classDate.isBetween(febuary, march, undefined, "[]")
                ) {
                  that.data[1] = parseFloat(
                    parseFloat(that.data[1]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (classDate.isBetween(march, april, undefined, "[]")) {
                  that.data[2] = parseFloat(
                    parseFloat(that.data[2]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (classDate.isBetween(april, may, undefined, "[]")) {
                  that.data[3] = parseFloat(
                    parseFloat(that.data[3]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (classDate.isBetween(may, june, undefined, "[]")) {
                  that.data[4] = parseFloat(
                    parseFloat(that.data[4]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (classDate.isBetween(june, july, undefined, "[]")) {
                  that.data[5] = parseFloat(
                    parseFloat(that.data[5]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (classDate.isBetween(july, augest, undefined, "[]")) {
                  that.data[6] = parseFloat(
                    parseFloat(that.data[6]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (
                  classDate.isBetween(augest, september, undefined, "[]")
                ) {
                  that.data[7] = parseFloat(
                    parseFloat(that.data[7]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (
                  classDate.isBetween(september, october, undefined, "[]")
                ) {
                  that.data[8] = parseFloat(
                    parseFloat(that.data[8]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (
                  classDate.isBetween(october, november, undefined, "[]")
                ) {
                  that.data[9] = parseFloat(
                    parseFloat(that.data[9]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (
                  classDate.isBetween(november, december, undefined, "[]")
                ) {
                  that.data[10] = parseFloat(
                    parseFloat(that.data[10]) + doc.data().minutesForToday
                  ).toFixed(2);
                } else if (
                  classDate.isBetween(december, decemberEnd, undefined, "[]")
                ) {
                  that.data[11] = parseFloat(
                    parseFloat(that.data[11]) + doc.data().minutesForToday
                  ).toFixed(2);
                }
              });
            })
            .catch(function (error) {
              console.error("Error getting document:", error);
            })
            .finally(function () {
              if (
                that.$store.getters.getStudentGlanceStudentIds.length - 1 ==
                i
              ) {
                //end of students loop
                let monthlyLables = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                that.mountChart(monthlyLables, that.data);
              }
            }); //finally
        } //for loop
      }
    },
    prevDateSet() {
      let date;

      if (true === this.$store.getters.getStudentGlanceYearGlance) {
        if ("" == this.dateBeingViewed) {
          //if empty then we're viewing this year
          date = moment().subtract(1, "years");
        } else {
          date = moment(this.dateBeingViewed).subtract(1, "years");
        }
        this.dateBeingViewed = moment(date);
        this.getDataAndSetChart(date, "year");
      } else {
        if ("" == this.dateBeingViewed) {
          //if empty then we're viewing this week
          date = moment().subtract(7, "days");
        } else {
          date = moment(this.dateBeingViewed).subtract(7, "days");
        }
        this.dateBeingViewed = moment(date);
        this.getDataAndSetChart(date, "week");
      }
    },
    nextDateSet() {
      let date;

      if (true === this.$store.getters.getStudentGlanceYearGlance) {
        if ("" == this.dateBeingViewed) {
          date = moment().add(1, "years");
        } else {
          date = moment(this.dateBeingViewed).add(1, "years");
        }
        this.dateBeingViewed = moment(date);
        this.getDataAndSetChart(date, "year");
      } else {
        if ("" == this.dateBeingViewed) {
          date = moment().add(7, "days");
        } else {
          date = moment(this.dateBeingViewed).add(7, "days");
        }
        this.dateBeingViewed = moment(date);
        this.getDataAndSetChart(date, "week");
      }
    },
    resetToViewAllStudents() {
      this.$store.dispatch("setStudentGlanceStudentIds", this.studentIds);
      this.$store.dispatch("setStudentGlanceTitle", "Total Student Minutes");
      this.$store.dispatch("setStudentGlanceViewingAllStudents", true);
    },
    toggleGlance(type) {
      if ("week" === type) {
        this.$store.dispatch("setStudentGlanceYearGlance", false);
        //this.$store.dispatch("setStudentGlanceStudentIds", this.studentIds);
        // this.$store.dispatch("setStudentGlanceTitle", "Total Student Minutes");
        // this.$store.dispatch("setStudentGlanceViewingAllStudents", true);
      } else if ("year" === type) {
        this.$store.dispatch("setStudentGlanceYearGlance", true);
      }
    },
  },
  computed: {
    diffStudentClick() {
      return this.$store.getters.getStudentGlanceDiffStudentClick;
    },
    yearGlanceBool() {
      return this.$store.getters.getStudentGlanceYearGlance;
    },
  },
  watch: {
    diffStudentClick(newCount, oldCount) {
      var date = moment();

      if (true === this.$store.getters.getStudentGlanceYearGlance) {
        this.getDataAndSetChart(date, "year");
      } else {
        this.getDataAndSetChart(date, "week");
      }
    },
    yearGlanceBool(newCount, oldCount) {
      var date = moment();

      if (true === newCount) {
        this.getDataAndSetChart(date, "year");
      } else {
        this.getDataAndSetChart(date, "week");
      }
    },
  },
};
</script>

<style scoped></style>
