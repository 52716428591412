<template>
  <div id="studentProfile">
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="row">
        <div class="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
          <!-- Portlet Start -->
          <div
            class="kt-portlet kt-widget-15"
            style="border: dashed #ffffff; background-color: #57baab"
          >
            <div class="kt-portlet__body">
              <!-- Doc: The bootstrap carousel is a slideshow for cycling through a series of content, see https://getbootstrap.com/docs/4.1/components/carousel/ -->
              <div
                id="kt-widget-slider-14-2"
                class="kt-slider carousel slide"
                data-ride="carousel"
                data-interval="8000"
              >
                <div class="kt-slider__head">
                  <div class="kt-slider__label"></div>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="kt-widget-15__body">
                      <div class="kt-widget-15__author">
                        <!-- <div class="kt-widget-15__photo">
                          <a href="javascript:;">
                            <img src="./assets/media/users/default.jpg" alt title />
                          </a>
                        </div> -->
                        <div class="kt-widget-15__detail">
                          <h4>Student information:</h4>
                          <br />
                          <h5>{{ studentInfo.name | capitalize }}</h5>
                          <div class="kt-widget-15__desc">
                            <br />
                            Birthday: {{ studentInfo.DOB }}
                            <br />
                            Join Date: {{ dateJoined }}
                          </div>
                        </div>
                      </div>
                      <ul class="kt-widget-15__wrapper ulBlock">
                        <h4>Parent information:</h4>
                        <br />
                        <li v-show="parentInfo.name">
                          <a
                            href="javascript:;"
                            class="btn btn-icon btn-sm btn-circle btn-brand"
                          >
                            <i class="fa fa-address-card"></i>
                          </a>
                          <a class="kt-widget-15__info--item">{{
                            parentInfo.name | capitalize
                          }}</a>
                        </li>
                        <li v-show="parentInfo.email">
                          <a
                            href="javascript:;"
                            class="btn btn-icon btn-sm btn-circle btn-brand"
                          >
                            <i class="fa fa-envelope"></i>
                          </a>
                          <a
                            :href="parentInfo.mailTo"
                            class="kt-widget-15__info--item"
                            >{{ parentInfo.email }}</a
                          >
                        </li>
                        <li v-show="parentInfo.phone">
                          <a
                            href="javascript:;"
                            class="btn btn-icon btn-sm btn-circle btn-brand"
                          >
                            <i class="fa fa-phone"></i>
                          </a>
                          <a
                            :href="'tel:' + parentInfo.phone"
                            class="kt-widget-15__info--item"
                            >{{ parentInfo.phone }}</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:;"
                            data-toggle="modal"
                            data-target="#updateProfileModal"
                            class="btn btn-info"
                            id="editButton"
                            >Edit</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:;"
                            data-toggle="modal"
                            data-target="#deleteProfileModal"
                            class="btn btn-danger"
                            style="color: white !important"
                            id="deleteButton"
                            >Delete</a
                          >
                        </li>
                      </ul>
                      <div class="leftBlock">
                        <h4>Scoring information:</h4>
                        <br />
                        <h6>
                          <a
                            id="screenScoresLink"
                            href="https://bartonreading.com/students/#ss"
                            target="_blank"
                            >Barton student pre-screening scores ➜</a
                          >
                        </h6>

                        <div
                          class="kt-widget-15__foot-label btn btn-sm btn-label-brand btn-bold"
                          style="
                            text-align: left;
                            margin-bottom: 10px;
                            color: white;
                          "
                        >
                          Pre Screening Scores: <br />
                          Correct: {{ studentInfo.pre.initialScore }}<br />
                          Correct After Repeat: {{ studentInfo.pre.repeatScore
                          }}<br />
                          Incorrect: {{ studentInfo.pre.inCorrectScore }}
                        </div>
                        <div id="clickableBox">
                          <a
                            href="javascript:;"
                            data-toggle="modal"
                            data-target="#updatePostScoreModal"
                            ><div
                              id="postScoresClick"
                              style="color: white"
                              title="Click to edit post scores"
                              class="kt-widget-15__foot-label btn btn-sm btn-label-brand btn-bold"
                            >
                              Post Screening Scores:<br />
                              Correct: {{ studentInfo.post.initialScore }}<br />
                              Correct After Repeat:
                              {{ studentInfo.post.repeatScore }}<br />
                              Incorrect: {{ studentInfo.post.inCorrectScore }}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="kt-widget-15__foot">
                      <div class="kt-widget-15__foot-info">
                        <div
                          class="kt-widget-15__foot-label btn btn-sm btn-label-brand btn-bold"
                        >Join Date: {{dateJoined}}</div>
                        <div class="kt-widget-15__foot-desc"></div>
                      </div>
                      <div class="kt-widget-15__foot-toolbar">

                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Portlet End -->
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
          <!--begin::Portlet-->
          <div
            class="kt-portlet kt-portlet--height-fluid"
            style="border: #edf0ff; border-style: dashed"
          >
            <div class="kt-portlet__head kt-portlet__head--noborder">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                  Course Progress:
                  <b>{{ studentInfo.studentProgress }}%</b> Completed
                </h3>
              </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
              <!--Doc: For the chart initialization refer to "widgetTechnologiesChart" function in "src\theme\app\scripts\custom\dashboard.js" -->
              <canvas
                id="kt_widget_technologies_chart_progress"
                style="height: 150px; width: 150px; display: block"
                width="225"
                height="225"
              ></canvas>
            </div>
          </div>
          <!--end::Portlet-->
        </div>

        <div class="col-lg-6 col-xl-6 order-lg-1 order-xl-1">
          <!--begin::Portlet-->
          <div
            class="kt-portlet kt-portlet--height-fluid"
            style="border: #edf0ff; border-style: dashed"
          >
            <div class="kt-portlet__head kt-portlet__head--noborder">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                  {{ studentInfo.studentReadableProgress }}:
                  <b>{{ studentInfo.latestLessionPercentageComplete }}%</b>
                  Completed
                </h3>
              </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
              <!--Doc: For the chart initialization refer to "widgetTechnologiesChart" function in "src\theme\app\scripts\custom\dashboard.js" -->
              <canvas
                id="kt_widget_technologies_chart_lession_progress"
                style="height: 150px; width: 150px; display: block"
                width="225"
                height="225"
              ></canvas>
            </div>
          </div>
          <!--end::Portlet-->
        </div>

        <!-- <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1">
          <div
            class="kt-portlet kt-portlet--height-fluid"
            style="border: #edf0ff; border-style: dashed"
          >
            <div class="kt-portlet__head kt-portlet__head--noborder">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                  Overall Proficiency:
                  <b>{{ studentInfo.averageProficiency }}%</b>
                </h3>
              </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
              <canvas
                id="kt_widget_technologies_chart_proficiency"
                style="height: 150px; width: 150px; display: block"
                width="225"
                height="225"
              ></canvas>
            </div>
          </div>
        </div> -->
      </div>

      <div class="row">
        <div class="col-lg-12 col-xl-12 order-lg-12 order-xl-12">
          <!--begin::Portlet-->
          <div
            class="kt-portlet kt-portlet--height-fluid"
            style="border: #edf0ff; border-style: dashed"
          >
            <div class="kt-portlet__head kt-portlet__head--noborder">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Notes</h3>
              </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
              <div class="col-lg-12 col-xl-12 order-lg-12 order-xl-12">
                <div class="row">
                  <textarea
                    v-model="newNote"
                    placeholder="Add student notes here"
                    id="noteTextArea"
                  ></textarea>
                </div>
                <div class="row">
                  <a
                    href="javascript:;"
                    class="btn btn-info"
                    :class="{ disabled: !newNote || disabledSubmitBtn }"
                    id="noteSubmitButton"
                    @click="addNote()"
                    >Add note</a
                  >
                </div>
                <div class="row" v-for="(noteObj, key) in notes" :key="key">
                  <div
                    class="cutomCardDate col-lg-1 col-xl-1 order-lg-1 order-xl-1"
                  >
                    {{ noteObj.date }}
                  </div>
                  <div
                    class="cutomCardDate col-lg-1 col-xl-1 order-lg-1 order-xl-1"
                  >
                    <div class="noteButtons">
                      <a href="javascript:;" class="" @click="openEditModal(noteObj)"
                        >Edit</a
                      >
                    </div>
                    <br />
                    <div class="noteButtons">
                      <a href="javascript:;" class="" @click="deleteNote(noteObj.uuid)"
                        >Delete</a
                      >
                    </div>
                  </div>
                  <div
                    class="customCard col-lg-10 col-xl-10 order-lg-10 order-xl-10"
                  >
                    {{ noteObj.note }}
                  </div>
                  <hr />
                </div>
              </div>
            </div>
            <!--end::Portlet-->
          </div>
        </div>
      </div>

      <!-- modal updatePostScoreModal -->
      <div
        class="modal fade"
        id="updatePostScoreModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="updatePostScoreModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="updatePostScoreModalCenterTitle">
                Update Post Scores
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div
                class="alert alert-success"
                role="alert"
                v-if="'' !== this.modal.infoSuccess"
              >
                {{ modal.infoSuccess }}
              </div>
              <div
                class="alert alert-danger"
                role="alert"
                v-if="'' !== this.modal.infoDanger"
              >
                {{ modal.infoDanger }}
              </div>
              <div class="kt-portlet">
                <div class="kt-portlet__body">
                  <fieldset>
                    <div class="control-group">
                      <div class="row">
                        <br />
                        <label>Post Screening Initial Score</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="postInitialScore"
                          @input="checkPostScore()"
                          v-bind:class="{ 'is-invalid': postScoresInvalid }"
                          data-kwimpalastatus="alive"
                        />
                        <br />

                        <label>Post Screening Repeat Score</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="postRepeatScore"
                          @input="checkPostScore()"
                          v-bind:class="{ 'is-invalid': postScoresInvalid }"
                          data-kwimpalastatus="alive"
                        />
                        <br />

                        <label>Post Screening Incorrect Score</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model.number="postInCorrectScore"
                          @input="checkPostScore()"
                          v-bind:class="{ 'is-invalid': postScoresInvalid }"
                          data-kwimpalastatus="alive"
                        />
                        <br />
                      </div>
                    </div>
                  </fieldset>
                </div>
                <!--end::Portlet-->
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-brand"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-brand"
                  @click="updatePostScore"
                  :disabled="postScoresInvalid"
                >
                  Update Scores
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end modal updatePostScoreModal -->

      <!-- modal updateProfileModal -->
      <div
        class="modal fade"
        id="updateProfileModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="updateProfileModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="newStudentModalCenterTitle">
                <i class="kt-menu__link-icon flaticon-user-add"></i> Edit
                Student
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="kt-form">
                <label>Student's Full Name</label>
                <input
                  v-model="editInfo.studentFullName"
                  class="form-control"
                  v-bind:class="{ 'is-invalid': !editInfo.studentFullName }"
                  type="text"
                  placeholder="John Smith"
                />
                <br />

                <label>Student's Birthday</label>
                <input
                  v-model="editInfo.studentDOB"
                  v-bind:class="{ 'is-invalid': !editInfo.studentDOB }"
                  class="form-control"
                  type="date"
                  value="2010-01-25"
                />
                <br />

                <!-- <label>Gender</label>
                <select
                  v-model="editInfo.studentGender"
                  v-bind:class="{ 'is-invalid': !editInfo.studentGender }"
                  class="form-control"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select> -->
                <br />

                <div>
                  <hr />
                  <h3>Input Barton Student Screening (Part C) scores</h3>
                  <label>Opt out of Barton Student Screening</label><br />
                  <span class="kt-switch kt-switch--icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="editInfo.ignoreBarton"
                        checked="checked"
                        name
                      />
                      <span></span>
                    </label>
                  </span>
                </div>

                <div v-show="!editInfo.ignoreBarton">
                  <h6>
                    If your student has not yet taken the Barton student
                    screening and you desire to do so, click on the link below.
                  </h6>
                  <label
                    ><a
                      href="https://bartonreading.com/students/#ss"
                      target="_blank"
                      >Link to Barton sudent screening ➜</a
                    ></label
                  >
                  <br />
                  <label>1-Prescreening Part C, Column 1: Number Correct</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="editInfo.initialScore"
                    @input="checkScore()"
                    v-bind:class="{ 'is-invalid': scoreInvalid }"
                    data-kwimpalastatus="alive"
                  />
                  <br />

                  <label>2-Prescreening Part C, Column 2: Number Correct with Repeat</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="editInfo.repeatScore"
                    @input="checkScore()"
                    v-bind:class="{ 'is-invalid': scoreInvalid }"
                    data-kwimpalastatus="alive"
                  />
                  <br />

                  <label>3-Prescreening Part C, Column 3: Number Incorrect</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="editInfo.inCorrectScore"
                    @input="checkScore()"
                    v-bind:class="{ 'is-invalid': scoreInvalid }"
                    data-kwimpalastatus="alive"
                  />
                  <br />
                </div>

                <label>Parent's Full Name</label>
                <input
                  v-model="editInfo.parentFullName"
                  class="form-control"
                  type="text"
                  placeholder="Sara Smith"
                />
                <br />

                <label>Parent's Phone:</label>
                <input
                  v-model="editInfo.parentPhone"
                  class="form-control"
                  type="tel"
                  placeholder="1 (555) 555-5555"
                />
                <br />

                <label>Parent's Email</label>
                <input
                  v-model="editInfo.parentEmail"
                  class="form-control"
                  type="email"
                  placeholder="student-email@email.com"
                />

                <!-- <span class="form-text text-muted">*This cannot be the same email as another account.</span> -->
                <br />
              </form>
              <br />

              <h4 v-if="editInfo.message" class="text-center">
                {{ editInfo.message }}
              </h4>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-brand"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-brand"
                @click="edit()"
                v-bind:class="{ disabled: editInfo.isSubmitting }"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end modal updateProfileModal -->

      <!-- modal deleteProfileModal -->
      <div
        class="modal fade"
        id="deleteProfileModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteProfileModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="newStudentModalCenterTitle">
                <i class="kt-menu__link-icon flaticon-user-add"></i> DELETE
                Student
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h3>Are you sure that you wish to delete this student?</h3>
              <br />
              <h4 style="color: #646c9a !important">This cannot be undone.</h4>
              <br />

              <h4 v-if="deleteInfo.message" class="text-center">
                {{ deleteInfo.message }}
              </h4>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-brand"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="deleteStudent()"
                v-bind:class="{ disabled: deleteInfo.isSubmitting }"
              >
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- end modal deleteProfileModal -->

      <!-- begin modal editNoteModal -->

      <div
        class="modal fade"
        id="editNoteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editNoteModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editNoteModalCenterTitle">
                Update Post Scores
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div
                class="alert alert-success"
                role="alert"
                v-if="'' !== this.modal.infoSuccess"
              >
                {{ modal.infoSuccess }}
              </div>
              <div
                class="alert alert-danger"
                role="alert"
                v-if="'' !== this.modal.infoDanger"
              >
                {{ modal.infoDanger }}
              </div>
              <div class="kt-portlet">
                <div class="kt-portlet__body">
                  <fieldset>
                    <div class="control-group">
                      <div class="row">
                        <br />

                        <textarea
                          class="form-control"
                          rows="3"
                          v-model="editNoteText"
                        ></textarea>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <!--end::Portlet-->
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-brand"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-brand"
                  @click="editNote(editNoteID)"
                >
                  Update Note
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- end modal editNoteModal -->
    </div>
  </div>
</template>

<script>
// import db from './firebaseDB'
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default {
  name: "studentProfile",
  data() {
    return {
      modal: {
        infoDanger: "",
        infoSuccess: "",
      },
      parentInfo: {
        email: "",
        phone: "",
        name: "",
        mailTo: "",
      },
      studentInfo: {
        DOB: "",
        gender: "",
        name: "",
        latestLessionPercentageComplete: 0,
        studentReadableProgress: "",
        averageProficiency: 0,
        pre: {
          initialScore: "0",
          repeatScore: "0",
          inCorrectScore: "0",
        },
        post: {
          initialScore: "0",
          repeatScore: "0",
          inCorrectScore: "0",
        },
      },
      editInfo: {
        studentFullName: "",
        studentDOB: "",
        initialScore: 0,
        repeatScore: 0,
        inCorrectScore: 0,
        parentFullName: "",
        parentPhone: "",
        parentEmail: "",
        message: false,
        isSubmitting: false,
        ignoreBarton: false,
      },
      deleteInfo: {
        message: false,
        isSubmitting: false,
      },
      postInCorrectScore: 0,
      postInitialScore: 0,
      postRepeatScore: 0,
      dateJoined: "",
      scoreInvalid: true,
      postScoresInvalid: true,
      newNote: "",
      notes: [],
      disabledSubmitBtn: false,
      editNoteText: "",
      editNoteID: "",
    };
  },
  async created() {},
  async mounted() {
    const that = this;
    const studentUid =
      this.$route.query.studentID ||
      localStorage.getItem("classroomInfoStudentID");

    const querySnapshot = await db
      .collection("users")
      .doc(localStorage.getItem("userID"))
      .collection("students")
      .doc(studentUid)
      .get()
      .catch(function (error) {
        console.error("Error getting document:", error);
      });

    that.parentInfo.name = querySnapshot.data().parentFullName;
    that.parentInfo.phone = querySnapshot.data().parentPhone;
    that.parentInfo.email = querySnapshot.data().parentEmail;
    that.parentInfo.mailTo = "mailto:" + querySnapshot.data().parentEmail;

    that.studentInfo.name = querySnapshot.data().studentFullName;
    that.studentInfo.DOB = moment(querySnapshot.data().studentDOB).format(
      "MMM Do YYYY"
    );
    that.studentInfo.studentReadableProgress = querySnapshot
      .data()
      .studentReadableProgress.split(",")[0];
    that.studentInfo.averageProficiency = isNaN(
      querySnapshot.data().averageProficiency
    )
      ? 0
      : querySnapshot.data().averageProficiency;
    that.studentInfo.latestLessionPercentageComplete =
      querySnapshot.data().currentLessonProgress;
    that.studentInfo.studentProgress = querySnapshot.data().studentProgress;

    that.studentInfo.pre.initialScore = querySnapshot.data().initialScore;
    that.studentInfo.pre.repeatScore = querySnapshot.data().repeatScore;
    that.studentInfo.pre.inCorrectScore = querySnapshot.data().inCorrectScore;

    that.studentInfo.post.initialScore = querySnapshot.data().postInitialScore;
    that.studentInfo.post.repeatScore = querySnapshot.data().postRepeatScore;
    that.studentInfo.post.inCorrectScore =
      querySnapshot.data().postInCorrectScore;

    // Modal prefill
    that.editInfo.studentFullName = that.studentInfo.name;
    that.editInfo.studentDOB = that.studentInfo.DOB;
    that.editInfo.initialScore = that.studentInfo.pre.initialScore;
    that.editInfo.repeatScore = that.studentInfo.pre.repeatScore;
    that.editInfo.inCorrectScore = that.studentInfo.pre.inCorrectScore;
    that.editInfo.parentFullName = that.parentInfo.name;
    that.editInfo.parentPhone = that.parentInfo.phone;
    that.editInfo.parentEmail = that.parentInfo.email;
    that.editInfo.ignoreBarton = !!querySnapshot.data().ignoreBarton;

    console.log(that.editInfo.ignoreBarton);

    that.dateJoined = moment(querySnapshot.data().dateCreated, "X").format(
      "MMM D, YYYY"
    );

    // checkScore
    that.checkScore();

    var config = {
      type: "doughnut",

      data: {
        datasets: [
          {
            data: [
              querySnapshot.data().studentProgress,
              100 - querySnapshot.data().studentProgress,
            ],
            backgroundColor: ["#646c9a", "#ffffff"],
            hoverBorderWidth: 0,
            borderWidth: 0,
            //hoverBorderColor: '#FDFDFD',
            //hoverBackgroundColor: '#FDFDFD',
            //borderColor: '#546c9a',
            //weight: 50,
          },
        ],
        labels: ["Course Progress", "Todo"],
      },
      options: {
        hover: { mode: null },
        cutoutPercentage: 75,
        responsive: true,
        circumference: Math.PI,
        rotation: Math.PI,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          text: "test",
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: "#5d78ff",
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0,
          filter: function (tooltipItem, data) {
            var label = data.labels[tooltipItem.index];
            if (label == "Todo") {
              return false;
            } else {
              return true;
            }
          },
        },
      },
    };

    // console.log(document.getElementById("kt_widget_technologies_chart_progress"));

    var ctx = document
      .getElementById("kt_widget_technologies_chart_progress")
      .getContext("2d");
    var myDoughnut = new Chart(ctx, config);

    // console.log(ctx);

    var config = {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [
              that.studentInfo.latestLessionPercentageComplete,
              100 - that.studentInfo.latestLessionPercentageComplete,
            ],
            backgroundColor: ["#5d78ff", "#ffffff"],
            hoverBorderWidth: 0,
            borderWidth: 0,
          },
        ],
        labels: ["Lesson Progress", "Todo"],
      },
      options: {
        hover: { mode: null },
        cutoutPercentage: 75,
        responsive: true,
        circumference: Math.PI,
        rotation: Math.PI,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          text: "Technology",
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: "#5d78ff",
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0,
          filter: function (tooltipItem, data) {
            var label = data.labels[tooltipItem.index];
            if (label == "Todo") {
              return false;
            } else {
              return true;
            }
          },
        },
      },
    };

    var ctx = document
      .getElementById("kt_widget_technologies_chart_lession_progress")
      .getContext("2d");
    var myDoughnut = new Chart(ctx, config);
    // var preventNaN = isNaN(that.studentInfo.averageProficiency) ? 0 : that.studentInfo.averageProficiency

    var config = {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [
              that.studentInfo.averageProficiency,
              100 - that.studentInfo.averageProficiency,
            ],
            backgroundColor: ["#1DC9B7", "#ffffff"],
            hoverBorderWidth: 0,
            borderWidth: 0,
          },
        ],
        labels: ["Overall Proficiency", "Todo"],
      },
      options: {
        hover: { mode: null },
        cutoutPercentage: 75,
        responsive: true,
        circumference: Math.PI,
        rotation: Math.PI,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          text: "Technology",
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        tooltips: {
          enabled: true,
          intersect: false,
          mode: "nearest",
          bodySpacing: 5,
          yPadding: 10,
          xPadding: 10,
          caretPadding: 0,
          displayColors: false,
          backgroundColor: "#5d78ff",
          titleFontColor: "#ffffff",
          cornerRadius: 4,
          footerSpacing: 0,
          titleSpacing: 0,
          filter: function (tooltipItem, data) {
            var label = data.labels[tooltipItem.index];
            if (label == "Todo") {
              return false;
            } else {
              return true;
            }
          },
        },
      },
    };

    // var ctx = document
    //   .getElementById("kt_widget_technologies_chart_proficiency")
    //   .getContext("2d");
    // new Chart(ctx, config);

    const querySnapshotTwo = await db
      .collection("users")
      .doc(localStorage.getItem("userID"))
      .collection("students")
      .doc(studentUid)
      .collection("notes")
      .get();

    querySnapshotTwo.forEach((doc) => {
      const uuid = doc.id;
      const timestamp = doc.data().date;
      const date = moment.unix(timestamp).format("MMM Do YYYY, h a");
      const note = doc.data().note;

      that.notes.push({
        uuid,
        timestamp,
        date,
        note,
      });
    });

    // Sort based off of date
    that.notes.sort((a, b) => b.timestamp - a.timestamp);
  },
  methods: {
    updatePostScore() {
      const that = this;
      const studentUid = localStorage.getItem("classroomInfoStudentID");

      db.collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .doc(studentUid)
        .update({
          postInCorrectScore: that.postInCorrectScore,
          postInitialScore: that.postInitialScore,
          postRepeatScore: that.postRepeatScore,
        })
        .then((data) => {
          location.reload();
        })
        .catch(console.error);
    },
    checkPostScore() {
      const that = this;
      const sum =
        that.postInitialScore + that.postRepeatScore + that.postInCorrectScore;

      if (sum === 15) {
        that.postScoresInvalid = false;
      } else {
        that.postScoresInvalid = true;
      }
    },
    edit() {
      const that = this;
      const studentUid = localStorage.getItem("classroomInfoStudentID");

      const data = {
        studentFullName: that.editInfo.studentFullName,
        studentDOB: that.editInfo.studentDOB,
        parentFullName: that.editInfo.parentFullName,
        parentPhone: that.editInfo.parentPhone,
        parentEmail: that.editInfo.parentEmail,
        initialScore: that.editInfo.initialScore,
        repeatScore: that.editInfo.repeatScore,
        inCorrectScore: that.editInfo.inCorrectScore,
        studentFullName: that.editInfo.studentFullName,
        ignoreBarton: that.editInfo.ignoreBarton,
      };

      if (!that.scoreInvalid) {
        data.initialScore = that.editInfo.initialScore;
        data.repeatScore = that.editInfo.repeatScore;
        data.inCorrectScore = that.editInfo.inCorrectScore;
      } else {
        data.initialScore = 0;
        data.repeatScore = 0;
        data.inCorrectScore = 15;
      }

      db.collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .doc(studentUid)
        .update(data)
        .then(() => {
          location.reload();
        })
        .catch(console.error);
    },
    deleteStudent() {
      const that = this;
      const studentUid = localStorage.getItem("classroomInfoStudentID");

      db.collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .doc(studentUid)
        .update({
          deleted: true,
        })
        .then(() => {
          that.$router.push({ path: "/dashboard" });
          location.reload();
        })
        .catch(console.error);
    },
    checkScore() {
      const that = this;
      const sum =
        that.editInfo.initialScore +
        that.editInfo.repeatScore +
        that.editInfo.inCorrectScore;

      if (sum === 15) {
        that.scoreInvalid = false;
      } else {
        that.scoreInvalid = true;
      }
    },
    async addNote() {
      const that = this;
      const studentUid =
        this.$route.query.studentID ||
        localStorage.getItem("classroomInfoStudentID");

      that.disabledSubmitBtn = true;
      document.body.style.cursor = "wait";

      const dateTimestmap = moment().unix();

      const newNoteUUID = uuidv4();

      await db
        .collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .doc(studentUid)
        .collection("notes")
        .doc(newNoteUUID)
        .set({
          date: dateTimestmap,
          note: that.newNote,
        });

        // // Get the new note's uuid
        // const querySnapshot = await db
        // .collection("users")
        // .doc(localStorage.getItem("userID"))
        // .collection("students")
        // .doc(studentUid)
        // .collection("notes")
        // .where("date", "==", dateTimestmap)
        // .get();



      that.notes.push({
        date: moment.unix(dateTimestmap).format("MMM Do YYYY, h a"),
        note: that.newNote,
        uuid: newNoteUUID,
      });

      that.newNote = "";
      that.disabledSubmitBtn = false;
      document.body.style.cursor = "default";
    },
    openEditModal(noteObj) {
      const that = this;

      that.editNoteText = noteObj.note;
      that.editNoteID = noteObj.uuid;

      $("#editNoteModal").modal("show");
    },
    async editNote(noteID) {
      const that = this;
      const studentUid =
        this.$route.query.studentID ||
        localStorage.getItem("classroomInfoStudentID");

      document.body.style.cursor = "wait";

      await db
        .collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .doc(studentUid)
        .collection("notes")
        .doc(noteID)
        .update({
          note: that.editNoteText,
        })
        .catch(console.error);

      document.body.style.cursor = "default";
      // Update the note in the array of notes
      that.notes.forEach((note) => {
        if (note.uuid === noteID) {
          note.note = that.editNoteText;
        }
      });

      $("#editNoteModal").modal("hide");
      that.editNoteText = "";
      that.editNoteID = "";
    },
    async deleteNote(noteID) {
      const that = this;

      console.log('noteID', noteID)
      const studentUid =
        this.$route.query.studentID ||
        localStorage.getItem("classroomInfoStudentID");

      await db
        .collection("users")
        .doc(localStorage.getItem("userID"))
        .collection("students")
        .doc(studentUid)
        .collection("notes")
        .doc(noteID)
        .delete()
        .catch(console.error);

      that.notes = that.notes.filter((note) => note.uuid !== noteID);
    },
  },
};
</script>

<style scoped>
h4,
h5 {
  color: white !important;
}

.noteButtons {
  text-align: center;
}

#editButton {
  color: white !important;
}

#screenScoresLink {
  color: white !important;
}

.kt-portlet .kt-widget-15 div,
.kt-widget-15__desc,
.kt-widget-15__info--item {
  color: white !important;
}

.leftBlock {
  color: white !important;
}

#postScoresClick {
  cursor: pointer !important;
  text-align: left !important;
}

#studentProfile {
  background: #b1987a !important;
}

a {
  color: #111c72 !important;
}

#postScoresClick:hover {
  background-color: #0d564aa1 !important;
  /* #0d564aa1 */
  border: 1px;
  border-color: #ffffffdb !important;
  border-style: dashed;
}

.btn.btn-label-brand {
  background-color: #379889a1 !important;
}

.btn.btn-icon.btn-brand i {
  color: #ffffff !important;
}

.btn.btn-icon.btn-success i {
  color: #ffffff !important;
}

.middleCharts {
  flex-grow: 0.5;
}

.middleCharts #kt_widget_technologies_chart {
  /* height: 200px !important;
    width: 200px !important; */
}

ul {
  display: block !important;
  list-style: none !important;
}

li {
  margin-bottom: 10px;
}

.kt-widget-15__body {
  display: flex;
}

.kt-widget-15__author {
  flex-grow: 1;
}

.kt-widget-15__wrapper {
  flex-grow: 1;
}

.kt-widget-15 .kt-widget-15__body .kt-widget-15__author .kt-widget-15__detail {
  justify-content: normal !important;
}

#noteTextArea {
  width: 100%;
  height: 100px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#noteSubmitButton {
  color: white !important;
  margin: 10px 0px;
  float: right;
}

.customCard {
  border: #57baab;
  border-width: 1px;
  border-style: dashed;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  color: black;
}

.cutomCardDate {
  padding: 11px;
  padding-right: 0px;
  color: gray;
  font-size: x-small;
  padding-left: 0px;
}
</style>
