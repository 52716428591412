<template>
  <div>
    <div class="pay">
      <stripe-pricing-table
        pricing-table-id="prctbl_1OFCR9CKdSniF3PKxHHh4eXU"
        publishable-key="pk_test_x7sIooZsOMmEumAbhwVWXfSZ"
      >
      </stripe-pricing-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "signin",
  data() {
    return {};
  },
  mounted() {
    const that = this;
  },
  methods: {},
};
</script>

<style scoped>
.pay {
  /* Maybe full page height */
  height: 100vh;
  background: white;;
}
</style>