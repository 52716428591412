<template>
  <div class="faq-component">
    <div style="padding: 20px">
      <h1 style="margin: 50px 0px">Table of Contents</h1>

      <!-- <p>
        Our goal is that students are successful so when a tutor discovers areas
        of difficulty for a student they are one step closer to helping them.
        Whenever a student has not mastered the content in the lesson, one of
        the best things you may do is repeat the lesson, emphasizing the areas
        of struggle. As always, you do not want to move on until the student has
        mastered the material.
      </p> -->

      <h4>
        If you’re spending a lot of time on this page, be encouraged, you have a
        challenging student! That’s right, this is the student who is going to
        help you to become a better teacher!
      </h4>

      <h4>
        To find answers to specific questions, see below and click on the topic.
      </h4>
      <h2>Topics</h2>
      <h4>
        To find answers to specific questions, see below and click on the topic.
      </h4>
      <ul style="list-style-type: none">
        <li :key="key" v-for="(image, key) in images">
          
           <h3> <a :href="'#' + key">{{ image.title }}</a></h3>
          
        </li>
      </ul>
    </div>

    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="row">
        <div
          :key="key"
          v-for="(image, key) in images"
          class="col-lg-6 col-xl-4 order-lg-1 order-xl-1"
        >
          <!--begin::Portlet-->
          <a :href="image.src" download target="_blank">
            <div
              class="kt-portlet kt-portlet--height-fluid imageHolder"
              :id="key"
            >
              <div class="kt-portlet__head kt-portlet__head--noborder">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">{{ image.title }}</h3>
                </div>
              </div>
              <div class="kt-portlet__body kt-portlet__body--fluid">
                <img :src="image.thumbnailSrc" class="videoImage" />
              </div>
            </div>
          </a>
          <!--end::Portlet-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gamesModal from "./gamesModal.vue";

export default {
  data() {
    return {
      images: [
        {
          title: "Difficulty with Sequencing",
          src: "https://docs.google.com/document/d/1_NRhBZnnKZZSgHGluWTFSBbX2njBOozT6PT5lgu83j8/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fstrugle.png?alt=media&token=2bb4f6e0-56f3-4996-be5d-8de8ccc7fac2",
        },
        {
          title: "Difficulty with the /th/ sound",
          src: "https://docs.google.com/document/d/135z5-hs_vLsNZisxGFjzxeR_pwEvDQijsDDkb-YO1qA/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fth-f.png?alt=media&token=718caaff-f342-4920-9546-f184e48a6304",
        },
        {
          title: "Difficulty with the /s/ and /sh/ sounds",
          src: "https://docs.google.com/document/d/1-H3tOMA4hnVRQ84k3jQXCmFZ1kStgUscWOsG8oxkU9k/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fs-sh.png?alt=media&token=a85fc4c7-32bc-4049-8b94-756eb3c5ab0a",
        },
        {
          title: "Difficulty with the /r/ and /l/ sound",
          src: "https://docs.google.com/document/d/17TRwLy7L_LAmm6cxMqWDNmqhSQJqNjOZondKkd9oKO0/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fr-i.png?alt=media&token=3bb5b065-f783-4576-a958-58a684db2f07",
        },
        {
          title: "Difficulty with the /i/ and /e/ sounds",
          src: "https://docs.google.com/document/d/1GZkWFMu42204k6j_1E1w7hvn04E50ZeJFsXr_ztMd18/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fi-e.png?alt=media&token=986ba44b-f10b-4e1d-ba7c-1e96b5e3f4a7",
        },
        {
          title: "Difficulty with the  /u/ and /o/ sounds",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fu-o.png?alt=media&token=932745cd-556c-4765-bcf4-3e1f06f2183f",
          src: "https://docs.google.com/document/d/1QiB3viCx7cuiMKEzWdzNgpMor6HWKNQpD3S08O4aLgg/edit?usp=sharing",
        },
        {
          title: "Difficulty with Changing with Sound Pictures",
          src: "https://docs.google.com/document/d/1Z5jnTAGeQ4L3El9H4_4fR0FTxaBdh1oLf_aHmqEBtuQ/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fcolors.png?alt=media&token=e6ab6dde-75aa-48b5-84be-54a1d874c4d6",
        },
        {
          title: "Motivation and Lesson Plan Ideas for a Severe Student",
          src: "https://docs.google.com/document/d/11XaZi7MK4x-aulCYyUXNgugngk9eDYtHuksMtNsDbjo/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fmotivate.png?alt=media&token=ad80d86f-3c79-4af7-8604-f99e1a46b09f",
        },
        {
          title: "Teaching a Student with Autism and Dyslexia",
          src: "https://docs.google.com/document/d/1NSgIQKTTZWYU0qhNs3sXrruNXL09nom5sMaLcaajtKE/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fdys.png?alt=media&token=40a8b7e4-3636-4958-b655-c0e935962eb5",
        },
        {
          title: "Additional Games for the Virtual Classroom",
          src: "https://docs.google.com/document/d/1gwJZsadkwdqqcZMIqHdqhR2y8_Y-MWpXthYOp1_2LSo/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fgames.png?alt=media&token=59fafe63-319e-4f4e-93b8-ba12d8ec0168",
        },
        {
          title: "IEP Goals for Foundation in SoundsTM",
          src: "https://docs.google.com/document/d/1XGaZThNply18Y-k0b3-vFLsCHjpVZW0jc1PZpdr3Lzc/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2FEIP.png?alt=media&token=f94029a6-7871-4550-9aac-8ed3e7009adb",
        },
        {
          title: "Foundation in SoundsTM Scope and Sequence",
          src: "https://docs.google.com/document/d/1HhKaVGS3qLzTSG5gN7351wJ0vmzyOi5EHHbAaN4YZe8/edit?usp=sharing",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fscope%20for.png?alt=media&token=3998c634-8cdd-4f29-84f8-3cc88b87dcc7",
        },
        {
          title: "Foundation in SoundsTM Completion Certificate",
          thumbnailSrc:
            "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/FAQs%2Fcert.png?alt=media&token=bf0ee4a5-de4b-4ce2-be32-a1c5084f2ff5",
          src: "https://docs.google.com/document/d/1IPWQP0eJ9e0Qq5GzwXnAbVe6s6kvIUAdjiCohfgbAbw/edit?usp=sharing",
        },
      ],
    };
  },
  components: {
    gamesModal,
  },
  async mounted() {
    $(document).ready(function () {
      $(".c15").click(function () {
        $(this).next(".answer").slideToggle();
      });
    });
  },
  methods: {
    toggle(elementID) {
      $(`#${elementID}`).next(".answer").slideToggle();
    },
    copyLink(index) {
      const url = window.location.href.split("#")[0];
      const link = `${url}#${index}`;
      navigator.clipboard.writeText(link);
      alert("Link copied to clipboard");
    },
  },
};
</script>

<style scoped>
.imageHolder {
  float: left;
  background: #edf0ff;
}

.imageHolder:hover {
  background: rgb(212 219 255);
}

.videoImage {
  width: 100% !important;
}

video {
  width: 100%;
}

#videoTraining h3 {
  font-size: 20px;
}

.faq-component {
  width: 100%;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 1rem;
}

.faq-item h3 {
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.faq-item p {
  margin-left: 1rem;
}
.answer {
  margin-top: 20px;
  margin-bottom: 20px;
  /* max-width: 500px; */
}

.question {
}

.answer {
  display: none;
}

/* -- Start of FAQ styles from docx -- */

@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
.lst-kix_list_14-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_14-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}
.lst-kix_list_14-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_14-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_list_18-5.start {
  counter-reset: lst-ctn-kix_list_18-5 0;
}
.lst-kix_list_14-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_14-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_14-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
.lst-kix_list_14-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}
ul.lst-kix_list_17-1 {
  list-style-type: none;
}
ul.lst-kix_list_17-0 {
  list-style-type: none;
}
ul.lst-kix_list_17-8 {
  list-style-type: none;
}
ul.lst-kix_list_17-7 {
  list-style-type: none;
}
ul.lst-kix_list_17-6 {
  list-style-type: none;
}
ul.lst-kix_list_17-5 {
  list-style-type: none;
}
ul.lst-kix_list_17-4 {
  list-style-type: none;
}
ul.lst-kix_list_17-3 {
  list-style-type: none;
}
.lst-kix_list_14-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_17-2 {
  list-style-type: none;
}
.lst-kix_list_18-8 > li {
  counter-increment: lst-ctn-kix_list_18-8;
}
.lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}
.lst-kix_list_5-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_5-3 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}
.lst-kix_list_5-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_5-1 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_list_18-0.start {
  counter-reset: lst-ctn-kix_list_18-0 0;
}
.lst-kix_list_5-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_8-4 {
  list-style-type: none;
}
ul.lst-kix_list_8-5 {
  list-style-type: none;
}
.lst-kix_list_5-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_5-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_8-2 {
  list-style-type: none;
}
ul.lst-kix_list_8-3 {
  list-style-type: none;
}
ul.lst-kix_list_8-8 {
  list-style-type: none;
}
ul.lst-kix_list_8-6 {
  list-style-type: none;
}
ul.lst-kix_list_8-7 {
  list-style-type: none;
}
.lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}
.lst-kix_list_18-3 > li {
  counter-increment: lst-ctn-kix_list_18-3;
}
.lst-kix_list_5-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_5-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_8-0 {
  list-style-type: none;
}
ul.lst-kix_list_8-1 {
  list-style-type: none;
}
.lst-kix_list_6-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_6-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_18-0 > li:before {
  content: "" counter(lst-ctn-kix_list_18-0, lower-latin) ". ";
}
.lst-kix_list_6-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_6-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_16-2 {
  list-style-type: none;
}
ul.lst-kix_list_16-1 {
  list-style-type: none;
}
.lst-kix_list_18-1 > li:before {
  content: "" counter(lst-ctn-kix_list_18-1, lower-roman) ". ";
}
.lst-kix_list_18-2 > li:before {
  content: "" counter(lst-ctn-kix_list_18-2, decimal) ". ";
}
ul.lst-kix_list_16-0 {
  list-style-type: none;
}
.lst-kix_list_6-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_16-8 {
  list-style-type: none;
}
ul.lst-kix_list_16-7 {
  list-style-type: none;
}
ul.lst-kix_list_16-6 {
  list-style-type: none;
}
ul.lst-kix_list_16-5 {
  list-style-type: none;
}
ul.lst-kix_list_16-4 {
  list-style-type: none;
}
.lst-kix_list_6-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_16-3 {
  list-style-type: none;
}
.lst-kix_list_6-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_6-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_6-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_2-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_7-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_7-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_18-5 > li {
  counter-increment: lst-ctn-kix_list_18-5;
}
.lst-kix_list_2-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_7-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_18-6 > li:before {
  content: "" counter(lst-ctn-kix_list_18-6, lower-latin) ". ";
}
ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
ol.lst-kix_list_9-7 {
  list-style-type: none;
}
.lst-kix_list_10-1 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_list_9-8 {
  list-style-type: none;
}
.lst-kix_list_18-4 > li:before {
  content: "" counter(lst-ctn-kix_list_18-4, lower-roman) ". ";
}
.lst-kix_list_18-8 > li:before {
  content: "" counter(lst-ctn-kix_list_18-8, decimal) ". ";
}
.lst-kix_list_13-7 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_list_9-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-4 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
ol.lst-kix_list_9-5 {
  list-style-type: none;
}
.lst-kix_list_7-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_list_9-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ol.lst-kix_list_9-0 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ol.lst-kix_list_9-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_10-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_15-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_10-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}
ol.lst-kix_list_18-6.start {
  counter-reset: lst-ctn-kix_list_18-6 0;
}
.lst-kix_list_10-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_11-7 {
  list-style-type: none;
}
ul.lst-kix_list_11-6 {
  list-style-type: none;
}
.lst-kix_list_4-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_11-5 {
  list-style-type: none;
}
ul.lst-kix_list_11-4 {
  list-style-type: none;
}
ul.lst-kix_list_11-3 {
  list-style-type: none;
}
.lst-kix_list_15-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_11-2 {
  list-style-type: none;
}
ul.lst-kix_list_11-1 {
  list-style-type: none;
}
ul.lst-kix_list_11-0 {
  list-style-type: none;
}
.lst-kix_list_9-2 > li:before {
  content: "" counter(lst-ctn-kix_list_9-2, decimal) ". ";
}
.lst-kix_list_4-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_4-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_11-8 {
  list-style-type: none;
}
.lst-kix_list_15-1 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_list_18-3.start {
  counter-reset: lst-ctn-kix_list_18-3 0;
}
.lst-kix_list_9-0 > li:before {
  content: "" counter(lst-ctn-kix_list_9-0, lower-latin) ". ";
}
.lst-kix_list_15-3 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_list_18-7 {
  list-style-type: none;
}
ol.lst-kix_list_18-8 {
  list-style-type: none;
}
ol.lst-kix_list_18-3 {
  list-style-type: none;
}
ol.lst-kix_list_18-4 {
  list-style-type: none;
}
ol.lst-kix_list_18-5 {
  list-style-type: none;
}
ol.lst-kix_list_18-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}
.lst-kix_list_9-6 > li:before {
  content: "" counter(lst-ctn-kix_list_9-6, lower-latin) ". ";
}
ol.lst-kix_list_18-0 {
  list-style-type: none;
}
ol.lst-kix_list_18-1 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}
ol.lst-kix_list_18-2 {
  list-style-type: none;
}
.lst-kix_list_9-4 > li:before {
  content: "" counter(lst-ctn-kix_list_9-4, lower-roman) ". ";
}
.lst-kix_list_11-3 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_list_18-4.start {
  counter-reset: lst-ctn-kix_list_18-4 0;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
.lst-kix_list_12-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_11-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_12-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
.lst-kix_list_9-8 > li:before {
  content: "" counter(lst-ctn-kix_list_9-8, decimal) ". ";
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.lst-kix_list_11-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
ul.lst-kix_list_10-0 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_13-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_10-8 {
  list-style-type: none;
}
ul.lst-kix_list_10-7 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_10-6 {
  list-style-type: none;
}
ul.lst-kix_list_10-5 {
  list-style-type: none;
}
ul.lst-kix_list_10-4 {
  list-style-type: none;
}
ul.lst-kix_list_10-3 {
  list-style-type: none;
}
.lst-kix_list_1-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_10-2 {
  list-style-type: none;
}
ul.lst-kix_list_10-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}
.lst-kix_list_13-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_12-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_18-4 > li {
  counter-increment: lst-ctn-kix_list_18-4;
}
.lst-kix_list_12-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_2-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_2-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_13-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}
.lst-kix_list_3-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_3-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_8-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_8-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_3-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_18-0 > li {
  counter-increment: lst-ctn-kix_list_18-0;
}
.lst-kix_list_3-4 > li:before {
  content: "\0025cb   ";
}
ol.lst-kix_list_18-2.start {
  counter-reset: lst-ctn-kix_list_18-2 0;
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
.lst-kix_list_8-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_3-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_8-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_8-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_8-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_13-5 {
  list-style-type: none;
}
ul.lst-kix_list_13-4 {
  list-style-type: none;
}
ul.lst-kix_list_13-3 {
  list-style-type: none;
}
.lst-kix_list_3-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_13-2 {
  list-style-type: none;
}
ul.lst-kix_list_13-1 {
  list-style-type: none;
}
.lst-kix_list_3-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_13-0 {
  list-style-type: none;
}
.lst-kix_list_8-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_11-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_13-8 {
  list-style-type: none;
}
.lst-kix_list_11-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_13-7 {
  list-style-type: none;
}
ul.lst-kix_list_13-6 {
  list-style-type: none;
}
.lst-kix_list_11-0 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}
.lst-kix_list_8-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_16-8 > li:before {
  content: "\0025a0   ";
}
ol.lst-kix_list_18-7.start {
  counter-reset: lst-ctn-kix_list_18-7 0;
}
.lst-kix_list_16-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_16-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_4-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_4-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_17-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_17-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_18-2 > li {
  counter-increment: lst-ctn-kix_list_18-2;
}
ul.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_16-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_4-6 {
  list-style-type: none;
}
.lst-kix_list_16-1 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_4-7 {
  list-style-type: none;
}
.lst-kix_list_16-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_4-0 {
  list-style-type: none;
}
.lst-kix_list_16-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_4-1 {
  list-style-type: none;
}
.lst-kix_list_16-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_16-5 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_4-4 {
  list-style-type: none;
}
ul.lst-kix_list_4-5 {
  list-style-type: none;
}
ul.lst-kix_list_4-2 {
  list-style-type: none;
}
ul.lst-kix_list_4-3 {
  list-style-type: none;
}
ol.lst-kix_list_18-1.start {
  counter-reset: lst-ctn-kix_list_18-1 0;
}
ol.lst-kix_list_18-8.start {
  counter-reset: lst-ctn-kix_list_18-8 0;
}
ul.lst-kix_list_12-6 {
  list-style-type: none;
}
ul.lst-kix_list_12-5 {
  list-style-type: none;
}
.lst-kix_list_17-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_12-4 {
  list-style-type: none;
}
ul.lst-kix_list_12-3 {
  list-style-type: none;
}
ul.lst-kix_list_12-2 {
  list-style-type: none;
}
ul.lst-kix_list_12-1 {
  list-style-type: none;
}
.lst-kix_list_17-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_12-0 {
  list-style-type: none;
}
.lst-kix_list_17-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_17-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_17-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_12-8 {
  list-style-type: none;
}
ul.lst-kix_list_12-7 {
  list-style-type: none;
}
.lst-kix_list_17-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_7-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_17-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_2-6 > li:before {
  content: "\0025cf   ";
}
ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}
.lst-kix_list_2-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_7-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_7-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}
.lst-kix_list_7-3 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_7-5 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_7-6 {
  list-style-type: none;
}
.lst-kix_list_18-5 > li:before {
  content: "" counter(lst-ctn-kix_list_18-5, decimal) ". ";
}
ul.lst-kix_list_7-3 {
  list-style-type: none;
}
.lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}
ul.lst-kix_list_7-4 {
  list-style-type: none;
}
.lst-kix_list_13-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_13-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_18-3 > li:before {
  content: "" counter(lst-ctn-kix_list_18-3, lower-latin) ". ";
}
.lst-kix_list_18-7 > li:before {
  content: "" counter(lst-ctn-kix_list_18-7, lower-roman) ". ";
}
ul.lst-kix_list_7-7 {
  list-style-type: none;
}
ul.lst-kix_list_7-8 {
  list-style-type: none;
}
.lst-kix_list_18-6 > li {
  counter-increment: lst-ctn-kix_list_18-6;
}
ul.lst-kix_list_7-1 {
  list-style-type: none;
}
ul.lst-kix_list_7-2 {
  list-style-type: none;
}
ul.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_15-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_15-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}
.lst-kix_list_10-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_10-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_4-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_15-3 {
  list-style-type: none;
}
ul.lst-kix_list_15-2 {
  list-style-type: none;
}
.lst-kix_list_15-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_15-1 {
  list-style-type: none;
}
.lst-kix_list_15-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_15-0 {
  list-style-type: none;
}
.lst-kix_list_10-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_4-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_15-8 {
  list-style-type: none;
}
.lst-kix_list_4-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_4-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_15-7 {
  list-style-type: none;
}
ul.lst-kix_list_15-6 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li:before {
  content: "" counter(lst-ctn-kix_list_9-3, lower-latin) ". ";
}
ul.lst-kix_list_15-5 {
  list-style-type: none;
}
ul.lst-kix_list_15-4 {
  list-style-type: none;
}
.lst-kix_list_15-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_10-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_9-1 > li:before {
  content: "" counter(lst-ctn-kix_list_9-1, lower-roman) ". ";
}
.lst-kix_list_9-7 > li:before {
  content: "" counter(lst-ctn-kix_list_9-7, lower-roman) ". ";
}
.lst-kix_list_11-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_12-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_9-5 > li:before {
  content: "" counter(lst-ctn-kix_list_9-5, decimal) ". ";
}
ul.lst-kix_list_6-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-4 {
  list-style-type: none;
}
ul.lst-kix_list_6-5 {
  list-style-type: none;
}
ul.lst-kix_list_6-8 {
  list-style-type: none;
}
.lst-kix_list_12-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_11-6 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_1-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_6-2 {
  list-style-type: none;
}
.lst-kix_list_11-8 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_6-3 {
  list-style-type: none;
}
.lst-kix_list_1-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_list_6-0 {
  list-style-type: none;
}
.lst-kix_list_12-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_list_1-4 > li:before {
  content: "\0025cb   ";
}
.lst-kix_list_13-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_14-4 {
  list-style-type: none;
}
ul.lst-kix_list_14-3 {
  list-style-type: none;
}
ul.lst-kix_list_14-2 {
  list-style-type: none;
}
.lst-kix_list_13-4 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_list_14-1 {
  list-style-type: none;
}
ul.lst-kix_list_14-0 {
  list-style-type: none;
}
.lst-kix_list_1-6 > li:before {
  content: "\0025cf   ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_18-7 > li {
  counter-increment: lst-ctn-kix_list_18-7;
}
ul.lst-kix_list_14-8 {
  list-style-type: none;
}
ul.lst-kix_list_14-7 {
  list-style-type: none;
}
.lst-kix_list_2-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_18-1 > li {
  counter-increment: lst-ctn-kix_list_18-1;
}
.lst-kix_list_12-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_14-6 {
  list-style-type: none;
}
ul.lst-kix_list_14-5 {
  list-style-type: none;
}
ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}
.lst-kix_list_1-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_2-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_13-2 > li:before {
  content: "\0025a0   ";
}
.lst-kix_list_12-8 > li:before {
  content: "\0025a0   ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c9 {
  /* background-color: #ffffff; */
  margin-left: 47pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c7 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c4 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c46 {
  margin-left: 47pt;
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c56 {
  margin-left: 72.2pt;
  padding-top: 17.3pt;
  text-indent: 1.1pt;
  padding-bottom: 0pt;
  line-height: 1.1453750133514404;
  text-align: left;
  margin-right: 3.7pt;
}
.c10 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c21 {
  margin-left: 72.9pt;
  padding-top: 2.5pt;
  text-indent: -17.5pt;
  padding-bottom: 0pt;
  line-height: 1.1453750133514404;
  text-align: left;
  margin-right: 13.7pt;
}
.c23 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.2949999999999997;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c27 {
  margin-left: 72pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c41 {
  margin-left: 47pt;
  padding-top: 10pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c52 {
  margin-left: 108.9pt;
  padding-top: 2.5pt;
  text-indent: -17.5pt;
  padding-bottom: 0pt;
  line-height: 1.1453750133514404;
  text-align: left;
  margin-right: 13.7pt;
}
.c8 {
  color: #7030a0;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
  font-style: normal;
}
.c3 {
  /* color: #9900ff; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  /* font-size: 12pt; */
  /* font-family: "Times New Roman"; */
  font-style: normal;
}
.c15 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
  font-size: 24px !important;
  cursor: pointer;
}
.c1 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
  font-style: normal;
}
.c37 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c53 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c51 {
  padding-top: 17.3pt;
  text-indent: 36pt;
  padding-bottom: 0pt;
  line-height: 1.1453750133514404;
  text-align: left;
  margin-right: 3.7pt;
}
.c29 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.0791666666666666;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c44 {
  margin-left: 90.7pt;
  padding-top: 2.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c58 {
  margin-left: 90.5pt;
  padding-top: 0.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c28 {
  /* background-color: #ffffff; */
  color: #1f1f1f;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c38 {
  margin-left: 36.2pt;
  padding-top: 17.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c17 {
  /* background-color: #ffffff; */
  font-size: 8pt;
  /* font-family: "Times New Roman"; */
  color: #222222;
  font-weight: 700;
}
.c24 {
  margin-left: 54.7pt;
  padding-top: 2.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c48 {
  margin-left: 72.2pt;
  padding-top: 17.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c36 {
  margin-left: 90.3pt;
  padding-top: 2.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c45 {
  margin-left: 54.5pt;
  padding-top: 0.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c13 {
  margin-left: 54.3pt;
  padding-top: 2.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c35 {
  color: #0000ff;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c16 {
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
  /* color: #9900ff; */
  font-weight: 700;
}
.c2 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c5 {
  color: #222222;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c6 {
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
  color: #222222;
  font-weight: 700;
}
.c30 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c33 {
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
  font-weight: 700;
}
.c43 {
  font-size: 8pt;
  /* font-family: "Times New Roman"; */
  font-weight: 400;
}
.c11 {
  font-size: 16pt;
  /* font-family: "Times New Roman"; */
  font-weight: 700;
}
.c34 {
  font-size: 14pt;
  /* font-family: "Times New Roman"; */
  font-weight: 700;
}
.c0 {
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
  font-weight: 400;
}
.c14 {
  font-weight: 400;
  font-size: 12pt;
  /* font-family: "Calibri"; */
}
.c47 {
  font-weight: 400;
  font-size: 11pt;
  /* font-family: "Calibri"; */
}
.c22 {
  /* background-color: #ffffff; */
  color: #222222;
}
.c25 {
  color: inherit;
  text-decoration: inherit;
}
.c42 {
  vertical-align: baseline;
  font-style: normal;
}
.c19 {
  padding: 0;
  margin: 0;
}
.c50 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c26 {
  margin-left: 36pt;
}
.c40 {
  color: #0000ff;
}
.c12 {
  padding-left: 0pt;
}
.c32 {
  /* background-color: #ffffff; */
}
.c57 {
  color: #1155cc;
}
.c54 {
  color: #7030a0;
}
.c39 {
  margin-left: 119pt;
}
.c49 {
  margin-left: 72pt;
}
.c55 {
  margin-left: 83pt;
}
.c31 {
  color: #222222;
}
.c20 {
  margin-left: 108pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  /* font-family: "Georgia"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  /* font-family: "Calibri"; */
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  /* font-family: "Calibri"; */
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin: 30px 0;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  /* font-family: "Calibri"; */
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
