<template>
  <div class="matchingGame">
    <div class="col-lg-12">
      <div class="kt-portlet" data-ktportlet="true" id="kt_portlet_tools_2">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
              <i class="flaticon2-graph-1 kt-label-font-color-2"></i>
            </span>
            <h3 class="kt-portlet__head-title">Thumbs Up Game</h3>
            <h3 class="kt-portlet__head-title" style="margin-left: 110px">
              <span class="highlightMeBtn" @click="resetScore()"
                >Reset score</span
              >
              &nbsp;Points: <b>{{ correct }}</b> out of: <b>{{ totalTries }}</b>
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
              <div class="kt-portlet__head-group">
                <!-- <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-secondary btn-circle"
                  title="Undo"
                >
                  <i class="la la-undo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-secondary btn-circle"
                  title="Redo"
                >
                  <i class="la la-mail-forward"></i>
                </a> -->
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-success btn-circle"
                  title="Rules"
                  data-toggle="modal"
                  data-target="#matchingRulesModal"
                >
                  <i class="la la-exclamation-circle"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-sm btn-icon btn-danger btn-circle"
                  title="End Game"
                  @click="close"
                >
                  <i class="la la-close"></i>
                </a>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_mjlxyndbma"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    will-change: transform;
                    visibility: hidden;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(484px, -38px, 0px);
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 28px"></div>
                  <div class="tooltip-inner">Reload</div>
                </div>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_8wf7n27ova"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    will-change: transform;
                    visibility: hidden;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(442px, -38px, 0px);
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 34px"></div>
                  <div class="tooltip-inner">Collapse</div>
                </div>
                <div
                  class="tooltip tooltip-portlet tooltip bs-tooltip-top"
                  role="tooltip"
                  id="tooltip_ca8wjj4ub2"
                  aria-hidden="true"
                  x-placement="top"
                  style="
                    position: absolute;
                    transform: translate3d(516px, -39px, 0px);
                    top: 0px;
                    left: 0px;
                    will-change: transform;
                    visibility: hidden;
                  "
                >
                  <div class="tooltip-arrow arrow" style="left: 32px"></div>
                  <div class="tooltip-inner">Remove</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="kt-portlet__content">
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <div class="voting-buttons">
                  <h4>Correct?</h4>
                  <a href="javascript:;" @click="check('true')" title="True">
                    <i class="flaticon-like"></i>
                  </a>
                  <a href="javascript:;" @click="check('false')" title="False">
                    <!-- <i class="flaticon2-delete dislike"></i> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      fill="currentColor"
                      class="bi bi-hand-thumbs-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964l-.261.065zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div class="row">
                <!--begin::Portlet-->

                <div id="videoContainer">
                  <video id="soundVideo" width="320" height="240" controls>
                    <source :src="videoSrc" type="video/mp4" />
                    <source :src="videoSrc" type="video/mov" />
                    Your browser does not support the video tag.
                  </video>
                </div>

                <div class="imageHolder">
                  <img
                    :src="currentImageSrc"
                    :id="'image_' + currentImageName"
                    class="tileImage"
                  />
                </div>

                <!--end::Portlet-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Modal -->
    <div
      class="modal fade"
      id="matchingRulesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="matchingRulesModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-l modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="matchingRulesModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-exclamation-1"></i> Rules
            </h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
          </div>
          <div class="modal-body">
            <div
              class="kt-content kt-grid__item kt-grid__item--fluid"
              id="kt_content"
            >
              <div class="row">
                <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                  When the Thumbs Up game is selected, a video and corresponding
                  Sound Picture will appear. In the video a person will say one
                  of the sounds that have been taught. The student will decide
                  whether the sound that has been said is the same or different
                  than the first sound in the Sound Picture displayed. If the
                  student believes that the sound said in the video is the same
                  as the first sound in the Sound Picture displayed, the student
                  will click on the thumbs up. If the student believes that the
                  sound said in the video is different than the first sound in
                  the Sound Picture displayed, the student will click on the
                  thumbs down. A point is awarded for each correct response. The
                  game is not timed and will continue until you decide to stop.
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-outline-brand" data-dismiss="modal">Close</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <!-- Start Modal -->
    <!-- <div class="modal fade" id="checkAnswer" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>
              <b>{{ $store.getters.getClassroomInfoStudentName }}</b> selected <b>{{ selectedBoolean }}</b> for the sound you made.
              <br />
              <br />Was the student correct?
            </p>
          </div>
          <div class="modal-footer">
            <div class="centerButtons">
              <button type="button" class="btn btn-default" @click="finishedCard(true)">Yes</button>
              <button type="button" class="btn btn-default" @click="finishedCard(false)">No</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- End Modal -->
  </div>
</template>

<script>
let videoData = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fa_compressed.mov?alt=media&token=07d4fa24-9d18-43a9-9cd1-8afc846bfdf7",
    sound: "a",
    name: "apple",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fb.mp4?alt=media&token=00165a8f-87af-4f25-9a9f-184c242d1796",
    sound: "b",
    name: "balloon",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fch.mp4?alt=media&token=3479ffc1-4606-4ca0-8b19-c6cccb625abd",
    sound: "ch",
    name: "chop",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fg_compressed.mp4?alt=media&token=67511786-a4cd-48b4-b3c2-f2f1a81ae126",
    sound: "g",
    name: "goat",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fi.MOV.mov?alt=media&token=946fce0f-ede1-47c3-a876-85f690bb86a6",
    sound: "i",
    name: "itchy",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fj_compressed.MOV?alt=media&token=c79af521-c43d-4bcb-ac2a-3da7154e58f1",
    sound: "j",
    name: "jam",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fk_compressed.MOV?alt=media&token=2dc98887-1728-4f91-98b8-434dd0336dbc",
    sound: "k",
    name: "kite",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fl_compressed.MOV?alt=media&token=012bdde4-c49a-48a1-a944-0ebbcf96fa8f",
    sound: "l",
    name: "lamp",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fm_compressed.MOV?alt=media&token=faf66298-b49b-40da-9214-332c8a533d12",
    sound: "m",
    name: "map",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fn_compressed.MOV?alt=media&token=9946afdd-b673-428f-9d88-8501b15352e1",
    sound: "n",
    name: "nut",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fo_compressed.MOV?alt=media&token=4809140f-f3fb-4ff4-bd31-ca6f4be48114",
    sound: "o",
    name: "olive",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fp_compressed.MOV?alt=media&token=89df166a-382a-45f4-8a3a-22b979d7733f",
    sound: "p",
    name: "pig",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fr_compressed.MOV?alt=media&token=07fc3822-d41d-4085-8acb-af71acdc32e1",
    sound: "r",
    name: "rat",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fs_compressed.MOV?alt=media&token=9bb7a2d1-b1f7-4de5-b0eb-ab676b96c5c9",
    sound: "s",
    name: "sun",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fsh.MOV.mov?alt=media&token=be1499f7-4cfd-4458-b25d-028f0e3a6da6",
    sound: "sh",
    name: "ship",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ft.MOV.mov?alt=media&token=0e952ba4-9366-4eb3-bdd3-0474a607daf9",
    sound: "t",
    name: "ten",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fth_compressed.mp4?alt=media&token=bfcd7e92-9bdf-4225-ab7e-81cd4c71394b",
    sound: "th",
    name: "thumb",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fu.MOV.mov?alt=media&token=6cd1e257-795f-4bd6-bbc1-0fe3d5196a6d",
    sound: "u",
    name: "upper",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fv.MOV.mov?alt=media&token=25fff4a7-cc2d-4af1-b076-0f8e78de3d89",
    sound: "v",
    name: "vase",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Ff_compressed.mp4?alt=media&token=9d98b8c9-f874-47c2-9aa9-01053958cc59",
    sound: "f",
    name: "fish",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fe_compressed.MOV?alt=media&token=d426a1fb-9f97-4803-840f-b2ba746d197f",
    sound: "e",
    name: "eddie",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Videos%2Fd.mp4?alt=media&token=e93ba769-ef03-4c1a-94cd-35d59da35782",
    sound: "d",
    name: "duck",
  },
];

export default {
  name: "thumbsGame",
  data() {
    return {
      images: [],
      currentImageIndex: 0,
      correct: 0,
      totalTries: 0,
      currentImageSrc: "",
      currentImageName: "",
      selectedBoolean: "",
      videoSrc: "",
      randomVideoObject: {},
    };
  },
  async mounted() {
    const that = this;
    that.images = [];
    var storageRef = storage.ref();

    // Create a reference under which you want to list
    var listRef = storageRef.child("Images/Tiles");

    const studentUid = localStorage.getItem("classroomInfoStudentID");

    const doc = await db
      .collection("users")
      .doc(studentUid)
      .get()
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    if (doc.exists) {
      for (var tile in doc.data().tiles) {
        let obj = {
          name: tile,
          visable: doc.data().tiles[tile],
        };

        //Only push visable images
        if (obj.visable) {
          // //If in the array add it to the page
          // const currentLesson = that.$store.getters.getClassroomInfoWhiteListedTiles
          // if (true === currentLesson.includes(obj.name.toLowerCase())) {
          that.images.push(obj);
          // }
        }
      }

      // Find all the prefixes and items.
      const res = await listRef.listAll().catch((error) => {
        // Uh-oh, an error occurred!
        console.error(error);
      });

      var backSideRef = storageRef.child(
        "Images/classroomBackgrounds/Back of Cards.jpg"
      );

      const backSidePath = await backSideRef.getDownloadURL();

      res.items.forEach((item) => {
        var starsRef = storageRef.child(item.location.path);
        // Get the download URL
        starsRef
          .getDownloadURL()
          .then((url) => {
            let path = item.location.path;
            let name = path
              .split("Images/Tiles/")[1]
              .split(".jpg")[0]
              .toLowerCase();

            for (let i = 0; i < that.images.length; i++) {
              if (that.images[i].name == name) {
                let obj = {
                  name: that.images[i].name,
                  visable: that.images[i].visable,
                  src: url,
                  backSidePath: backSidePath,
                };

                that.$set(that.images, i, obj);
              }
            }

            that.nextVideo();

            that.currentImageIndex = 0;
            that.currentImageSrc = that.images[that.currentImageIndex].src;
            that.currentImageName = that.images[that.currentImageIndex].name;
          })
          .catch((error) => {
            that.$store.dispatch("setInfoDanger", error.code);
          });
      });

      that.shuffleArray();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },
  methods: {
    check(studentAnswer) {
      const that = this;
      studentAnswer = studentAnswer === "true";
      //Check answer aginst shown video
      //Show next card

      console.log("studentAnswer", studentAnswer);

      if (
        that.randomVideoObject.name === that.currentImageName &&
        studentAnswer == true
      ) {
        console.log("Correct!1");
        that.correct++;
        // let addOnePoint = this.$store.getters.getClassroomInfoPoints + 1
        // let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1
        // this.$store.dispatch('setClassroomInfoPoints', addOnePoint)
        // this.$store.dispatch('setClassroomInfoTotalTries', addOneTry)
      } else if (
        that.randomVideoObject.name !== that.currentImageName &&
        studentAnswer == false
      ) {
        console.log("Correct!2");
        that.correct++;
        // let addOnePoint = this.$store.getters.getClassroomInfoPoints + 1
        // let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1
        // this.$store.dispatch('setClassroomInfoPoints', addOnePoint)
        // this.$store.dispatch('setClassroomInfoTotalTries', addOneTry)
      } else {
        console.log("Incorrect!");
        // let addOneTry = this.$store.getters.getClassroomInfoTotalTries + 1
        // this.$store.dispatch('setClassroomInfoTotalTries', addOneTry)
      }

      that.totalTries++;

      this.currentImageIndex++;

      //If gone through, start another loop
      if (this.currentImageIndex === this.images.length) {
        this.currentImageIndex = 0;
      }
      this.currentImageSrc = this.images[this.currentImageIndex].src;
      this.currentImageName = this.images[this.currentImageIndex].name;

      that.nextVideo();
    },
    async nextVideo() {
      const that = this;

      const video = document.getElementById("soundVideo");
      if (video.src) {
        console.log("has src and is about to remove it");
        video.pause();
        video.removeAttribute("src"); // empty source
        video.load();
      }

      //Reset
      that.currentImageOneSrc = "";
      that.currentImageOneName = "";
      that.currentImageTwoSrc = "";
      that.currentImageTwoName = "";

      videoData = videoData.sort(() => Math.random() - 0.5); //Shuffles the array

      // Randomly select a video from the videoData array of videos and set it to the randomVideoObject, but make we should more often than not get a video object that has a name value that matches the that.currentImageName value
      if (Math.random() > 0.7) {
        that.randomVideoObject =
          videoData[Math.floor(Math.random() * videoData.length)]; //Grabs random item
        console.log("found random video");
      } else {
        that.randomVideoObject = videoData.find(
          (x) => x.name === that.currentImageName
        );

        // If we don't find a video object that has a name value that matches the that.currentImageName value, then we should just grab a random video object
        // I think that.currentImageName and that.randomVideoObject don't match up
        while (!that.randomVideoObject) {
          that.randomVideoObject =
            videoData[Math.floor(Math.random() * videoData.length)]; //Grabs random item
          console.log("in while..................");
        }

        console.log("found matching video", that.randomVideoObject.name);
      }

      //We should only play video that is for this lesson
      while (
        -1 ===
        that.images.findIndex((x) => x.name === that.randomVideoObject.name)
      ) {
        that.randomVideoObject =
          videoData[Math.floor(Math.random() * videoData.length)]; //Grabs random item
        // console.log('randomVidObj inside while', that.randomVideoObject)
      }

      // //Find index and remove from array
      // const x = videoData.findIndex((z) => z.name === that.randomVideoObject.name)
      // videoData.splice(x, 1)

      // Sets video source
      that.videoSrc = that.randomVideoObject.src;

      video.load();
      setTimeout(() => {
        video.play();
      }, 0);
    },
    shuffleArray() {
      for (let i = this.images.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.images[i], this.images[j]] = [this.images[j], this.images[i]];
      }
    },
    close() {
      this.$store.dispatch("setClassroomInfoThumbsGameFinished", true);
      this.$emit("displayGame", false);
    },
    resetScore() {
      this.correct = 0;
      this.totalTries = 0;
    },
  },
};
</script>

<style scoped>
#videoContainer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
  justify-content: center;
}

.imageHolder {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
}

.imageHolder img {
  height: auto;
  width: auto;
  max-height: 350px;
  margin: 0 auto;
}

.voting-buttons {
  font-size: 35px;
  margin: auto;
  left: 0px;
  right: 0px;
  margin-bottom: 15px;
}

.voting-buttons .dislike {
  color: #fd397a;
}

.centerButtons {
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
}

.highlightMeBtn {
  background-color: #dfdfdf;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
