<template>
  <div>
    <h1>Maintenance page. <a href="/">Go back to dashboard</a></h1>

    <!-- Input form -->

    <div class="form-group">
      <label for="uid">UID</label>
      <input
        type="text"
        class="form-control"
        id="uid"
        v-model="uid"
        placeholder="Enter UID"
      />

      <label for="username">Username</label>

      <input
        type="text"
        class="form-control"
        id="username"
        v-model="username"
        placeholder="Enter username"
      />

      <label for="password">Password</label>
      <input
        type="password"
        class="form-control"
        id="password"
        v-model="password"
        placeholder="Enter password"
      />

      <!-- New user type dropdown select -->
      <div>
        <label for="newUserType">New user type</label>
        <select
          class="form-control"
          id="newUserType"
          v-model="newUserType"
          placeholder="Select new user type"
        >
          <option value="tutorAdmin">TutorAdmin</option>
          <option value="homeschooler">Independent</option>
        </select>

      <button @click="login" class="btn btn-primary">Login</button>

      <!-- Error message -->
      <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ZAuth",
  data() {
    return {
      uid: "",
      username: "",
      password: "",
      error: "",
      newUserType: "",
    };
  },
  methods: {
    async login() {
      try {
        const that = this;

        // Check if uid, username, and password are not empty
        if (!this.uid || !this.username || !this.password) {
          throw new Error("Please fill in all fields");
        }

        const response = await axios.post("/admin/get-token", {
          uid: this.uid,
          username: this.username,
          password: this.password,
        });

        // Check for errors
        if (response.data.error) {
          throw new Error(response.data.error);
        }

        const data = response.data;
        const customToken = data.token;
        const isNewUser = data.isNewUser;

        // Check if new user
        if (isNewUser && !that.newUserType) {
          throw new Error("Please select a new user type and try again");
        }

        // First signout and then navigate to the /authenticate page with the custom token in the URL
        firebase
          .auth()
          .signOut()
          .then(function () {
            console.log("Sign-out successful.");
            // Sign-out successful.
            that.$store.dispatch("setAuthStatus", false);
            that.adminStatus = false;
            that.$store.dispatch("setAdminStatus", false);
            localStorage.removeItem("userID");

            that.$router.push({
              path: "/authenticate",
              query: {
                customToken: customToken,
                accountType: isNewUser ? that.newUserType : "",
                viewAs: true,
              },
            });
          })
          .catch(function (error) {
            // An error happened.
            console.error("Error signing out:", error);
          });
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>
