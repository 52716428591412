<template>
  <div class="chart">
    <div class="row">
      <!-- <div class="col-lg-1">
      </div> -->

      <div class="col-lg-12" id="mainBGs">
        <div class="row">
          <div class="chartImgs side">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Lip%20Chart%20for%20Quiet%20Sounds.jpg?alt=media&token=6691fcb4-efc2-46b9-9fe2-5767f48cef5e"
              class="col s4 sideImgs"
              @load="positionSmallImages()"
              id="topLeftImg"
            />
            <div
              style="left: 7%; top: -69%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Fish')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FFish.jpg?alt=media&token=aa06cb16-3ece-4193-854f-01180460de06"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 38%; top: -69%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Sun')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FSun.jpg?alt=media&token=42e4e4e9-fd79-44df-a43a-c609f6a6592f"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 69%; top: -69%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Ship')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FShip.jpg?alt=media&token=10b61544-4b6b-4be6-a780-a582377ec710"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 7%; top: -33%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Pig')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FPig.jpg?alt=media&token=4560f8d2-4a70-4ea3-b890-c069fa3008d4"
                class="draggable tile chartTile"
              />
            </div>
          </div>
          <div class="chartImgs middle">
            <img
              class="col s4 middleImgs"
              src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FVowel%20Chart.jpg?alt=media&token=fc402fbb-78c3-46ad-bcf7-6bd61d371fa1"
            />

            <div
              style="left: 4%; top: -37%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Itchy')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FItchy.jpg?alt=media&token=79f66741-f0fb-45ee-ba03-c0e29241fbde"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 23%; top: -42%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Eddy')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FEddy.jpg?alt=media&token=0066aebe-c6f6-4f18-97cb-925842f59faf"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 42%; top: -47%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Upper')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FUpper.jpg?alt=media&token=46e36ace-ab99-4b66-974b-0a6abb52c3c7"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 61%; top: -52%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Apple')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FApple.jpg?alt=media&token=69695f92-99b0-4313-aa19-21f0df5c57dd"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 79%; top: -57%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Olive')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FOlive.jpg?alt=media&token=0f7a4754-ec7e-4176-8cba-d485d0c80ec3"
                class="draggable tile chartTile"
              />
            </div>

            <div class="col s12">
              <div
                id="colors"
                class="row"
                style="width:100%; display: flex; justify-content: center; margin-top: 10px;"
              >
                <div class="over">
                  <div
                    class="dot draggable color yellow"
                  ></div>
                  <div
                    class="dot draggable color yellow"
                  ></div>
                  <div
                    class="dot draggable color yellow"
                  ></div>
                </div>
                <div class="over">
                  <div
                    class="dot draggable color blue"
                  ></div>
                  <div
                    class="dot draggable color blue"
                  ></div>
                  <div
                    class="dot draggable color blue"
                  ></div>
                </div>
                <div class="over">
                  <div
                    class="dot draggable color green"
                  ></div>
                  <div
                    class="dot draggable color green"
                  ></div>
                  <div
                    class="dot draggable color green"
                  ></div>
                </div>
                <div class="over">
                  <div
                    class="dot draggable color orange"
                  ></div>
                  <div
                    class="dot draggable color orange"
                  ></div>
                  <div
                    class="dot draggable color orange"
                  ></div>
                </div>
                <div class="over">
                  <div
                    class="dot draggable color red"
                  ></div>
                  <div
                    class="dot draggable color red"
                  ></div>
                  <div
                    class="dot draggable color red"
                  ></div>
                </div>
              </div>

            </div>

          </div>
          <div class="chartImgs side">
            <img
              class="col s4 sideImgs"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Tongue%20Chart%20for%20Quiet%20Sounds.jpg?alt=media&token=05d871b6-418f-4fca-843b-1bed9ce91a00"
            />

            <div
              style="left: 7%; top: -69%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Thumb')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FThumb.jpg?alt=media&token=3af5cc45-c81e-4505-9a1f-4821ff974682"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 38%; top: -69%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Kite')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FKite.jpg?alt=media&token=74318fc6-8564-450e-84dd-1e3355b30cf9"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 69%; top: -69%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Ten')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FTen.jpg?alt=media&token=710dbb29-c38d-4b8a-a2b4-90557504dfae"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 7%; top: -33%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Chop')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FChop.jpg?alt=media&token=87c516b0-f1f2-4040-9a89-27284486ef9c"
                class="draggable tile chartTile"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="chartImgs side">
            <img
              class="col s4 sideImgs"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Lip%20Chart%20for%20Vibrating%20Sounds.jpg?alt=media&token=7550bb43-b36b-4c8d-bca0-e509c05980f8"
            />

            <div
              style="left: 7%; top: -68%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Map')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FMap.jpg?alt=media&token=311b2c21-b76d-41a4-92dd-155520793323"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 38%; top: -68%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Vase')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FVase.jpg?alt=media&token=0526a76a-867a-4c6d-978b-a30add496263"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 69%; top: -68%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Balloon')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FBalloon.jpg?alt=media&token=9418a8ba-150c-4f3f-8d66-3746b0b3f9e9"
                class="draggable tile chartTile"
              />
            </div>
          </div>
          <div class="chartImgs middle">
            <div id="SSLImageGroup">
              <img
                class="col s4 middleImgs"
                id="SSLBigImage"
                v-show="'stopLight' === selectedSheet"
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSounds%20Stop%20Light.jpg?alt=media&token=e7db96a3-3d3b-40a4-8cb3-53f32782a99f"
              />
              <img v-show="showImage" :src="imageSrc" id="SSLSmallImage" />
              <!-- <soundsStopLightInChart class="col s4" v-if="'stopLight' === selectedSheet" :show-image="showImage" :show-stop-light="showStopLight"></soundsStopLightInChart> -->
            </div>
            <img
              class="col s4 middleImgs"
              v-show="'color3' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=dc126e92-22e7-4826-bc90-12dcc49b0865"
            />
            <img
              class="col s4 middleImgs"
              v-show="'color2' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=5058ab1f-e3dd-4eed-9787-bb1c125f10c7"
            />
            <img
              class="col s4 middleImgs"
              v-show="'sound3' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=6e1cf91a-3967-441e-95e8-6544798a1f73"
            />
            <img
              class="col s4 middleImgs"
              v-show="'sound2' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FclassroomBackgrounds%2FSequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=e495b564-6734-4288-a202-9ddc41fe3db1"
            />

            <div class="" style="width:100%; display: flex; justify-content: center; margin-top: 10px;">
              <div v-show="resetButtons">
                <button
                  type="button"
                  class="btn btn-info mr-2 customSmallButton"
                  @click="resetTiles"
                >
                  Reset Sound Pictures
                </button>
                <button
                  type="button"
                  class="btn btn-info mr-2 customSmallButton"
                  @click="restColors"
                >
                  Reset Color Tiles
                </button>
              </div>
              </div>



          </div>
          <div class="chartImgs side">
            <img
              class="col s4 sideImgs"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Tongue%20Chart%20for%20Vibrating%20Sounds.jpg?alt=media&token=8efd677e-5171-4c8b-9110-dcfe68c814c8"
            />

            <div
              style="left: 7%; top: -68%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Nut')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FNut.jpg?alt=media&token=2a8dbb7f-1216-4206-92fc-be52ec9ada73"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 38%; top: -68%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Goat')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FGoat.jpg?alt=media&token=6e1e3cf9-6a35-4147-ab69-7d589aa37e18"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 69%; top: -32%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Lamp')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FLamp.jpg?alt=media&token=77db5c5c-93b3-41f7-b5b0-4ddee26ba68f"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 7%; top: -32%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Rat')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FRat.jpg?alt=media&token=76cbf7f3-2a16-4dd3-b0d3-4f1409f10aaa"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 38%; top: -32%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Duck')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FDuck.jpg?alt=media&token=c3c1e69b-162d-4506-a202-c269bf64de71"
                class="draggable tile chartTile"
              />
            </div>
            <div
              style="left: 69%; top: -68%;"
              class="imgContainer"
              v-show="toShowOrToNowShowArray.includes('Jam')"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                class="draggable tile chartTile"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FTiles%2FJam.jpg?alt=media&token=4d68bd58-c1e5-4107-aca3-ad2bc616dd9e"
                class="draggable tile chartTile"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import soundsStopLightInChart from "./soundsStopLightInChart";

let soundsForLesson = {
  lesson2: [
    ["/m/", "/m/", "/n/"],
    ["/m/", "/n/", "/m/"],
    ["/n/", "/m/", "/m/"],
    ["/n/", "/m/", "/n/"],
    ["/n/", "/n/", "/m/"],
    ["/m/", "/n/", "/n/"],
  ],
  lesson3: [
    ["/m/", "/v/", "/m/"],
    ["/f/", "/v/", "/v/"],
    ["/m/", "/m/", "/n/"],
    ["/v/", "/f/", "/v/"],
    ["/n/", "/m/", "/n/"],
    ["/n/", "/v/", "/n/"],
    ["/f/", "/m/", "/v/"],
  ],
  lesson4: [
    ["/n/", "/n/", "/th/"],
    ["/th/", "/f/", "/f/"],
    ["/v/", "/v/", "/f/"],
    ["/th/", "/v/", "/th/"],
    ["/f/", "/th/", "/m/"],
    ["/v/", "/th/", "/f/"],
  ],
  lesson5: [
    ["/m/,", "/m/,", "/v/"],
    ["/k/", "/k/", "/g/"],
    ["/m/", "/m/", "/n/"],
    ["^/v/", "/f/", "/v/"],
    ["^/th/", "/v/", "/v/"],
    ["/m/", "/k/", "/n/"],
    ["/k/", "/m/", "/g/"],
  ],
  lesson6: [
    ["/v/,", "/v/,", "/m/"],
    ["/g/", "/k/", "/g/ "],
    ["^/sh/", "/th/", "/sh/"],
    ["/s/", "/s/", "/th/"],
    ["/v/", "/f/", "/v/ "],
    ["/m/", "/n/", "/m/"],
    ["/sh/", "/g/", "/g/"],
  ],
  lesson7: [
    ["/i/,", "/i/,", "/k/"],
    ["/m/", "/m/", "/n/ "],
    ["/e/", "/g/", "/e/ "],
    ["^/th/", "/f/", "/th/ "],
    ["/e/", "/e/", "/i/"],
    ["/sh/", "/s/", "/s/ "],
    ["/v/", "/i/", "/v/"],
  ],
  lesson8: [
    ["/u/,", "/i/,", "/u/"],
    ["/f/", "/f/", "/v/ "],
    ["/e/", "/g/", "/g/ "],
    ["/th/", "/s/", "/s/"],
    ["/u/", "/sh/", "/m/"],
    ["/k/", "/n/", "/u/"],
    ["/v/", "/e/", "/s/"],
  ],
  lesson9: [
    ["/m/,", "/n/,", "/n/"],
    ["/v/", "/f/", "/v/"],
    ["/a/", "/a/", "/o/ "],
    ["/s/", "/sh/", "/s/"],
    ["/u/", "/i/", "/i/ "],
    ["/g/", "/th/", "/m/"],
    ["^/o/", "/i/", "/e/"],
  ],
  lesson10: [
    ["/g/,", "/r/,", "/g/"],
    ["/r/", "/l/", "/l/"],
    ["/i/", "/e/", "/e/ "],
    ["/th/", "/sh/", "/sh/"],
    ["/a/", "/o/", "/o/ "],
    ["/l/", "/r/", "/k/"],
    ["/a/", "/o/", "/u/"],
  ],
  lesson11: [
    ["/t/,", "/d/,", "/d/"],
    ["/t/", "/m/", "/t/ "],
    ["/e/", "/i/", "/o/"],
    ["/f/", "/th/", "/f/"],
    ["/a/", "/e/", "/i/"],
    ["/k/", "/l/", "/r/"],
    ["/o/", "/o/", "/u/"],
  ],
  lesson12: [
    ["/j/,", "/ch/,", "/j/"],
    ["/ch/", "/j/", "/sh/ "],
    ["/i/", "/a/", "/i/ "],
    ["/f/", "/th/", "/s/"],
    ["/i/", "/e/", "/e/"],
    ["/m/", "/d/", "/n/"],
    ["/o/", "/a/", "/u/"],
  ],
  lesson13: [
    ["/j/,", "/ch/,", "/j/"],
    ["/ch/", "/j/", "/sh/ "],
    ["/i/", "/a/", "/i/ "],
    ["/f/", "/th/", "/s/"],
    ["/i/", "/e/", "/e/"],
    ["/m/", "/d/", "/n/"],
    ["/o/", "/a/", "/u/"],
  ],
};

let sequence = 0;

let move = 0;
let lastTileMoved = "";
let objectWithPlaceholders = {
  moveOne: "",
  moveTwo: "",
  moveThree: "",
};
let correctAndIncorrectObj = {};

export default {
  name: "chart",
  data() {
    return {
      resetButtons: false,
      image: "",
      apple: true,
      balloon: true,
      chop: true,
      duck: true,
      eddy: true,
      fish: true,
      goat: true,
      itchy: true,
      jam: true,
      kite: true,
      lamp: true,
      map: true,
      nut: true,
      olive: true,
      pig: true,
      rat: true,
      ship: true,
      sun: true,
      ren: true,
      ten: true,
      thumb: true,
      upper: true,
      vase: true,
    };
  },
  props: [
    "selectedSheet",
    "showImage",
    "showStopLight",
    "imageSrc",
    "toShowOrToNowShowArray",
  ],
  computed: [soundsStopLightInChart],
  mounted() {
    const that = this;

    $(".draggable").draggable({
      stack: ".draggable",
    });

    that.$root.$on("restColors", () => {
      that.restColors();
    });

    window.addEventListener('resize', that.positionSmallImages);

    const info = localStorage.getItem("classroomInfoProgress").split(", ");

    const lesson = info[0].toLowerCase();
    const procedure = info[1] ? info[1].toLowerCase() : "introduction";

    if (procedure.includes('2') || procedure.includes('3') || procedure.includes('7') || procedure.includes('8')) {
      that.resetButtons = true
    } else {
      that.resetButtons = false
    }

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.positionSmallImages());
  },
  methods: {
    async positionSmallImages() {
      let largeImage = document.getElementById('topLeftImg');
      const imageTiles = document.querySelectorAll('.chartTile');

      while (!largeImage) {
        console.log('Waiting for large image to load...')
        largeImage = document.getElementById('topLeftImg');
        // Wait 100ms
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const goodHight = parseInt(largeImage.offsetHeight / 4)

      imageTiles.forEach(imageTile => {
          imageTile.style.height = `${goodHight}px`;
      });
    },
    resetTiles() {
      $(".draggable.tile").each(function() {
        $(this).css({
            'top': '',
            'left': ''
        });
    });

    },
    restColors() {
      $(".draggable.color").removeAttr("style");
    },
  }
};
</script>

<style scoped>
#colors > div {
  float: left;
  margin-left: 45px;
}

#colors > div:first-child {
    margin-left: 0;
}

.tile:active {
  height: 110px !important;
  box-shadow: 0px 0px 5px black;
}

.color:active {
  height: 43px !important;
  width: 43px !important;
  box-shadow: 0px 0px 5px black;
}

.dot {
  height: 40px !important;
  width: 40px;
  position: absolute !important;
}

.yellow {
  background-color: yellow !important;
}

.blue {
  background-color: blue !important;
}

.green {
  background-color: green !important;
}

.orange {
  background-color: orange !important;
}

.red {
  background-color: red !important;
}

/* .together {
	position: absolute;
	height: 100px;
	width: 72px;
} */

/* .together img {
	position: absolute;
} */

.draggable {
  height: 100px;
  /* z-index: 999; */
  /* position: relative; */
}

/* .draggable img {
	z-index: 999;
	position: relative !important;
} */

#mainBGs .s4 img {
  height: 100%;
}

#mainBGs .row {
  display: flex;
  flex-wrap: wrap;
}

.chartImgs {
  flex-grow: 1;
  margin-bottom: 3px;
}

.side {
  width: 28%;
}

.middle {
  width: 40%;
}

/* .chartImgs img {
	z-index: 0;
	position: relative !important;
} */

.imgContainer {
  /* float: left; */
  position: relative !important;
  /* margin-right: 82px;
  margin-bottom: 110px; */
  /* transform: translate(-50%, -50%); */
}
.imgContainer img {
  position: absolute !important;
}

#SSLImageGroup {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 0;
  width: 72%;
}

#SSLSmallImage {
  height: 100px;
  top: 115px;
  left: 111px; /* 115 */
  position: absolute;
}

.sideImgs {
  padding: 0px;
}

.middleImgs {
  padding: 0px 3px;
  /* margin: 0px 3px; */
}

.customSmallButton {
  padding: 5px !important;
}

</style>
