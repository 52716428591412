import Vue from 'vue/dist/vue.esm.js'
import VueRouter from 'vue-router'

import Signin from './components/Signin'
import Dashboard from './components/Dashboard'
import Resources from './components/Resources'
import FAQs from './components/FAQs'
import Settings from './components/Settings'
import PrivacyPolicy from './components/privacyPolicy'
import Tos from './components/tos'
import ErrorPage from './components/404'
import studentProfile from './components/studentProfile'
import teacherProfile from './components/teacherProfile'
import videoTraining from './components/videoTraining'
import welcomeLetter from './components/welcomeLetter'
import classroomTeacher from './components/classroomTeacher'
import classroomStudent from './components/classroomStudent'
import OpenData from './components/OpenData'
import AdminDashboard from './components/AdminDashboard'
import Authenticate from './components/Authenticate'
import Signup from './components/Signup'
import PayHomeschooler from './components/PayHomeschooler'
import PayTutorAdmin from './components/PayTutorAdmin'
import PayLearningCenter from './components/PayLearningCenter'
import SplitPage from './components/SplitPage'
import thankYou from './components/ThankYou'
import ZAamin from './components/ZAamin'

import store from './components/store/store'

// import firebase from './components/firebaseInit'
// import db from './components/firebaseDB'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: SplitPage,
      meta: {
        title: 'Sign In',
      },
    },
    {
      path: '/privacyPolicy',
      component: PrivacyPolicy,
      meta: {
        title: 'Privacy Policy',
      },
    },
    {
      path: '/tos',
      component: Tos,
      meta: {
        title: 'Terms of Service',
      },
    },
    {
      path: '/signin',
      component: Signin,
      meta: {
        title: 'Sign In',
      },
    },
    {
      path: '/signup',
      component: Signup,
      meta: {
        title: 'Sign up',
      },
    },
    {
      path: '/authenticate',
      component: Authenticate,
      meta: {
        title: 'authenticate',
      },
    },
    {
      path: '/welcome',
      component: welcomeLetter,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Welcome To Foundation In Sounds',
      },
    },
    {
      path: '/thank-you',
      component: thankYou,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Thank You!',
      },
    },
    {
      path: '/open-data',
      component: OpenData,
      meta: {
        requiresAuth: false,
        title: 'Foundation in Sounds Open Data',
      },
    },
    {
      path: '/dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Dashboard',
      },
    },
    {
      path: '/admin',
      component: AdminDashboard,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        title: 'Admin Dashboard',
      },
    },
    {
      path: '/pay-homeschooler',
      component: PayHomeschooler,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        title: 'Payment',
      },
    },
    {
      path: '/pay-tutor-admin',
      component: PayTutorAdmin,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        title: 'Payment',
      },
    },
    {
      path: '/pay-learning-center',
      component: PayLearningCenter,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
        title: 'Payment',
      },
    },
    {
      path: '/resources',
      component: Resources,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Resources',
      },
    },
    {
      path: '/FAQs',
      component: FAQs,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'FAQs',
      },
    },
    {
      path: '/classroom/teacher',
      component: classroomTeacher,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Classroom',
      },
    },
    {
      path: '/classroom/student',
      component: classroomStudent,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Classroom',
      },
    },
    {
      path: '/settings',
      component: Settings,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Settings',
      },
    },
    {
      path: '/studentProfile',
      component: studentProfile,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Student Profile',
      },
    },
    {
      path: '/teacherProfile',
      component: teacherProfile,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: "Teacher's Profile",
      },
    },
    {
      path: '/videoTraining',
      component: videoTraining,
      meta: {
        requiresAuth: true,
        requiresAdmin: false,
        title: 'Video Training',
      },
    },{
      path: '/z/auth',
      component: ZAamin,
      meta: {
        requiresAuth: false,
        requiresAdmin: false,
        title: 'Administer Z Auth',
      },
    },
    {
      path: '/*',
      component: ErrorPage,
      meta: {
        title: '404 - Page Not Found',
      },
    },
  ],
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  },
  mode: 'history',
})

router.beforeEach(function (to, from, next) {
  const currentUser = store.getters.getLoggedInStatus
  const isAdmin = store.getters.getAdminStatus

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin === true)
  const requiresNoAdmin = to.matched.some((record) => record.meta.requiresAdmin === false)
  const toPath = to.path

  document.title = to.meta.title

  //Flush VueX state on these properties after use on page
  store.dispatch('setInfoSuccess', '')
  store.dispatch('setInfoDanger', '')

  if (requiresAuth && !currentUser) {
    next('/signin')
  } else if (requiresAdmin && !isAdmin) {
    next('/dashboard')  // or any other fallback route for non-admins
  } else if (requiresNoAdmin && isAdmin) {
    next()  // allow admins to access if they want
  } else if (currentUser && '/' === toPath) {
    next('/dashboard')
  } else if (currentUser && '/signin' === toPath) {
    next('/dashboard')
  } else if (currentUser && '/signup' === toPath) {
    next('/dashboard')
  } else {
    next()
  }
})

export default router
